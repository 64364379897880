import React, { Component } from "react";
import styles from "../PricingWrapper.module.scss";
import SubNav from "../../../../shared/subnav/SubNav";
import Grid from "@material-ui/core/Grid";
import VehicleInfo from "../../../../shared/vehicleInfo/VehicleInfo";
import { vehicleInfo } from "./VehicleInfoMockData";
import DealerVehicles from "../dealerVehicles/DealerVehicles";
import BackToButton from "../../../../shared/backToButton/BackToButton";
import DealerOptions from "../dealerOptions/DealerOptions";
import { logMsg } from "../../../../common/Logger";
import { FEES_MAP, PRICING } from "../../../../common/Constants";
import DealerFees from "../dealerFees/DealerFees";
import PendingActionPrompt from "../../../../shared/pendingActionPrompt/PendingActionPrompt";
import PricingClient from "../../../../shared/clients/PricingClient";
import PendingActionClient from "../../../../shared/clients/PendingActionClient";
import UpperCaseText from "../../../../utils/UpperCaseText";
import FormattedLang from "../../../../utils/FormattedLang";
import {
  displayError,
  isUnknownError,
  PAGE_ERROR,
} from "../../../../errors/ErrorConstants";
import DealerVehicleCatalogClient from "../../../../shared/clients/DealerVehicleCatalogClient";
import { withTranslation } from "react-i18next";
import ModelDropDown from "./ModelDropDown";

class DealerCurrentPricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      renderApp: false,
      hasError: [],
      buttonEnabled: undefined,
      pendingActionPrompt: false,
      modelData: null,
      model: "All",
      year: "All",
      optionsData: [],
      pricingData: [],
      feesData: [],
      released: [],
      priceUpdatesAvailable: [],
    };
    this.currencySymbol =
      this.props.localizedInfo && this.props.localizedInfo.defaultCurrency
        ? this.props.localizedInfo.defaultCurrency.symbol
        : this.props.t("CurrentPricing.na");
    this.newVehiclesReleased = false;
    this.originalPricing = [];
    this.originalOptions = new Map();
    this.originalFees = new Map();
    this.pricingDataUpdated = false;
    this.isDealerChanged = false;
    this.hasFeesChanged = false;
    this.hasVehiclesChanged = false;
    this.hasOptionsChanged = false;
    this.onboardedDealerGroup = this.props.user.getOnboardedDealerGroup(true);
  }

  componentDidMount() {
    this.determinePAStatus();
    this.fetchDealerPricing();
    this.fetchVehicleLines();
  }

  componentWillUnmount() {
    if (this.props.user.showPricingWarningMessage && !this.isDealerChanged) {
      this.updatePricingWarning(false);
    }
  }

  onDealerChanged = (dealer, showAll = false) => {
    this.updatePricingWarning(this.pricingDataUpdated);
    this.isDealerChanged = true;
    this.props.onDealerChanged(dealer, showAll);
  };

  onModelChange = (event) => {
    this.setState(
      {
        model: event.target.value,
        renderApp: false,
        optionsData: [],
        feesData: [],
        pricingData: [],
        released: [],
      },
      () => {
        this.fetchDealerPricing();
      }
    );
  };

  onYearChange = (event) => {
    this.setState(
      {
        year: event.target.value,
        renderApp: false,
        optionsData: [],
        feesData: [],
        pricingData: [],
        released: [],
      },
      () => {
        this.fetchDealerPricing();
      }
    );
  };

  handleTabChange = (event, selectedTab) => {
    this.setState({ selectedTab });
  };

  determinePAStatus() {
    this.pendingActionStatusForDealers =
      this.props.user.getPendingActionStatusForDealers(
        this.props.user.pendingActions,
        PRICING
      );
    this.disablePendingActionPrompt =
      this.pendingActionStatusForDealers.length === 0;
  }

  fetchDealerPricing() {
    this.props.hideOrShow(true);
    const pricingData = [];
    const optionsData = [];
    const feesData = [];
    let released;
    let priceUpdatesAvailable;
    PricingClient.getDealerPricing(
      this.props.user,
      FormattedLang(this.props.lang),
      this.state.model,
      this.state.year
    )
      .then((res) => {
        if (res && res.length > 0) {
          res.forEach((data) => {
            pricingData.push(...data.result.products);
            optionsData.push(...data.result.options);
            feesData.push(...data.result.fees);
            released = data.result.released;
            priceUpdatesAvailable = data.result.priceUpdatesAvailable;
          });
        }
        this.setState({
          pricingData,
          optionsData,
          feesData,
          released,
          priceUpdatesAvailable,
          buttonEnabled: !this.state.released,
        });
      })
      .catch((error) => {
        logMsg("reason", error);
        if (isUnknownError(error)) {
          this.setState({ hasError: [PAGE_ERROR] });
        }
      })
      .finally(() => {
        this.makeCopyOfData();
        this.checkNewVehiclesReleased();
        this.setState({ renderApp: true });
        this.props.hideOrShow(false);
      });
  }

  fetchVehicleLines() {
    this.props.hideOrShow(true);
    DealerVehicleCatalogClient.getVehicleLines(this.props.user)
      .then((res) => {
        if (res && res.length) {
          this.setState({ modelData: res });
        }
      })
      .catch((error) => {
        logMsg("reason", error);
        if (isUnknownError(error)) {
          this.setState({ hasError: [PAGE_ERROR] });
        }
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  }

  checkNewVehiclesReleased = () => {
    let buttonEnabled = false;
    if (!this.state.released) {
      buttonEnabled = true;
    } else {
      this.state.feesData &&
        this.state.feesData.forEach((fees) => {
          if (!fees.released) {
            buttonEnabled = true;
          }
        });
      this.state.pricingData &&
        this.state.pricingData.forEach((entity) => {
          entity.vehiclePricingDetails &&
            entity.vehiclePricingDetails.forEach((pricingRow) => {
              if (!pricingRow.released) {
                buttonEnabled = true;
              }
            });
        });
    }
    this.setState({ buttonEnabled });
    this.newVehiclesReleased = buttonEnabled;
  };

  makeCopyOfData() {
    this.originalPricing = JSON.parse(JSON.stringify(this.state.pricingData));

    const originalOptionsData = JSON.parse(
      JSON.stringify(this.state.optionsData)
    );
    const optionsMap = new Map();
    originalOptionsData.forEach(function (val) {
      optionsMap.set(
        val.feature.concat(val.featureCondition, val.featureDesc),
        val
      );
    });
    this.originalOptions = optionsMap;

    const originalFeesData = JSON.parse(JSON.stringify(this.state.feesData));
    const feesMap = new Map();
    originalFeesData.forEach(function (val) {
      feesMap.set(val.entity, val);
    });
    this.originalFees = feesMap;
  }

  constructTabs() {
    return [
      {
        menuName: this.props.t("CurrentPricing.dealerFees"),
        enabled: true,
        id: "dealerFees",
      },
      {
        menuName: this.props.t("CurrentPricing.vehicles"),
        enabled: true,
        id: "vehicles",
      },
      {
        menuName: this.props.t("CurrentPricing.options"),
        enabled: true,
        id: "options",
      },
    ];
  }

  getFloatValue(data) {
    if (data !== null) {
      return parseFloat(data);
    } else return 0;
  }

  // If the user updated the pricing data and clicks the update button then it will call this function to save the updated pricing records
  updatePricingData = (data, index) => {
    const dataMap = new Map();
    data.forEach(function (val) {
      dataMap.set(val.entityId, val);
    });

    let btnEnb = false;
    const modifiedSpecificationData = this.state.pricingData[index];
    if (modifiedSpecificationData !== null) {
      modifiedSpecificationData.vehiclePricingDetails.forEach(
        (specification) => {
          const modifiedRecord = dataMap.get(specification.entityId);
          if (
            this.getFloatValue(specification.adj) !==
            this.getFloatValue(modifiedRecord.adj)
          ) {
            specification.adj = this.getFloatValue(modifiedRecord.adj);
          }
        }
      );
    }
    const newPricingMap = new Map();
    this.state.pricingData.forEach((entity) => {
      entity.vehiclePricingDetails.forEach((pricingRow) => {
        newPricingMap.set(pricingRow.entityId, pricingRow);
      });
    });

    this.originalPricing.forEach((entity) => {
      entity.vehiclePricingDetails.forEach((pricingRow) => {
        const newPricingRecord = newPricingMap.get(pricingRow.entityId);
        if (
          this.getFloatValue(pricingRow.adj) !==
          this.getFloatValue(newPricingRecord.adj)
        ) {
          btnEnb = true;
        }
      });
    });

    this.setState({
      buttonEnabled:
        btnEnb ||
        this.hasOptionsChanged ||
        this.hasFeesChanged ||
        this.newVehiclesReleased,
    });
    this.pricingDataUpdated = btnEnb;
    this.hasVehiclesChanged = btnEnb;
  };

  // If the user updated the options data and clicks the update button  then it will call this function to save the updated options records
  updateOptionsData = (data) => {
    const dataMap = new Map();
    data.forEach(function (val) {
      dataMap.set(
        val.feature.concat(val.featureCondition, val.featureDesc),
        val
      );
    });
    let btnEnb = false;
    this.optionsData.forEach((optionsRow) => {
      const modifiedOptions = dataMap.get(
        optionsRow.feature.concat(
          optionsRow.featureCondition,
          optionsRow.featureDesc
        )
      );
      const originalOptions = this.originalOptions.get(
        optionsRow.feature.concat(
          optionsRow.featureCondition,
          optionsRow.featureDesc
        )
      );
      optionsRow.adj = this.getFloatValue(modifiedOptions.adj);
      if (
        this.getFloatValue(originalOptions.adj) !==
        this.getFloatValue(modifiedOptions.adj)
      ) {
        btnEnb = true;
      }
    });
    this.setState({
      buttonEnabled:
        btnEnb ||
        this.hasFeesChanged ||
        this.hasVehiclesChanged ||
        this.newVehiclesReleased,
    });
    this.pricingDataUpdated = btnEnb;
    this.hasOptionsChanged = btnEnb;
  };

  // If the user updated the fees data and clicks the update button  then it will call this function to save the updated fees records
  updateFeesData = (data) => {
    const dataMap = new Map();
    data.forEach(function (val) {
      dataMap.set(val.entity, val);
    });
    let btnEnb = false;
    this.state.feesData.forEach((feesRow) => {
      const modifiedFees = dataMap.get(feesRow.entity);
      const originalData = this.originalFees.get(feesRow.entity);
      feesRow.additionalFees = this.getFloatValue(modifiedFees.additionalFees);
      const additionalFeesChanged =
        this.getFloatValue(modifiedFees.additionalFees) !==
        this.getFloatValue(originalData.additionalFees);
      feesRow.deliveryFee = this.getFloatValue(modifiedFees.deliveryFee);
      const deliveryFeeChanged =
        this.getFloatValue(modifiedFees.deliveryFee) !==
        this.getFloatValue(originalData.deliveryFee);

      feesRow.numberPlates = this.getFloatValue(modifiedFees.numberPlates);
      const numberPlatesChanged =
        this.getFloatValue(originalData.numberPlates) !==
        this.getFloatValue(modifiedFees.numberPlates);

      feesRow.firstRegFee = this.getFloatValue(modifiedFees.firstRegFee);
      const firstRegFeeChanged =
        this.getFloatValue(originalData.firstRegFee) !==
        this.getFloatValue(modifiedFees.firstRegFee);

      feesRow.cityFee = this.getFloatValue(modifiedFees.cityFee);
      const cityFeeChanged =
        this.getFloatValue(originalData.cityFee) !==
        this.getFloatValue(modifiedFees.cityFee);

      feesRow.handlingFee = this.getFloatValue(modifiedFees.handlingFee);
      const handlingFeeChanged =
        this.getFloatValue(originalData.handlingFee) !==
        this.getFloatValue(modifiedFees.handlingFee);

      if (
        additionalFeesChanged ||
        numberPlatesChanged ||
        deliveryFeeChanged ||
        firstRegFeeChanged ||
        cityFeeChanged ||
        handlingFeeChanged
      ) {
        btnEnb = true;
      }
    });
    this.setState({
      buttonEnabled:
        btnEnb ||
        this.hasOptionsChanged ||
        this.hasVehiclesChanged ||
        this.newVehiclesReleased,
    });
    this.pricingDataUpdated = btnEnb;
    this.hasFeesChanged = btnEnb;
  };

  // on the time of revisit need to send only the updated pricing records
  checkUpdatedPricingRecords = (data) => {
    const updatedPricingData = [];
    const pricingMap = new Map();
    data.forEach(function (val) {
      pricingMap.set(val.entityId, val);
    });

    this.originalPricing.forEach((entity) => {
      entity.vehiclePricingDetails.forEach((copyPricingRow) => {
        const modifiedRecord = pricingMap.get(copyPricingRow.entityId);
        if (
          this.getFloatValue(copyPricingRow.adj) !==
            this.getFloatValue(modifiedRecord.adj) ||
          !modifiedRecord.released
        ) {
          modifiedRecord.catalogId = entity.catalogId;
          updatedPricingData.push(modifiedRecord);
        }
      });
    });
    return updatedPricingData;
  };

  // on the time of revisit need to send only the updated option records
  checkUpdatedOptionRecords = () => {
    const updatedOptionsData = [];

    this.state.optionsData.forEach((optionsRow) => {
      const modifiedOptions = this.originalOptions.get(
        optionsRow.feature.concat(
          optionsRow.featureCondition,
          optionsRow.featureDesc
        )
      );
      if (
        this.getFloatValue(optionsRow.adj) !==
        this.getFloatValue(modifiedOptions.adj)
      ) {
        updatedOptionsData.push(optionsRow);
      }
    });
    return updatedOptionsData;
  };

  // on the time of revisit need to send only the updated fees records
  checkUpdatedFeesRecords = () => {
    const updatedFeesData = [];

    this.state.feesData.forEach((feesRow) => {
      const modifiedFees = this.originalFees.get(feesRow.entity);
      const additionalFeesChanged =
        this.getFloatValue(feesRow.additionalFees) !==
        this.getFloatValue(modifiedFees.additionalFees);

      const deliveryFeesChanged =
        this.getFloatValue(feesRow.deliveryFee) !==
        this.getFloatValue(modifiedFees.deliveryFee);

      const numberPlatesChanged =
        this.getFloatValue(feesRow.numberPlates) !==
        this.getFloatValue(modifiedFees.numberPlates);

      const firstRegFeeChanged =
        this.getFloatValue(feesRow.firstRegFee) !==
        this.getFloatValue(modifiedFees.firstRegFee);

      const cityFeeChanged =
        this.getFloatValue(feesRow.cityFee) !==
        this.getFloatValue(modifiedFees.cityFee);

      const handlingFeeChanged =
        this.getFloatValue(feesRow.handlingFee) !==
        this.getFloatValue(modifiedFees.handlingFee);

      if (
        numberPlatesChanged ||
        deliveryFeesChanged ||
        additionalFeesChanged ||
        firstRegFeeChanged ||
        cityFeeChanged ||
        handlingFeeChanged ||
        !modifiedFees.released
      ) {
        updatedFeesData.push(feesRow);
      }
    });
    return updatedFeesData;
  };

  //Format the save resposne which has both the pricing and the options data
  postResponse = () => {
    const pricingResponse = { pricing: [], options: [], fees: [] };
    const newPricingData = [];
    this.state.pricingData.forEach((entity) => {
      entity.vehiclePricingDetails.forEach((pricingRow) => {
        pricingRow.catalogId = entity.catalogId;
        newPricingData.push(pricingRow);
      });
    });
    if (this.state.released) {
      pricingResponse.pricing = this.checkUpdatedPricingRecords(newPricingData);
      pricingResponse.options = this.checkUpdatedOptionRecords();
      pricingResponse.fees = this.checkUpdatedFeesRecords();
    } else {
      pricingResponse.pricing = newPricingData;
      pricingResponse.options = this.state.optionsData;
      pricingResponse.fees = this.state.feesData;
    }
    return pricingResponse;
  };

  saveDealerPricing = () => {
    if (
      this.props.user.showAll &&
      this.onboardedDealerGroup.length > 1 &&
      !this.disablePendingActionPrompt
    ) {
      this.setState({ pendingActionPrompt: true });
    } else {
      this.applyPricing(this.props.user.getDealerIdsWithoutSDLR());
    }
  };

  applyPricing(dealerIds) {
    this.props.hideOrShow(true);
    this.setState({ renderApp: false });
    PricingClient.saveDealerPricingCall(
      this.props.user,
      this.postResponse(),
      dealerIds
    )
      .then(() => {
        PendingActionClient.removeDealerPendingAction(
          this.props.user,
          dealerIds,
          PRICING,
          1
        ).then(() => {
          this.props.getPendingActions().then(() => {
            this.determinePAStatus();
          });
        });
        this.fetchDealerPricing();
      })
      .catch((err) => {
        logMsg("error", err);
        this.setState({ renderApp: true });
      })
      .finally(() => {
        this.setState({ buttonEnabled: false, pendingActionPrompt: false });
        if (this.props.user.showPricingWarningMessage) {
          this.updatePricingWarning(false);
        }
        this.pricingDataUpdated = false;
        this.props.hideOrShow(false);
      });
  }

  pendingActionPromptClose = () => {
    this.setState({ pendingActionPrompt: false });
  };

  pendingActionPromptApplyAll = () => {
    const dealerIds = this.pendingActionStatusForDealers[0].map((dealer) => {
      return dealer.dealerId;
    });
    this.applyPricing(dealerIds);
  };

  pendingActionPromptApplySelective = () => {
    //only apply values to dealers who have NOT completed pendingAction
    const dealerIds = this.pendingActionStatusForDealers[0]
      .filter((dealer) => !dealer.pendingActionStatus)
      .map((dealer) => {
        return dealer.dealerId;
      });
    this.applyPricing(dealerIds);
  };

  getSubHeader = () => {
    return (
      <div className={`${styles.left} pagePaddingLeft pagePaddingBottom`}>
        {this.state.selectedTab === 0 ? (
          <div>
            <div className={styles.dealerTrans}>
              <p>{this.props.t("DealerFees.subHeader")} </p>
              {this.props.user.showPricingWarningMessage && (
                <p className={styles.warningMessage}>
                  {this.props.t("DealerFees.priceChangeWarning")}
                </p>
              )}
            </div>
            <div className={styles.modelDiv}>
              <div className={styles.dropdownContainer}>
                <Grid item md={3} xs={4}>
                  <div className={styles.subTitle}>
                    {this.props.t("NamePlate.model")}
                  </div>
                  <ModelDropDown
                    modelData={this.state.modelData}
                    year={false}
                    onModelChange={this.onModelChange}
                    onYearChange={this.onYearChange}
                    model={this.state.model}
                    yearSelection={this.state.year}
                  />
                </Grid>

                <Grid item md={3} xs={4}>
                  <div className={styles.subTitle}>
                    {this.props.t("NamePlate.modelYear")}
                  </div>
                  <ModelDropDown
                    modelData={this.state.modelData}
                    year={true}
                    onModelChange={this.onModelChange}
                    onYearChange={this.onYearChange}
                    model={this.state.model}
                    yearSelection={this.state.year}
                  />
                </Grid>
              </div>
            </div>
          </div>
        ) : this.state.selectedTab === 1 ? (
          <>
            <div className={styles.dealerTrans}>
              {this.props.t("DealerVehicles.subheader1")}
            </div>
            <div className={styles.equation}>
              {this.props.t("DealerVehicles.rrp")}{" "}
              <span className={styles.minus}> &#8722; </span>{" "}
              {this.props.t("DealerVehicles.variableMarketingOffer")}
              <span className={styles.plus}> &#43;/&#8722; </span>{" "}
              {this.props.t("DealerVehicles.dealerAdjustment")}
              <span className={styles.equal}> &#61; </span>{" "}
              {this.props.t("DealerVehicles.vehicle1")}
            </div>
            <div className={styles.equation}>
              {this.props.t("DealerVehicles.vehicle2")}
              <span className={styles.plus}> &#43; </span>
              {this.props.t("DealerVehicles.dealerFees")}{" "}
              <span className={styles.equal}> &#61; </span>{" "}
              {this.props.t("DealerVehicles.transactionPrice")}
            </div>
            <div className={styles.modelDiv}>
              <div className={styles.dropdownContainer}>
                <Grid item md={3} xs={4}>
                  <div className={styles.subTitle}>
                    {this.props.t("NamePlate.model")}
                  </div>
                  <ModelDropDown
                    modelData={this.state.modelData}
                    year={false}
                    onModelChange={this.onModelChange}
                    onYearChange={this.onYearChange}
                    model={this.state.model}
                    yearSelection={this.state.year}
                  />
                </Grid>

                <Grid item md={3} xs={4}>
                  <div className={styles.subTitle}>
                    {this.props.t("NamePlate.modelYear")}
                  </div>
                  <ModelDropDown
                    modelData={this.state.modelData}
                    year={true}
                    onModelChange={this.onModelChange}
                    onYearChange={this.onYearChange}
                    model={this.state.model}
                    yearSelection={this.state.year}
                  />
                </Grid>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.dealerTrans}>
            <p>{this.props.t("DealerVehicles.setAccessoriesPricing")}</p>
            <div className={styles.modelDiv}>
              <div className={styles.dropdownContainer}>
                <Grid item md={3} xs={4}>
                  <div className={styles.subTitle}>
                    {this.props.t("NamePlate.model")}
                  </div>
                  <ModelDropDown
                    modelData={this.state.modelData}
                    year={false}
                    onModelChange={this.onModelChange}
                    onYearChange={this.onYearChange}
                    model={this.state.model}
                    yearSelection={this.state.year}
                  />
                </Grid>

                <Grid item md={3} xs={4}>
                  <div className={styles.subTitle}>
                    {this.props.t("NamePlate.modelYear")}
                  </div>
                  <ModelDropDown
                    modelData={this.state.modelData}
                    year={true}
                    onModelChange={this.onModelChange}
                    onYearChange={this.onYearChange}
                    model={this.state.model}
                    yearSelection={this.state.year}
                  />
                </Grid>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  vehicleTitle = () => {
    return (
      <Grid className={styles.infoBar} container>
        <Grid item xs={6}>
          <VehicleInfo
            vehicleTitle={vehicleInfo.vehicleTitle}
            year={vehicleInfo.year}
          />
        </Grid>
      </Grid>
    );
  };

  updatePricingWarning = (status) => {
    this.props.user.showPricingWarning(status);
  };

  getSubNav = () => {
    return (
      <div className={styles.left}>
        <SubNav
          {...this.props}
          title={this.props.t("DealerVehicles.currentPricing")}
          subTitle={this.vehicleTitle}
          renderSubHeader={true}
          subHeader={this.getSubHeader}
          selectedTab={this.state.selectedTab}
          action={this.handleTabChange}
          tabs={this.constructTabs()}
          onDealerChanged={this.onDealerChanged}
        />
      </div>
    );
  };

  render() {
    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);

    return (
      this.state.renderApp && (
        <div className="pageWrapper">
          <BackToButton
            backToUrl="/pricing"
            display={this.props.t("PricingWrapper.backToPricing")}
          />
          {this.getSubNav()}
          {this.state.selectedTab === 0 ? (
            <DealerFees
              {...this.props}
              handleTabChange={this.handleTabChange}
              updateFeesData={this.updateFeesData}
              released={this.state.released}
              priceUpdatesAvailable={this.state.priceUpdatesAvailable}
              buttonEnabled={this.state.buttonEnabled}
              currencySymbol={this.currencySymbol}
              feesData={this.state.feesData}
            />
          ) : this.state.selectedTab === 1 ? (
            <DealerVehicles
              {...this.props}
              handleTabChange={this.handleTabChange}
              pricingData={this.state.pricingData}
              feesData={this.state.feesData}
              currencySymbol={this.currencySymbol}
              navigateToOptions={this.navigateToOptions}
              released={this.state.released}
              priceUpdatesAvailable={this.state.priceUpdatesAvailable}
              buttonEnabled={this.state.buttonEnabled}
              updatePricingData={this.updatePricingData}
              fees={FEES_MAP[UpperCaseText(this.props.user.market)]}
            />
          ) : (
            <DealerOptions
              {...this.props}
              released={this.state.released}
              priceUpdatesAvailable={this.state.priceUpdatesAvailable}
              optionsData={this.state.optionsData}
              buttonEnabled={this.state.buttonEnabled}
              savePricing={this.saveDealerPricing}
              updateOptionsData={this.updateOptionsData}
              currencySymbol={this.currencySymbol}
              isDisabled={true}
            />
          )}
          <PendingActionPrompt
            {...this.props}
            disabled={this.disablePendingActionPrompt}
            dealersActioned={
              this.disablePendingActionPrompt
                ? []
                : this.pendingActionStatusForDealers[0].filter(
                    (dealer) => dealer.pendingActionStatus
                  )
            }
            dealersNotActioned={
              this.disablePendingActionPrompt
                ? []
                : this.pendingActionStatusForDealers[0].filter(
                    (dealer) => !dealer.pendingActionStatus
                  )
            }
            title={this.props.t("PendingActionPrompt.pricing")}
            buttonTitle={this.props.t("PendingActionPrompt.pricingButton")}
            onClose={this.pendingActionPromptClose}
            open={this.state.pendingActionPrompt}
            applyAll={this.pendingActionPromptApplyAll}
            applySome={this.pendingActionPromptApplySelective}
          />
        </div>
      )
    );
  }
}

export default withTranslation("emp")(DealerCurrentPricing);
