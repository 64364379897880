import React, { Component } from "react";
import { InfoIconWithMessage } from "../../../shared/infoIcon/InfoIcon";
import { withTranslation } from "react-i18next";
import LeadTimesRow from "./LeadTimesRow";
import Grid from "@material-ui/core/Grid";
import styles from "./LeadTimes.module.scss";
import CheckBox from "../../../shared/checkBox/CheckBox";
import EditIcon from "../../../shared/Edit/EditIcon";
import { ValidatorForm } from "react-material-ui-form-validator";
import HasPermissionTo from "../../../common/HasPermissionTo";
import LeadTimesClient from "../../../shared/clients/LeadTimesClient";
import { LEAD_TIMES } from "../../../common/Constants";
import PendingActionClient from "../../../shared/clients/PendingActionClient";
import ValidationRules from "../../../common/ValidationRules";

class LeadTimes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editUnscheduled: false,
      editInProduction: false,
      editInTransit: false,
      editGateReleased: false,
      editVehicleHoldingCompound: false,
      editDealerDelivered: false,
      data: [],
      firstLoad: false,
      totalChecked: 0,
      editCount: 0,
      buttonDisabled: true,
      saveSuccess: false,
      totalSaved: 0,
      infoTooltipOpen: false,
      infoTooltipColumn: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeInfoTooltip = this.closeInfoTooltip.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.isNotFirstLoad = this.isNotFirstLoad.bind(this);
  }

  componentDidMount() {
    ValidationRules();
    LeadTimesClient.getLeadTimes(this.props.user)
      .then((response) => {
        const firstLoad = !this.isNotFirstLoad(response);
        if (firstLoad) {
          response = response.map((d) => {
            d.checked = true;
            return d;
          });
        }
        this.setState({
          data: response,
          firstLoad,
          totalChecked: firstLoad ? response.length : 0,
        });
      })
      .catch(() => {});
  }

  handleKeyDown(e) {
    if (e.keyCode === 39) {
      this.advanceFields(e);
    } else if (e.keyCode === 37) {
      this.goBack(e);
    }
  }

  handleChange(e, index, key) {
    // Update data
    const dataCopy = JSON.parse(JSON.stringify(this.state.data));
    dataCopy[index]["deliveryStatus"][key] = e.target.value;

    // Check field if first edit
    const wasChecked = dataCopy[index].checked;
    !wasChecked && (dataCopy[index].checked = true);
    this.setState(
      {
        data: dataCopy,
        totalChecked: wasChecked
          ? this.state.totalChecked
          : this.state.totalChecked + 1,
      },
      () => this.checkButtonDisabled()
    );

    // Auto advance
    if (e.target.value.length === 5) {
      this.advanceFields(e);
    }
  }

  handleEdit(key) {
    // Show popup tooltip with info if this is the users first time editing anything on the page
    if (this.state.firstLoad && this.state.editCount === 0) {
      this.setState({ infoTooltipOpen: true, infoTooltipColumn: key });
    }

    // If this is first edit after save, reset all the data
    if (this.state.saveSuccess) {
      const copyData = JSON.parse(JSON.stringify(this.state.data));
      copyData.forEach((item) => {
        item.saved = false;
      });
      this.setState({ saveSuccess: false, data: copyData, totalSaved: 0 });
    }

    switch (key) {
      case "UnscheduleDeliveryTime":
        this.setState(
          {
            editUnscheduled: true,
            editCount: this.state.editCount + 1,
          },
          () => this.checkButtonDisabled()
        );
        break;
      case "InProduction":
        this.setState(
          {
            editInProduction: true,
            editCount: this.state.editCount + 1,
          },
          () => this.checkButtonDisabled()
        );
        break;
      case "GateRelease":
        this.setState(
          {
            editGateReleased: true,
            editCount: this.state.editCount + 1,
          },
          () => this.checkButtonDisabled()
        );
        break;
      case "InTransit":
        this.setState(
          {
            editInTransit: true,
            editCount: this.state.editCount + 1,
          },
          () => this.checkButtonDisabled()
        );
        break;
      case "VehicleHoldCompound":
        this.setState(
          {
            editVehicleHoldingCompound: true,
            editCount: this.state.editCount + 1,
          },
          () => this.checkButtonDisabled()
        );
        break;
      case "DealerDelivery":
        this.setState(
          {
            editDealerDelivered: true,
            editCount: this.state.editCount + 1,
          },
          () => this.checkButtonDisabled()
        );
        break;
      default:
        return;
    }
  }

  handleSubmit() {
    const totalSaved = this.state.totalChecked;
    const clonedData = JSON.parse(JSON.stringify(this.state.data));
    clonedData.forEach((leadTime) => {
      if (leadTime.checked) {
        leadTime.saved = true;
        leadTime.checked = false;
      }
    });
    LeadTimesClient.postLeadTimes(this.state.data, this.props.user).then(() => {
      PendingActionClient.removeNscPendingAction(
        this.props.user,
        LEAD_TIMES,
        2
      );
      this.setState({
        editUnscheduled: false,
        editInProduction: false,
        editGateReleased: false,
        editInTransit: false,
        editVehicleHoldingCompound: false,
        editDealerDelivered: false,
        data: clonedData,
        firstLoad: false,
        totalSaved: totalSaved,
        totalChecked: 0,
        editCount: 0,
        buttonDisabled: true,
        saveSuccess: true,
      });
    });
  }

  checkButtonDisabled() {
    if (this.state.firstLoad) {
      // On first load, all fields need to be filled out to enable button - so all must be in edit mode
      this.formRef.current
        .isFormValid()
        .then((valid) => {
          this.setState({
            buttonDisabled: !(valid && this.state.editCount === 6),
          });
        })
        .catch(() => {});
    } else {
      this.formRef.current.isFormValid().then((valid) => {
        this.setState({
          buttonDisabled: !(valid && this.state.totalChecked > 0),
        });
      });
    }
  }

  advanceFields(e) {
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    let inputFound = false;
    let next;
    for (let i = index + 1; i < form.elements.length; ++i) {
      if (form.elements[i].type === "text") {
        inputFound = true;
        next = i;
        break;
      }
    }
    if (inputFound) {
      form.elements[next].focus();
      e.preventDefault();
    }
  }

  goBack(e) {
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    let inputFound = false;
    let prev;
    for (let i = index - 1; i >= 0; --i) {
      if (form.elements[i].type === "text") {
        inputFound = true;
        prev = i;
        break;
      }
    }
    if (inputFound) {
      form.elements[prev].focus();
      e.preventDefault();
    }
  }

  formRef = React.createRef();

  isNotFirstLoad(leadTimes) {
    return leadTimes.every((lt) => {
      return (
        this.getTrimmedValue(lt.deliveryStatus.UnscheduleDeliveryTime) &&
        this.getTrimmedValue(lt.deliveryStatus.DealerDelivery) &&
        this.getTrimmedValue(lt.deliveryStatus.InProduction) &&
        this.getTrimmedValue(lt.deliveryStatus.GateRelease) &&
        this.getTrimmedValue(lt.deliveryStatus.VehicleHoldCompound) &&
        this.getTrimmedValue(lt.deliveryStatus.InTransit)
      );
    });
  }

  getTrimmedValue(value) {
    return value ? value.trim() : "";
  }

  closeInfoTooltip() {
    this.setState({ infoTooltipOpen: false, infoTooltipColumn: "" });
  }

  render() {
    return (
      <div className="pageWrapper">
        <div className="pagePaddingTop">
          <div className="pagePaddingLeft pagePaddingRight">
            <InfoIconWithMessage
              message={this.props.t("LeadTimes.subheader")}
            />
          </div>
          <div className="pagePaddingTop">
            <div>
              <Grid container spacing={0} className={styles.divider}>
                <Grid item xs={1} className={styles.checkboxHeader}>
                  <div className={styles.checkbox}>
                    <CheckBox
                      id="leadTimesAll"
                      faded={true}
                      checked={
                        this.state.totalChecked === this.state.data.length
                      }
                      isDisabled={true}
                    />
                  </div>
                </Grid>
                <Grid item xs={5} className={styles.descriptionHeader}>
                  {this.props.t("LeadTimes.factoryNo")}
                </Grid>
                <Grid item xs={1} className={styles.editHeader}>
                  <span
                    className={
                      !this.state.editUnscheduled
                        ? styles.negativeMarginTitle
                        : styles.title
                    }
                  >
                    {this.props.t("LeadTimes.unscheduled")}
                  </span>
                  <HasPermissionTo
                    perform={["leadTimes:write"]}
                    permissions={this.props.user.permissions.rolePermissions}
                    condition={!this.state.editUnscheduled}
                    render={() => {
                      return (
                        <div className={styles.editIcon}>
                          <EditIcon
                            id="Unscheduled"
                            onClick={() =>
                              this.handleEdit("UnscheduleDeliveryTime")
                            }
                          />
                        </div>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={1} className={styles.editHeader}>
                  <span
                    className={
                      !this.state.editUnscheduled
                        ? styles.negativeMarginTitle
                        : styles.title
                    }
                  >
                    {this.props.t("LeadTimes.inProduction")}
                  </span>
                  <HasPermissionTo
                    perform={["leadTimes:write"]}
                    permissions={this.props.user.permissions.rolePermissions}
                    condition={!this.state.editInProduction}
                    render={() => {
                      return (
                        <div className={styles.editIcon}>
                          <EditIcon
                            id="InProduction"
                            onClick={() => this.handleEdit("InProduction")}
                          />
                        </div>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={1} className={styles.editHeader}>
                  <span
                    className={
                      !this.state.editUnscheduled
                        ? styles.negativeMarginTitle
                        : styles.title
                    }
                  >
                    {this.props.t("LeadTimes.gateReleased")}
                  </span>
                  <HasPermissionTo
                    perform={["leadTimes:write"]}
                    permissions={this.props.user.permissions.rolePermissions}
                    condition={!this.state.editGateReleased}
                    render={() => {
                      return (
                        <div className={styles.editIcon}>
                          <EditIcon
                            id="Gate"
                            onClick={() => this.handleEdit("GateRelease")}
                          />
                        </div>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={1} className={styles.editHeader}>
                  <span
                    className={
                      !this.state.editUnscheduled
                        ? styles.negativeMarginTitle
                        : styles.title
                    }
                  >
                    {this.props.t("LeadTimes.inTransit")}
                  </span>
                  <HasPermissionTo
                    perform={["leadTimes:write"]}
                    permissions={this.props.user.permissions.rolePermissions}
                    condition={!this.state.editInTransit}
                    render={() => {
                      return (
                        <div className={styles.editIcon}>
                          <EditIcon
                            id="InTransit"
                            onClick={() => this.handleEdit("InTransit")}
                          />
                        </div>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={1} className={styles.editHeader}>
                  <span
                    className={
                      !this.state.editUnscheduled
                        ? styles.negativeMarginTitle
                        : styles.title
                    }
                  >
                    {this.props.t("LeadTimes.vehicleHoldingCompound")}
                  </span>
                  <HasPermissionTo
                    perform={["leadTimes:write"]}
                    permissions={this.props.user.permissions.rolePermissions}
                    condition={!this.state.editVehicleHoldingCompound}
                    render={() => {
                      return (
                        <div className={styles.editIcon}>
                          <EditIcon
                            id="VHC"
                            onClick={() =>
                              this.handleEdit("VehicleHoldCompound")
                            }
                          />
                        </div>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={1} className={styles.editHeader}>
                  <span
                    className={
                      !this.state.editUnscheduled
                        ? styles.negativeMarginTitle
                        : styles.title
                    }
                  >
                    {this.props.t("LeadTimes.dealerDelivered")}
                  </span>
                  <HasPermissionTo
                    perform={["leadTimes:write"]}
                    permissions={this.props.user.permissions.rolePermissions}
                    condition={!this.state.editDealerDelivered}
                    render={() => {
                      return (
                        <div className={styles.editIcon}>
                          <EditIcon
                            id="DealerDelivered"
                            onClick={() => this.handleEdit("DealerDelivery")}
                          />
                        </div>
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <Grid container className={styles.countDiv}>
            <Grid item xs={1} />
            <Grid item xs={2}>
              <span className={styles.count}>{this.state.totalChecked} </span>
              model line{this.state.totalChecked !== 1 && "s"} selected
            </Grid>
          </Grid>
          <ValidatorForm onSubmit={this.handleSubmit} ref={this.formRef}>
            {this.state.data.map((data, index) => {
              return (
                <LeadTimesRow
                  data={data}
                  index={index}
                  key={index}
                  infoTooltipOpen={this.state.infoTooltipOpen}
                  infoTooltipColumn={this.state.infoTooltipColumn}
                  closeInfoTooltip={this.closeInfoTooltip}
                  firstLoad={this.state.firstLoad}
                  handleKeyDown={this.handleKeyDown}
                  handleChange={this.handleChange}
                  editModeUnscheduled={this.state.editUnscheduled}
                  editModeInProduction={this.state.editInProduction}
                  editModeGateRelease={this.state.editGateReleased}
                  editModeInTransit={this.state.editInTransit}
                  editModeVehicleHoldingCompound={
                    this.state.editVehicleHoldingCompound
                  }
                  editModeDealerDelivered={this.state.editDealerDelivered}
                />
              );
            })}
            <HasPermissionTo
              perform={["leadTimes:write"]}
              permissions={this.props.user.permissions.rolePermissions}
              render={() => {
                return (
                  <div className={styles.buttonDiv}>
                    {this.state.saveSuccess ? (
                      <div className={styles.saveSuccess}>
                        {this.state.totalSaved}{" "}
                        {this.state.totalSaved !== 1
                          ? this.props.t("LeadTimes.savedMultiple")
                          : this.props.t("LeadTimes.savedOne")}
                      </div>
                    ) : (
                      <button
                        type="submit"
                        data-testid="submitBtn"
                        disabled={this.state.buttonDisabled}
                      >
                        {this.props.t("LeadTimes.releaseLeadTimes")}
                      </button>
                    )}
                  </div>
                );
              }}
            />
          </ValidatorForm>
        </div>
      </div>
    );
  }
}

export default withTranslation("emp")(LeadTimes);
