import React from "react";
import styles from "../../pricingList/PricingList.module.scss";
import { Table, TableBody, TableCell, withStyles } from "@material-ui/core";
import { TextValidator } from "react-material-ui-form-validator";
import Item from "../../../../../shared/item/Item";
import CheckBox from "../../../../../shared/checkBox/CheckBox";
import feesStyles from "../DealerFeesStyles";
import CheckCurrencyValue from "../../../../../utils/CheckCurrencyValue";

const DealerFeesRow = (props) => {
  const CheckBoxTableCell = withStyles({
    root: {
      borderBottom: 0,
      padding: 0,
      width: "8%",
    },
  })(TableCell);
  const WarningTableCell = withStyles({
    root: {
      borderBottom: 0,
      padding: 0,
      width: "5%",
      paddingLeft: "2%",
    },
  })(TableCell);
  const SpecsTableCell = withStyles({
    root: {
      borderBottom: 0,
      fontWeight: 500,
      padding: "5px 0",
      width: "26%",
      textAlign: "left",
      color: "#102b4e",
      fontSize: "12px",
      fontFamily: "FordAntennaRegular, sans-serif",
      letterSpacing: "1px",
      lineHeight: 2.67,
    },
  })(TableCell);
  const NonEditTableCell = withStyles({
    root: {
      borderBottom: 0,
      fontWeight: 500,
      padding: "5px 0",
      width: "10%",
      textAlign: "right",
      color: "#4a4a4a ",
      fontSize: "12px",
      fontFamily: "FordAntennaSemiBold, sans-serif",
      letterSpacing: "1px",
    },
  })(TableCell);

  return (
    <div
      className={`${
        props.item.released
          ? styles.rowBorderHighlight
          : styles.rowBorderHighlightPriceChange
      } ${styles.dealerFeesRow} pagePaddingRight`}
    >
      <Table>
        <TableBody>
          <WarningTableCell>
            {!props.item.released && (
              <div
                className={styles.warning}
                data-testid={"warning" + props.index}
              />
            )}
          </WarningTableCell>
          <CheckBoxTableCell className={styles.rowItem}>
            <CheckBox checked={true} isDisabled={props.isDisabled} />
          </CheckBoxTableCell>
          <SpecsTableCell className={styles.rowItem}>
            <Item value={props.item.specs} datatestid={"specs" + props.index} />
          </SpecsTableCell>

          {props.fees &&
            props.fees.map((fee) => {
              return fee.isEditable ? (
                <TableCell
                  style={feesStyles.feesEditValue}
                  className={`${styles.rowItemBold} ${styles.right}`}
                >
                  {props.editEnabled[fee.key] ? (
                    <span className={styles.textBox}>
                      <TextValidator
                        name={fee.key + props.index}
                        value={props.item[fee.key]}
                        onChange={(e) =>
                          props.handleEdit(e, props.index, fee.key)
                        }
                        validators={["required", "isPositiveNumber"]}
                        errorMessages={[
                          props.t("Error.requiredField"),
                          props.t("Error.invalidInput"),
                        ]}
                      />
                    </span>
                  ) : (
                    <>
                      <div className={`${styles.width85} ${styles.alignRight}`}>
                        <Item
                          value={CheckCurrencyValue(
                            props.currencySymbol,
                            props.item[fee.key]
                          )}
                        />
                      </div>
                      <div className={`${styles.width15}`} />
                    </>
                  )}
                </TableCell>
              ) : (
                <NonEditTableCell
                  className={`${styles.rowItemBold} ${styles.right}`}
                >
                  <Item
                    value={CheckCurrencyValue(
                      props.currencySymbol,
                      props.item[fee.key]
                    )}
                  />
                </NonEditTableCell>
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
};

export default DealerFeesRow;
