import React, { Component } from "react";
import { Button, Grid, TableCell } from "@material-ui/core";
import styles from "./LoadDealerPricing.module.scss";
import { withTranslation } from "react-i18next";
import HasPermissionTo from "../../../../common/HasPermissionTo";
import CheckBox from "../../../../shared/checkBox/CheckBox";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { PAYMENT_THRESHOLD_LIMIT } from "../../../../common/Constants";
import ClearIcon from "@material-ui/icons/Clear";

class LoadDealerPricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxLimitFormatted: "",
      validationInvalidFormat: "",
      validationRequired: "",
    };

    ValidatorForm.addValidationRule("changeInPrice", (value, model) => {
      let changeInPrice = true;
      this.props.selectedPrices.forEach((item) => {
        changeInPrice = !(
          (model === item.currencyAdjustedPrice) ===
          parseFloat(value).toFixed(2)
        );
      });
      this.props.applyBtn && this.props.applyBtn(changeInPrice);
      return true;
    });

    this.deleteTermsDialogProps = {
      title: this.props.t("TermsandConditionsHistory.deleteTitle"),
      bodyText: this.props.t("TermsandConditionsHistory.deleteBody"),
      confirm: this.props.t("TermsandConditionsHistory.confirm"),
      cancel: this.props.t("TermsandConditionsHistory.cancel"),
    };

    this.disableCheckbox =
      //Disable checkbox if the permissions not found
      this.props.user.permissions.rolePermissions.hasOwnProperty(
        "dealerInventoryApprove:write"
      ) === false;
  }

  componentDidMount() {
    this.setState({
      maxLimitFormatted: PAYMENT_THRESHOLD_LIMIT[this.props.userDetails.market]
        ? PAYMENT_THRESHOLD_LIMIT[this.props.userDetails.market]
            .maxLimitFormatted
        : "999,999.99",
      validationInvalidFormat:
        this.props.t("PaymentThreshold.validations.invalidFormat") || "",
      validationRequired: "Value is required" || "",
    });
  }

  handleConfirm = () => {
    this.props.deleteTerms(this.version);
  };

  deleteTermsDialog = (version) => {
    this.version = version;
  };

  displayDepositPrice = (item, defaultCurrency) => {
    if (
      item.currencyAdjustedPrice !== null &&
      (!!item.currencyAdjustedPrice || item.currencyAdjustedPrice >= 0)
    ) {
      return (
        <div>
          <span id="model" className={styles.reservationInputGridModel}>
            {defaultCurrency ? defaultCurrency.symbol : ""}{" "}
            {parseFloat(item.currencyAdjustedPrice).toFixed(2)}
          </span>
        </div>
      );
    }
  };

  loadTableCellHeader = (style, divStyle, column, displayColumn, editable) => {
    return (
      <TableCell style={style}>
        <div>
          {displayColumn && (
            <div className={divStyle} id="header">
              {this.props.t("UploadDealerPrice." + column)}
            </div>
          )}
          {editable && (
            <div
              className={`${styles.Edit} ${
                this.props.editButtonDisable && styles.disabled
              }`}
              id="editReservations"
              onClick={this.props.editReservations}
            >
              <div className={styles.RectangleStart}>&nbsp;</div>
              <div className={styles.RectangleEnd}>&nbsp;</div>
            </div>
          )}
        </div>
      </TableCell>
    );
  };

  showDeleteIcon = (index) => {
    return (
      this.props.termsAndConditionsHistory.length > 1 &&
      index === 0 &&
      this.props.termsAndConditionsHistory[0].canBeDeleted
    );
  };
  showInventoryConfirmation = (v) => {
    //step-1 // open confirm dialog and pass the selected vin
    this.props.showInventoryConfirmation(v);
  };

  roundToTwoDecimal = (num) => {
    if (num % 1 !== 0) {
      let value = +(Math.round(num + "e+2") + "e-2");
      const length = value.toString().split(".")[1].length;
      if (length === 0) {
        value = value + ".00";
      } else if (length === 1) {
        value = value + "0";
      }
      return value;
    } else {
      return num ? num.toString() + ".00" : "-";
    }
  };

  render() {
    const { defaultCurrency } = this.props.localizedInfo || {
      defaultCurrency: { symbol: "" },
    };
    return (
      <div>
        <div className={styles.title}>
          <h2 className="pagePaddingLeft" id="termsHeader">
            {this.props.t("UploadDealerPrice.title")}
          </h2>
          <div id="inventoryPrices">
            <Grid
              container
              spacing={0}
              mt={1}
              className={`${styles.tableRowHeader} pagePaddingLeft pagePaddingRight`}
            >
              {this.props.showValidatdColumn && (
                <Grid item md={1}>
                  <span id="model" className={styles.priceValidatedAllCheckBox}>
                    {this.props.t("UploadDealerPrice.validated")}
                    <CheckBox
                      id="checkAllBoxes"
                      checked={this.props.allChecked}
                      isDisabled={this.disableCheckbox}
                      onChange={this.props.checkAllCheckBox}
                    />
                  </span>
                </Grid>
              )}
              <Grid item md={true}>
                <span id="model" className={styles.reservationInputGridModel}>
                  {this.props.t("UploadDealerPrice.specification")}
                </span>
              </Grid>
              <Grid item md={2}>
                <span id="model" className={styles.reservationInputGridModel}>
                  {this.props.t("UploadDealerPrice.vin")}
                </span>
              </Grid>
              <Grid item md={1}>
                <span id="model" className={styles.reservationInputGridModel}>
                  {this.props.t("UploadDealerPrice.modelYear")}
                </span>
              </Grid>
              {!this.props.hideUsdPrice && (
                <Grid
                  item
                  md={true}
                  style={{
                    width: 106,
                    maxWidth: 135,
                    justifyContent: "flex-end",
                  }}
                >
                  <span
                    id="model"
                    style={{ justifyContent: "flex-end" }}
                    className={styles.reservationInputGridModel}
                  >
                    {this.props.t("UploadDealerPrice.usdCustomerPrice")}
                  </span>
                </Grid>
              )}
              <Grid
                item
                md={true}
                style={{
                  width: 106,
                  maxWidth: 135,
                  justifyContent: "flex-end",
                }}
              >
                <div
                  style={{ justifyContent: "flex-end" }}
                  className={styles.reservationInputGridRightCell}
                >
                  {this.props.t("UploadDealerPrice.arsAdjustedPrice")}
                </div>
              </Grid>
              {this.props.showValidatdColumn && (
                <Grid item md={true} style={{ width: 10, maxWidth: 10 }}>
                  <HasPermissionTo
                    perform={["dealerInventoryApprove:write"]}
                    permissions={this.props.user.permissions.rolePermissions}
                    render={() => (
                      <div
                        className={`${styles.Edit} ${
                          this.props.editButtonDisable && styles.disabled
                        }`}
                        id="editReservations"
                        data-testid="editReservations"
                        onClick={this.props.editReservations}
                      >
                        <div className={styles.RectangleStart}>&nbsp;</div>
                        <div id="pencilIcon" className={styles.RectangleEnd}>
                          &nbsp;
                        </div>
                      </div>
                    )}
                  />
                </Grid>
              )}
              <Grid item md={2}>
                <span id="model" className={styles.reservationInputGridModel}>
                  {this.props.t("UploadDealerPrice.effectiveDate")}
                </span>
              </Grid>
              {this.props.showValidatdColumn && (
                <Grid item md={true} style={{ width: 50, maxWidth: 50 }}></Grid>
              )}
            </Grid>
            <div style={{ marginTop: 10 }}>
              {this.props.selectedPrices &&
                this.props.selectedPrices.map((price, index) => (
                  <Grid
                    container
                    spacing={0}
                    mt={1}
                    className="pagePaddingLeft pagePaddingRight"
                    justify="center"
                    key={"selectedPrice-" + price.vin + "-" + index}
                  >
                    {this.props.showValidatdColumn && (
                      <Grid item md={1} className={styles.reservationEditPrice}>
                        <div className={styles.checkbox}>
                          <CheckBox
                            id={`checkbox${index}`}
                            key={`checkbox${index}-${price && price.checked}`}
                            value={index}
                            checked={
                              price.priceModified
                                ? price.checked
                                : price.checked || price.published
                            }
                            isDisabled={this.disableCheckbox}
                            onChange={this.props.clickCheckBox}
                          />
                        </div>
                      </Grid>
                    )}
                    <Grid item md={true}>
                      <span
                        id="model"
                        style={{ justifyContent: "flex-start" }}
                        className={styles.reservationInputGridModel}
                      >
                        {price.inventorySpecification}
                      </span>
                    </Grid>
                    <Grid item md={2}>
                      <span
                        id="model"
                        className={styles.reservationInputGridModel}
                      >
                        {price.vin}
                      </span>
                    </Grid>
                    <Grid item md={1}>
                      <span
                        id="model"
                        className={styles.reservationInputGridModel}
                      >
                        {price.modelYear}
                      </span>
                    </Grid>
                    {!this.props.hideUsdPrice && (
                      <Grid
                        item
                        md={true}
                        style={{
                          width: 106,
                          maxWidth: 135,
                          justifyContent: "flex-end",
                        }}
                      >
                        <span
                          id="model"
                          className={styles.reservationInputGridModelPrice}
                        >
                          USD{" "}
                          {price.currencyAdjustedPrice > 0
                            ? this.roundToTwoDecimal(
                                price.currencyAdjustedPrice *
                                  this.props.conversionRate
                              )
                            : ""}
                        </span>
                      </Grid>
                    )}
                    <Grid
                      item
                      md={true}
                      style={{
                        width: 106,
                        maxWidth: 135,
                        justifyContent: "flex-end",
                      }}
                      className={styles.reservationEditPrice}
                    >
                      {this.props.editMode ? (
                        <>
                          <div>
                            {defaultCurrency ? defaultCurrency.symbol : ""}{" "}
                            &nbsp;
                          </div>
                          <TextValidator
                            autoComplete="off"
                            name={price.vin}
                            ref={this.priceRef}
                            variant="outlined"
                            key={"checkbox-row" + { index } + "-value-"}
                            value={
                              price.currencyAdjustedPrice === null ||
                              price.currencyAdjustedPrice === ""
                                ? ""
                                : price.currencyAdjustedPrice
                            }
                            onChange={this.props.updateInventoryPrice}
                            validators={[
                              "required",
                              "matchRegexp:^[0-9]*(\\.[0-9]{0,2})?$",
                              "minNumber:1",
                              "changeInPrice:" + price.vin,
                            ]}
                            errorMessages={[
                              this.state.validationRequired,
                              this.state.validationInvalidFormat,
                              this.state.validationInvalidFormat,
                              this.state.maxLimitFormatted,
                              this.state.validationInvalidFormat,
                            ]}
                          />
                        </>
                      ) : (
                        this.displayDepositPrice(price, defaultCurrency)
                      )}
                    </Grid>
                    {this.props.showValidatdColumn && (
                      <Grid
                        item
                        md={true}
                        style={{ width: 10, maxWidth: 10 }}
                      ></Grid>
                    )}
                    <Grid item md={2}>
                      <span
                        id="model"
                        className={styles.reservationInputGridModel}
                      >
                        {price.effectiveDate}
                      </span>
                    </Grid>
                    {this.props.showValidatdColumn && (
                      <Grid
                        item
                        md={true}
                        style={{ width: 50, maxWidth: 50, display: "flex" }}
                      >
                        <HasPermissionTo
                          perform={["dealerInventoryApprove:write"]}
                          permissions={
                            this.props.user.permissions.rolePermissions
                          }
                          render={() => (
                            <Button
                              id={`cancelAdd_${price.vin}`}
                              data-testid={`cancelAdd_${price.vin}`}
                              onClick={() =>
                                this.showInventoryConfirmation(price.vin)
                              }
                              size="small"
                            >
                              <ClearIcon />
                            </Button>
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("emp")(LoadDealerPricing);
