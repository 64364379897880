import React from "react";
import styles from "./AccessoriesSettingsEU.module.scss";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import Item from "../../../../../shared/item/Item";
import Clear from "@material-ui/icons/Clear";
import Button from "@material-ui/core/Button";
import RectangleEdit from "../../../../../shared/rectangleEdit/RectangleEdit";
import { withTranslation } from "react-i18next";
import HasPermissionTo from "../../../../../common/HasPermissionTo";

const AccessoriesHeader = (props) => {
  const StyledClear = withStyles({
    root: {
      width: 20,
      height: 20,
      fill: "#e81607",
    },
  })(Clear);
  const StyledButton = withStyles({
    root: {
      minWidth: 15,
    },
  })(Button);
  const commonStyles = {
    borderBottom: 0,
    fontWeight: 500,
    padding: "5px 0",
    color: "#102b4e",
    fontSize: "12px",
    fontFamily: "FordAntennaCondMed, sans-serif",
    letterSpacing: "1px",
    textTransform: "uppercase",
  };
  const PartNameTableCell = withStyles({
    root: {
      ...commonStyles,
      width: "23%",
      textAlign: "left",
      overflowWrap: "break-word",
      wordWrap: "break-word",
    },
  })(TableCell);
  const HeaderTableCell = withStyles({
    root: {
      ...commonStyles,
      width: "11%",
      maxWidth: "11%",
      textAlign: "right",
      overflowWrap: "break-word",
      wordWrap: "break-word",
    },
  })(TableCell);

  const HeaderCell = ({ title: title }) => {
    return (
      <HeaderTableCell>
        <div className={styles.headerWrap}>
          <Item className={styles.maxWidth} value={title} />
        </div>
      </HeaderTableCell>
    );
  };

  return (
    <div className={styles.rowBorder}>
      <Table>
        <TableHead>
          <TableRow>
            <PartNameTableCell>
              <div className={styles.specsHeaderWrap}>
                <Item value={props.t("AccessoriesPricing.partName")} />
              </div>
            </PartNameTableCell>
            <HeaderTableCell>
              <div className={`${styles.headerWrap} ${styles.compressHeader}`}>
                <Item
                  className={styles.maxWidth}
                  value={props.t("AccessoriesPricing.partNumber")}
                />
              </div>
            </HeaderTableCell>
            <HeaderCell title={props.t("AccessoriesPricing.rrp")} />
            <HeaderCell
              title={props.t("AccessoriesPricing.variableMarketing")}
            />
            <HeaderTableCell className={`${styles.rowItem} ${styles.right}`}>
              <div className={styles.editableHeader}>
                {props.editEnabled ? (
                  <div className={styles.vehiclesHeaderWrap}>
                    <div
                      className={styles.adjustmentUpdateTitle}
                      data-testid="accessoriesUpdateHeader"
                    >
                      <span>
                        <Item
                          value={props.t("AccessoriesPricing.dealerAdjustment")}
                        />
                      </span>
                    </div>
                    <div className={styles.right}>
                      <StyledButton
                        className={styles.cancelBtn}
                        size="small"
                        name="Cancel"
                        data-testid="cancelAdjustment"
                        onClick={props.cancelUpdate}
                      >
                        <StyledClear />
                      </StyledButton>
                      <Button
                        size="small"
                        name="Confirm"
                        type="submit"
                        data-testid="updateAdjustment"
                      >
                        <span className={styles.vehiclesHeaderWrap}>
                          {props.t("AccessoriesPricing.update")}
                        </span>
                      </Button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      className={`${styles.headerWrap}  ${styles.width85} ${styles.right}`}
                    >
                      <span>
                        <Item
                          value={props.t("AccessoriesPricing.dealerAdjustment")}
                        />
                      </span>
                    </div>
                    <HasPermissionTo
                      perform={["accessoriesEU:write"]}
                      permissions={props.user.permissions.rolePermissions}
                      condition={
                        props.isNsc
                          ? true
                          : !props.isMainViewingSDLR(props.user)
                      }
                      render={() => (
                        <div className={styles.editBtnDiv}>
                          <RectangleEdit
                            dataTestId="editAdjustment"
                            onEditClick={props.onEditClick}
                          />
                        </div>
                      )}
                      noRender={() => (
                        <div className={styles.noRectangleEdit} />
                      )}
                    />
                  </>
                )}
              </div>
            </HeaderTableCell>
            <HeaderCell title={props.t("AccessoriesPricing.dealerPrice")} />
            <HeaderCell title={props.t("AccessoriesPricing.installTime")} />
            <HeaderCell
              title={props.t("AccessoriesPricing.transactionPrice")}
            />
          </TableRow>
        </TableHead>
      </Table>
    </div>
  );
};

export default withTranslation("emp")(AccessoriesHeader);
