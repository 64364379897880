import React, { Component } from "react";
import OptionDetails from "../optionDetails/OptionDetails";
class DealerOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditEnabled: false,
      inEditMode: false,
    };

    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.updateEditFlag = this.updateEditFlag.bind(this);
  }

  updateEditFlag(value) {
    this.setState({ isEditEnabled: value });
  }

  toggleEditMode() {
    this.setState({ inEditMode: !this.state.inEditMode });
  }

  render() {
    return (
      <OptionDetails
        {...this.props}
        isDealer={true}
        inEditMode={this.state.inEditMode}
        toggleEditMode={this.toggleEditMode}
        isEditEnabled={this.state.isEditEnabled}
        updateEditFlag={this.updateEditFlag}
      />
    );
  }
}

export default DealerOptions;
