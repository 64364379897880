import React, { Component } from "react";
import styles from "./AccessoriesSettingsEU.module.scss";
import AccessoriesHeader from "./AccessoriesHeader";
import ValidationRules from "../../../../../common/ValidationRules";
import AccessoriesTable from "./AccessoriesTable";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Button } from "@material-ui/core";
import GetFloatValue from "../../../../../utils/GetFloatValue";
import AccessoriesEUClient from "../../../../../shared/clients/AccessoriesEUClient";
import { withTranslation } from "react-i18next";
import AppliedPricing from "../../../../../shared/appliedPricing/AppliedPricing";
import HasPermissionTo from "../../../../../common/HasPermissionTo";
import PendingActionClient from "../../../../../shared/clients/PendingActionClient";
import {
  ACCESSORIES_PRICING,
  DEFAULT_VEHICLE,
  DEFAULT_YEAR,
} from "../../../../../common/Constants";
import PendingActionPrompt from "../../../../../shared/pendingActionPrompt/PendingActionPrompt";
import FormattedLang from "../../../../../utils/FormattedLang";
import UpperCaseText from "../../../../../utils/UpperCaseText";
import VehicleCatalogClient from "../../../../../shared/clients/VehicleCatalogClient";
import { logMsg } from "../../../../../common/Logger";
import Grid from "@material-ui/core/Grid";
import ModelDropDown from "../../currentPricing/ModelDropDown";
import YearDropDown from "../../currentPricing/YearDropDown";

class AccessoriesSettingsEU extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editEnabled: false,
      renderApp: false,
      accessoriesData: {},
      buttonEnabled: false,
      pendingActionPrompt: false,
      selectedModel:
        this.props.location &&
        this.props.location.query &&
        this.props.location.query.model
          ? this.props.location.query.model
          : DEFAULT_VEHICLE,
      selectedYear:
        this.props.location &&
        this.props.location.query &&
        this.props.location.query.model
          ? this.props.location.query.modelYear
          : DEFAULT_YEAR,
      modelData: null,
      catalogIds: [],
      yearDropDownValues: [],
      released: true,
    };
    this.originalAccessoriesMapData = new Map();
    this.modifiedAccessoriesData = {};
    this.currencySymbol =
      (this.props.localizedInfo &&
        this.props.localizedInfo.defaultCurrency &&
        this.props.localizedInfo.defaultCurrency.symbol) ||
      this.props.t("CurrentPricing.na");
    this.onboardedDealerGroup =
      UpperCaseText(this.props.user.userType) === "DEALER" &&
      this.props.user.getOnboardedDealerGroup(true);
  }

  componentDidMount() {
    ValidationRules();
    !this.props.isNsc && this.determinePAStatus();
    this.fetchModelAndYearData();
  }

  onEditClick = () => {
    this.setState({ editEnabled: true });
  };

  onModelChange = (event) => {
    if (event) {
      event.preventDefault();
    }
    const catalogIds = this.getCatalogIds(
      this.state.modelData,
      event.target.value
    );
    this.getYears(this.state.modelData, event.target.value);

    this.setState(
      {
        selectedModel: event.target.value,
        renderApp: false,
        catalogIds,
        accessoriesData: {},
      },
      () => {
        this.fetchAccessoriesData();
      }
    );
  };

  onYearChange = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.getCatalogIds(this.state.selectedModel, event.target.value);
    this.setState(
      {
        selectedYear: event.target.value,
        renderApp: false,
        released: true,
        accessoriesData: {},
      },
      () => {
        this.fetchAccessoriesData();
      }
    );
  };

  handleAdjustmentEdit = (groupName, id, value) => {
    const copy = JSON.parse(JSON.stringify(this.state.accessoriesData));
    copy.forEach((group) => {
      if (group.groupName === groupName) {
        group.accessories.forEach((subGroup) => {
          if (subGroup.id === id) {
            subGroup.adjustment = value;
          }
        });
      }
    });
    this.setState({ accessoriesData: copy });
  };

  getYears = (data, selectedModel) => {
    const years =
      data &&
      data
        .filter((x) => x.model === selectedModel)
        .map((model) => model.year.toString());

    this.setState({
      yearDropDownValues: years,
      selectedYear: years && years[0],
    });
  };

  getMaxYear = (data, selectedModel) => {
    const years =
      data &&
      data.filter((x) => x.model === selectedModel).map((model) => model.year);

    return Math.max.apply(Math, years);
  };
  getDefaultModel = (vehicleLines) => {
    const models = vehicleLines.map((vehicleLine) => vehicleLine.model);
    return models.includes(this.state.selectedModel)
      ? this.state.selectedModel
      : models[0];
  };

  getCatalogIds = (selectedModel, selectedYear) => {
    const catalogIds = [];
    if (selectedYear !== null && selectedModel !== null) {
      this.state.modelData &&
        this.state.modelData.forEach((vehicleLine) => {
          if (
            vehicleLine.model.toString() === selectedModel.toString() &&
            vehicleLine.year.toString() === selectedYear.toString()
          ) {
            catalogIds.push(vehicleLine.catalogId);
          }
        });
      this.setState({ catalogIds });
    }
  };

  fetchModelAndYearData() {
    this.props.hideOrShow(true);
    this.getVehicleLinesData()
      .then((res) => {
        this.setState({ modelData: res });
        if (
          this.props.location &&
          this.props.location.actionName === ACCESSORIES_PRICING
        ) {
          this.fromPendingAction();
        } else {
          const selectedModel = this.getDefaultModel(res);
          this.getYears(res, selectedModel);
          const selectedYear = this.getMaxYear(res, selectedModel);
          this.setState(
            {
              selectedModel,
              selectedYear,
            },
            () => this.getCatalogIds(selectedModel, this.state.selectedYear)
          );
        }
      })
      .catch((error) => {
        logMsg("reason", error);
      })
      .finally(() => {
        this.fetchAccessoriesData();
        this.props.hideOrShow(false);
      });
  }
  fromPendingAction = () => {
    this.setState({
      selectedModel:
        this.props.location.query && this.props.location.query.model,
      selectedYear:
        this.props.location.query && this.props.location.query.modelYear,
    });
    this.getYears(this.state.modelData, this.props.location.query.model);
    this.props.location.query &&
      this.props.location.query.model &&
      this.props.location.query.modelYear &&
      this.getCatalogIds(
        this.props.location.query.model,
        this.props.location.query.modelYear
      );
  };

  fetchAccessoriesData = () => {
    this.getAccessoriesData()
      .then((res) => {
        if (res) {
          this.setState({ accessoriesData: res.accessoriesGroup }, () => {
            this.checkReleaseStatus();
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        this.makeCopyOfData();
        this.setState({ renderApp: true });
        this.props.hideOrShow(false);
      });
  };
  checkReleaseStatus = () => {
    this.state.accessoriesData.forEach((group) => {
      group.accessories.forEach((subGroup) => {
        if (!subGroup.released) {
          this.setState({ released: false });
        }
      });
    });
  };
  makeCopyOfData = () => {
    this.modifiedAccessoriesData = JSON.parse(
      JSON.stringify(this.state.accessoriesData)
    );
    const accessoriesData = JSON.parse(
      JSON.stringify(this.state.accessoriesData)
    );
    accessoriesData.length > 0 &&
      accessoriesData.forEach((group) => {
        group.accessories.forEach((subGroup) => {
          this.originalAccessoriesMapData.set(
            group.groupName + subGroup.id,
            subGroup
          );
        });
      });
  };

  getAccessoriesData = () => {
    this.props.hideOrShow(true);
    if (this.props.isNsc) {
      return AccessoriesEUClient.getNscAccessoriesData(
        this.props.user,
        this.state.catalogIds
      );
    } else {
      return AccessoriesEUClient.getDealerAccessoriesData(
        this.props.user,
        FormattedLang(this.props.lang),
        this.state.catalogIds
      );
    }
  };

  getVehicleLinesData = () => {
    if (this.props.isNsc) {
      return VehicleCatalogClient.getNscVehicleLines(this.props.user, true);
    } else {
      return VehicleCatalogClient.getDealerVehicleLines(this.props.user, true);
    }
  };

  updateAdjustments = () => {
    this.setState(
      {
        editEnabled: false,
        buttonEnabled: false,
      },
      () => {
        this.modifiedAccessoriesData = JSON.parse(
          JSON.stringify(this.state.accessoriesData)
        );
      }
    );
    this.modifiedAccessoriesData.forEach((group) => {
      group.accessories.forEach((subGroup) => {
        const record = this.originalAccessoriesMapData.get(
          group.groupName + subGroup.id
        );
        if (
          GetFloatValue(subGroup.adjustment) !==
          GetFloatValue(record.adjustment)
        ) {
          this.setState({ buttonEnabled: true });
        }
      });
    });
  };
  cancelUpdate = () => {
    this.setState({
      editEnabled: !this.state.editEnabled,
      accessoriesData: this.modifiedAccessoriesData,
    });
  };

  accessoriesRequest = () => {
    const updatedAccessoriesData = [];
    this.state.accessoriesData.forEach((group) => {
      group.accessories.forEach((subGroup) => {
        const record = this.originalAccessoriesMapData.get(
          group.groupName + subGroup.id
        );
        if (
          GetFloatValue(record.adjustment) !==
            GetFloatValue(subGroup.adjustment) ||
          !subGroup.released
        ) {
          const temp = {};
          temp.groupName = group.groupName;
          temp.accessories = [];
          let tempAcc = {};

          tempAcc = subGroup;
          tempAcc.released = true;
          temp.accessories.push(tempAcc);
          updatedAccessoriesData.push(temp);
        }
      });
    });
    return updatedAccessoriesData;
  };

  saveNSCAccessories = () => {
    this.props.hideOrShow(true);
    AccessoriesEUClient.saveNSCAccessories(
      this.props.user,
      this.accessoriesRequest(),
      this.state.catalogIds
    )
      .then(() => {
        this.removeNscPA();
        this.setState({ released: true });
        this.fetchAccessoriesData();
      })
      .catch()
      .finally(() => {
        this.setState({ buttonEnabled: false });
        this.props.hideOrShow(false);
      });
  };

  getVehicleLine = () => {
    return (
      this.state.modelData &&
      this.state.modelData
        .filter(
          (vehicleInfo) =>
            vehicleInfo.model === this.state.selectedModel &&
            Number(vehicleInfo.year) === Number(this.state.selectedYear)
        )
        .map((vehicleInfo) => vehicleInfo.vehicleLine)
    );
  };
  removeNscPA = () => {
    PendingActionClient.removeNscPendingAction(
      this.props.user,
      ACCESSORIES_PRICING,
      this.getVehicleLine()
    );
  };

  saveDealerAccessories = () => {
    if (
      this.props.user.showAll &&
      this.onboardedDealerGroup.length > 1 &&
      !this.disablePendingActionPrompt
    ) {
      this.setState({ pendingActionPrompt: true });
    } else {
      this.postDealerAccessories(
        this.props.user.getDealerIdsWithoutSDLR(),
        this.props.user.getCommonIdsWithoutSDLR(true)
      );
    }
  };

  postDealerAccessories = (dealerIds, commonIds) => {
    this.props.hideOrShow(true);
    AccessoriesEUClient.saveDealerAccessories(
      this.props.user,
      commonIds,
      this.accessoriesRequest(),
      this.state.catalogIds
    )
      .then(() => {
        this.removeDealerPA(dealerIds);
        this.setState({ released: true });
        this.fetchAccessoriesData();
      })
      .catch()
      .finally(() => {
        this.setState({ buttonEnabled: false, pendingActionPrompt: false });
        this.props.hideOrShow(false);
      });
  };

  removeDealerPA = (dealerIds) => {
    PendingActionClient.removeDealerPendingAction(
      this.props.user,
      dealerIds,
      ACCESSORIES_PRICING,
      this.getVehicleLine()
    ).then(() => {
      this.props.getPendingActions().then(() => {
        this.determinePAStatus();
      });
    });
  };
  pendingActionPromptApplyAll = () => {
    const dealerIds = this.pendingActionStatusForDealers[0].map((dealer) => {
      return dealer.dealerId;
    });
    const commonIds = this.pendingActionStatusForDealers[0].map((dealer) => {
      return dealer.commonId.split("|").join("%7C");
    });
    this.postDealerAccessories(dealerIds, commonIds);
  };

  pendingActionPromptApplySelective = () => {
    const dealerIds = this.pendingActionStatusForDealers[0]
      .filter((dealer) => !dealer.pendingActionStatus)
      .map((dealer) => {
        return dealer.dealerId;
      });
    const commonIds = this.pendingActionStatusForDealers[0]
      .filter((dealer) => !dealer.pendingActionStatus)
      .map((dealer) => {
        return dealer.commonId.split("|").join("%7C");
      });
    this.postDealerAccessories(dealerIds, commonIds);
  };

  pendingActionPromptClose = () => {
    this.setState({ pendingActionPrompt: false });
  };

  determinePAStatus() {
    this.pendingActionStatusForDealers =
      this.props.user.getPendingActionStatusForDealers(
        this.props.user.pendingActions,
        ACCESSORIES_PRICING
      );

    this.disablePendingActionPrompt =
      this.pendingActionStatusForDealers.length === 0;
  }

  render() {
    return (
      this.state.renderApp && (
        <ValidatorForm onSubmit={this.updateAdjustments}>
          <div
            className={`${styles.dropdownContainer} pagePaddingLeft pagePaddingTop`}
          >
            <Grid item md={3} xs={4}>
              <div className={styles.subTitle}>
                {this.props.t("NamePlate.model")}
              </div>
              <ModelDropDown
                {...this.props}
                modelData={this.state.modelData}
                selectedModel={this.state.selectedModel}
                modelDropDownValues={this.state.modelDropDownValues}
                updateModel={this.onModelChange}
              />
            </Grid>

            <Grid item md={3} xs={4}>
              <div className={styles.subTitle}>
                {this.props.t("NamePlate.modelYear")}
              </div>
              <YearDropDown
                {...this.props}
                yearValues={this.state.yearDropDownValues}
                modelData={this.state.modelData}
                selectedModel={this.state.selectedModel}
                updateYear={this.onYearChange}
                selectedYear={this.state.selectedYear}
                showAll={false}
              />
            </Grid>
          </div>
          {this.state.accessoriesData.length > 0 ? (
            <>
              <div className={styles.tableDiv}>
                <AccessoriesHeader
                  {...this.props}
                  editEnabled={this.state.editEnabled}
                  cancelUpdate={this.cancelUpdate}
                  onEditClick={this.onEditClick}
                />
                {this.state.accessoriesData.map((subGroup, index) => {
                  return (
                    <AccessoriesTable
                      {...this.props}
                      key={index}
                      currencySymbol={this.currencySymbol}
                      accessoriesData={subGroup}
                      editEnabled={this.state.editEnabled}
                      handleAdjustmentEdit={this.handleAdjustmentEdit}
                    />
                  );
                })}
              </div>
              <HasPermissionTo
                permissions={this.props.user.permissions.rolePermissions}
                perform={["accessoriesEU:view"]}
                condition={
                  this.props.isNsc
                    ? true
                    : !this.props.isMainViewingSDLR(this.props.user)
                }
                render={() => (
                  <div className={styles.editableHeader}>
                    {this.state.released && !this.state.buttonEnabled ? (
                      <AppliedPricing />
                    ) : (
                      <Button
                        data-testid="applyOptionsBtn"
                        variant="contained"
                        onClick={
                          this.props.isNsc
                            ? this.saveNSCAccessories
                            : this.saveDealerAccessories
                        }
                        className={styles.applyBtn}
                        component="button"
                        disabled={this.state.editEnabled}
                      >
                        <span>
                          {this.props.t("AccessoriesPricing.applyAndRelease")}
                        </span>
                      </Button>
                    )}
                  </div>
                )}
              />
            </>
          ) : (
            <div className={styles.notFound}>
              {this.props.t("NamePlate.dataNotFound")}
            </div>
          )}
          {!this.props.isNsc && (
            <PendingActionPrompt
              {...this.props}
              disabled={this.disablePendingActionPrompt}
              dealersActioned={
                this.disablePendingActionPrompt
                  ? []
                  : this.pendingActionStatusForDealers[0].filter(
                      (dealer) => dealer.pendingActionStatus
                    )
              }
              dealersNotActioned={
                this.disablePendingActionPrompt
                  ? []
                  : this.pendingActionStatusForDealers[0].filter(
                      (dealer) => !dealer.pendingActionStatus
                    )
              }
              title={this.props.t("PendingActionPrompt.pricing")}
              buttonTitle={this.props.t("PendingActionPrompt.pricingButton")}
              onClose={this.pendingActionPromptClose}
              open={this.state.pendingActionPrompt}
              applyAll={this.pendingActionPromptApplyAll}
              applySome={this.pendingActionPromptApplySelective}
            />
          )}
        </ValidatorForm>
      )
    );
  }
}

export default withTranslation("emp")(AccessoriesSettingsEU);
