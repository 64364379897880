import React, { Component } from "react";
import FileBrowser from "../../../../shared/fileBrowser/FileBrowser";
import Button from "@material-ui/core/Button";
import styles from "./UploadDealerPricing.module.scss";
import Grid from "@material-ui/core/Grid";
import excelIcon from "../../../../imgs/excel-icon.svg";
import ClearIcon from "@material-ui/icons/Clear";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import { InfoIconWithMessage } from "../../../../shared/infoIcon/InfoIcon";
import UploadDealerPriceClient from "../../../../shared/clients/UploadDealerPriceClient";
import BackToButton from "../../../../shared/backToButton/BackToButton";
import SubNav from "../../../../shared/subnav/SubNav";
import { BRANDS } from "../../../accessories/Brands";
import LoadDealerPricing from "./LoadDealerPricing";
import ShowInventoryData from "./ShowInventoryData";
import { ValidatorForm } from "react-material-ui-form-validator";
import moment from "moment";
import { REGEX_LIST } from "../../../../common/Constants";
import commonStyles from "../../../../styles/common/CommonStyles.module.scss";
import { PricesApplied } from "../../../../shared/feedback/PricesApplied";
import HasPermissionTo from "../../../../common/HasPermissionTo";
import MarketPreferencesClient from "../../../../shared/clients/MarketPreferencesClient";
import { base64FileDownload } from "../../../../utils/FileDownloadUtil";
import { withStyles } from "@material-ui/core/styles";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

const StyledSaveAltIcon = withStyles({
  root: {
    height: 18,
    margin: 2,
    width: 18,
    padding: 0,
  },
})(SaveAltIcon);

class UploadDealerPricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      displayDetails: false,
      enablePublish: false,
      effectiveDate: "",
      displayError: false,
      openModal: false,
      isEditable: false,
      editButtonDisable: {},
      allChecked: false,
      selectedPrices: [],
      fileExtractingData: [],
      pricesApplied: false,
      conversionRate: "1.0",
      pricesUploaded: false,
      numberOfPricesUploaded: 0,
      numberOfPricesPublished: 0,
    };
    this.restrictDropdown = false;
    this.removeFile = this.removeFile.bind(this);
  }

  componentDidMount() {
    this.setState({
      selectedPrices: this.props.dealerInventoryPrices,
      effectiveDate: new Date(),
    });
    this.getMarketConversionRate();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.dealerInventoryPrices &&
      this.props.dealerInventoryPrices.length !==
        prevProps.dealerInventoryPrices.length
    ) {
      this.setState({ selectedPrices: this.props.dealerInventoryPrices });
    } else if (
      this.props.dealerInventoryPrices &&
      prevProps.dealerInventoryPrices &&
      this.props.dealerInventoryPrices.length ===
        prevProps.dealerInventoryPrices.length
    ) {
      let shouldUpdate = false;
      prevProps.dealerInventoryPrices.forEach((prevPrice, index) => {
        if (
          this.props.dealerInventoryPrices[index] &&
          (prevPrice.currencyAdjustedPrice !==
            this.props.dealerInventoryPrices[index].currencyAdjustedPrice ||
            prevPrice.published !==
              this.props.dealerInventoryPrices[index].published)
        ) {
          shouldUpdate = shouldUpdate || true;
        }
      });
      if (shouldUpdate) {
        this.setState({ selectedPrices: this.props.dealerInventoryPrices });
      }
    }
  }

  onFileSelected = (file) => {
    let validFileType = false;
    if (
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      validFileType = true;
    }
    if (file.size > 1000000 || !validFileType) {
      //Need to display an error message like file exceeds 70kb
      this.setState({ displayError: true, file: null, displayDetails: false });
    } else {
      this.setState({
        file: file,
        displayDetails: true,
        displayError: false,
        enablePublish: true,
        effectiveDate: new Date(),
      });
    }
  };
  handleClose = () => {
    this.setState({ openModal: false });
  };
  updateInventoryPrice = (event) => {
    // Edit functions
    const originalDisables = this.state.editButtonDisable;
    if (!event.target.value.match(REGEX_LIST.number) || !event.target.value) {
      originalDisables[event.target.name] = true;
    } else {
      originalDisables[event.target.name] = false;
    }

    // change the current-editing value but retain the other records edited values as well
    const newPrices = this.state.selectedPrices.map((price) => {
      const newPrice = { ...price };
      if (event.target.name === newPrice.vin) {
        newPrice.currencyAdjustedPrice = event.target.value;
        newPrice.checked = false;
        newPrice.priceModified = true;
        if (this.props.user && this.props.user.market === "ARG") {
          newPrice.usdCustomerPrice =
            this.state.conversionRate * event.target.value;
        }
      }
      return newPrice;
    });
    this.setState({
      editButtonDisable: originalDisables,
      selectedPrices: newPrices,
      pricesApplied: false,
    });
  };

  getCount = () => {
    const prices = this.state.selectedPrices;
    let count = 0;
    prices.length > 0 &&
      prices.forEach((price) => {
        if (price.checked) {
          count += 1;
        }
      });
    return count > 0 ? count : null;
  };

  getCountForEdit = () => {
    const prices = this.state.selectedPrices;
    let isChanged = false;
    prices &&
      prices.length > 0 &&
      prices.forEach((price) => {
        if (!price.published) {
          isChanged = isChanged || true;
        } else {
          const propsPrice = this.props.dealerInventoryPrices.find(
            (propsprice) => price.vin === propsprice.vin
          );
          if (
            propsPrice &&
            propsPrice.currencyAdjustedPrice !== price.currencyAdjustedPrice
          ) {
            isChanged = isChanged || true;
          }
        }
      });
    if (!isChanged) {
      this.setState({ pricesApplied: true });
    }
    return isChanged;
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      openModal: !prevState.openModal,
    }));
  };
  fillAllCheckBox = () => {
    const checkedOrNot = !this.state.allChecked;
    const allPrices = this.state.selectedPrices;
    allPrices.forEach((invprice) => {
      if (invprice.vin !== "") {
        if (invprice.priceModified) {
          invprice.checked = true;
        } else {
          invprice.checked = checkedOrNot;
        }
      }
    });
    this.setState({
      selectedPrices: allPrices,
      allChecked: checkedOrNot,
    });
  };
  updatePriceModifiedFlag = (modifiedPrices) => {
    modifiedPrices.forEach((modified) => {
      this.state.selectedPrices.forEach((price) => {
        if (modified.vin === price.vin) {
          price.priceModified = false;
          price.checked = false;
        }
      });
    });
  };
  uploadInventoryPrices = () => {
    const modifiedPrices = this.state.selectedPrices.filter(
      (price) => price.checked
    );
    this.props.hideOrShow(true);
    UploadDealerPriceClient.updateDealerInventoryPrices(
      this.props.user,
      modifiedPrices,
      this.state.conversionRate
    )
      .then((data) => {
        if (data && data.message && data.message.code === "200") {
          // this.props.uncheckEverything();
          //finally reload the data
          this.props.loadDealerInventoryPricingHistory();

          //and reset the states
          this.setState({
            // selectedPrices: this.props.dealerInventoryPrices,
            numberOfPricesPublished: modifiedPrices.length,
            isEditable: false,
            editButtonDisable: {},
            allChecked: false,
            pricesApplied: true,
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        this.props.hideOrShow(false);
      });
    this.updatePriceModifiedFlag(modifiedPrices);
  };
  clickCheckBox = (event) => {
    const index = event.target.value;
    const originalState = this.state.selectedPrices;
    if (originalState && originalState[index]) {
      originalState[index].checked = !originalState[index].checked;
      const filteredPrice = originalState.filter(
        (invprice) => invprice.checked === true
      );
      this.setState({
        selectedPrices: originalState,
        allChecked: this.checkIfAllChecked(filteredPrice),
      });
    }
  };

  checkIfAllChecked = (filteredPrice) => {
    return filteredPrice.length === this.state.selectedPrices.length;
  };
  getMarketConversionRate = () => {
    MarketPreferencesClient.getConversionRate(this.props.user)
      .then((response) => {
        let rate = 1.0;
        if (response.result.conversionRate) {
          rate = response.result.conversionRate;
        }
        this.setState({
          conversionRate: rate,
        });
      })
      .catch(() => {})
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  EditReservationsHandler = () => {
    this.setState({
      isEditable: !this.state.isEditable,
    });
  };

  uploadToExtractFile = () => {
    this.uploadDealerPrice(0);
  };

  uploadDealerPriceToSave = () => {
    this.setState({ openModal: false });
    this.uploadDealerPrice(1);
  };

  uploadDealerPrice = (processType) => {
    this.setState({ enablePublish: false });
    this.props.hideOrShow(true);
    UploadDealerPriceClient.uploadDealerPrice(
      this.props.user,
      this.state.file,
      this.state.effectiveDate,
      processType,
      this.state.conversionRate
    )
      .then((data) => {
        if (processType === 1) {
          this.removeFile();
          this.props.loadDealerInventoryPricingHistory();
          this.setState({
            numberOfPricesUploaded:
              data &&
              data.dealerPricesDataList &&
              data.dealerPricesDataList[0].dealerInvPriceData &&
              data.dealerPricesDataList[0].dealerInvPriceData.length,
          });
        } else {
          this.setState({
            fileExtractingData:
              data &&
              data.dealerPricesDataList &&
              data.dealerPricesDataList[0].dealerInvPriceData,
            numberOfPricesUploaded:
              data &&
              data.dealerPricesDataList &&
              data.dealerPricesDataList[0].dealerInvPriceData &&
              data.dealerPricesDataList[0].dealerInvPriceData.length,
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        this.props.hideOrShow(false);
        if (processType === 0) {
          this.toggleModal();
        } else if (processType === 1) {
          this.setState({
            pricesUploaded: true,
          });
        }
      });
  };

  removeFile = () => {
    document.getElementById("xls-file").value = "";
    this.setState({
      file: null,
      displayDetails: false,
      enablePublish: false,
      effectiveDate: "",
    });
  };

  getSubNav = () => {
    return (
      <>
        <BackToButton
          backToUrl="/pricing"
          display={this.props.t("PricingWrapper.backToPricing")}
        />
        <SubNav
          {...this.props}
          title={this.props.t("UploadDealerPrice.header")}
          renderSubHeader={false}
          showCustomDropdown={false}
          subHeader={null}
          restrictDropdown={this.restrictDropdown}
          dropDownValues={BRANDS}
        />
      </>
    );
  };

  downloadInvPriceTemplate = () => {
    this.props.hideOrShow(true);
    UploadDealerPriceClient.downloadInvPriceTemplate(
      this.props.user,
      this.props.t("UploadDealerPrice.templateColumnNames")
    )
      .then((data) => {
        if (data.base64String) {
          const BOM = "\uFEFF";
          const response = BOM + data.base64String;
          base64FileDownload(
            decodeURIComponent(response),
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            this.props.t("UploadDealerPrice.templateName") + ".xlsx"
          );
        }
        this.props.hideOrShow(false);
      })
      .catch(() => {});
  };

  render() {
    return (
      <div className="pageWrapper">
        {!this.props.fromQuickstart && this.getSubNav()}
        {this.props.enableUpload !== undefined && (
          <div className={styles.uploadTermsAndConditions}>
            {this.props.enableUpload ? (
              <>
                <HasPermissionTo
                  perform={["dealerInventoryUpload:write"]}
                  permissions={this.props.user.permissions.rolePermissions}
                  render={() => {
                    return (
                      <div>
                        <div
                          className={`${styles.download} ${styles.downloadPointer} ${styles.downloadFile} pagePadding`}
                        >
                          <StyledSaveAltIcon />
                          <ax
                            href={null}
                            onClick={this.downloadInvPriceTemplate}
                            className={`${styles.download}`}
                          >
                            {this.props.t("UploadDealerPrice.downloadTemplate")}
                          </ax>
                        </div>
                        <div className="pagePadding">
                          <InfoIconWithMessage
                            message={this.props.t(
                              "UploadDealerPrice.fileHeaderText"
                            )}
                          />
                        </div>

                        <div className="pagePaddingLeft">
                          <FileBrowser
                            fileType=".xls,.xlsx"
                            onFileSelected={this.onFileSelected}
                            id="xls-file"
                          >
                            <Button
                              className={styles.uploadBtn}
                              component="span"
                              id="upload-file-btn"
                            >
                              {this.props.t(
                                "UploadDealerPrice.uploadDealerPriceFile"
                              )}
                            </Button>
                          </FileBrowser>
                          <p className={styles.fileTypeText}>
                            {this.props.t("UploadDealerPrice.tipText")}
                          </p>
                          {this.state.displayError && (
                            <div className={styles.warningDiv}>
                              <div className={styles.warning}></div>
                              <div className={styles.errorText}>
                                {this.props.t("UploadDealerPrice.fileError")}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }}
                />
                {this.state.displayDetails && (
                  <>
                    <Grid
                      container
                      className={`${styles.effectiveBox} pagePaddingLeft`}
                      id="effectiveRow"
                    >
                      <Grid item xs={4} className={styles.effectiveDateRow}>
                        <div className={styles.fileNameTextBox}>
                          <div>
                            <img src={excelIcon} />
                            <p>{this.state.file.name}</p>
                          </div>
                          <div
                            className={styles.clearImage}
                            id="removeFile"
                            onClick={this.removeFile}
                          >
                            <ClearIcon />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={8} className={styles.effectiveDateRow}>
                        <span className={styles.effectiveText}>
                          Effective From{" "}
                        </span>
                        <div className={styles.effectiveDateText}>
                          {moment(this.state.effectiveDate).toString()}
                        </div>
                      </Grid>
                    </Grid>
                    <div className={styles.publishButton}>
                      <Button
                        type="button"
                        onClick={this.uploadToExtractFile}
                        className="greenBtn"
                        id="publish-action-btn"
                        disabled={!this.state.enablePublish}
                      >
                        {this.props.t("UploadDealerPrice.publish")}
                      </Button>
                    </div>
                  </>
                )}
              </>
            ) : (
              <div id="termsHeaderWithWarning" className="pagePadding">
                <InfoIconWithMessage
                  message={[
                    this.props.t("BusinessSettings.termsandConditionsHeader"),
                    " ",
                    // eslint-disable-next-line react/jsx-key
                    <span className={styles.previewTitleWarning}>
                      {this.props.t("BusinessSettings.maximumUploadWarning")}
                    </span>,
                  ]}
                />
              </div>
            )}
          </div>
        )}
        <ShowInventoryData
          {...this.props}
          open={this.state.openModal}
          cancel={this.handleClose}
          closeModal={this.toggleModal}
          showUsdCustomerPrice={this.props.t(
            "UploadDealerPrice.showUsdCustomerPrice"
          )}
          uploadDealerPriceToSave={this.uploadDealerPriceToSave}
          toggleModal={this.toggleModal}
          checkAllCheckBox={this.fillAllCheckBox}
          allChecked={this.state.allChecked}
          selectedPrices={this.state.fileExtractingData}
          conversionRate={this.state.conversionRate}
        />
        <ValidatorForm
          id="modifyReservationForm"
          ref={this.props.formRef}
          onSubmit={this.uploadInventoryPrices}
        >
          <LoadDealerPricing
            {...this.props}
            showValidatdColumn={true}
            applyBtn={this.applyBtnHandler}
            showUsdCustomerPrice={this.props.t(
              "UploadDealerPrice.showUsdCustomerPrice"
            )}
            editMode={this.state.isEditable}
            editReservations={(e) => this.EditReservationsHandler(e)}
            clickCheckBox={(e) => this.clickCheckBox(e)}
            updateInventoryPrice={this.updateInventoryPrice}
            allChecked={this.state.allChecked}
            checkAllCheckBox={this.fillAllCheckBox}
            selectedPrices={this.state.selectedPrices}
            conversionRate={this.state.conversionRate}
          />
          {this.getCount() > 0 &&
          this.getCountForEdit() &&
          !this.state.pricesApplied ? (
            <div className={styles.buttonDiv}>
              <button id="saveBtn" className={commonStyles.greenBtn}>
                {`${this.props.t("ActionFooter.apply")} `}
              </button>
            </div>
          ) : (
            <PricesApplied
              success={this.state.pricesApplied}
              text={`${this.state.numberOfPricesPublished} ${this.props.t(
                "ModifyReservationPrice.vinsPublishedSuccessfully"
              )}`}
            />
          )}
        </ValidatorForm>

        <PricesApplied
          success={this.state.pricesUploaded}
          text={`${this.state.numberOfPricesUploaded} ${this.props.t(
            "ModifyReservationPrice.vinsUploadedSuccessfully"
          )}`}
        />
      </div>
    );
  }
}

export default withTranslation("emp")(UploadDealerPricing);
