import React from "react";
import Item from "../../../../shared/item/Item";
import styles from "./PricingHeader.module.scss";
import CheckBox from "../../../../shared/checkBox/CheckBox";
import Clear from "@material-ui/icons/Clear";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import SortingIcon from "../../../../shared/sortingIcon/SortingIcon";
import HasPermissionTo from "../../../../common/HasPermissionTo";
import { Table, TableCell, TableHead } from "@material-ui/core";
import { withTranslation } from "react-i18next";

const PricingHeader = (props) => {
  const index = props.index;
  const editEnabled = props.isEditEnabled;

  const StyledClear = withStyles({
    root: {
      width: 20,
      height: 20,
      fill: "#e81607",
    },
  })(Clear);
  const StyledSpecsTableCell = withStyles({
    root: {
      borderBottom: 0,
      fontWeight: 500,
      padding: 0,
      width: "15%",
      textAlign: "left",
      color: "#102b4e",
      fontSize: "12px",
      letterSpacing: "1px",
      textTransform: "uppercase",
      verticalAlign: "bottom",
    },
  })(TableCell);
  const NonEditTableCell = withStyles({
    root: {
      borderBottom: 0,
      fontWeight: 500,
      padding: 0,
      width: "15%",
      textAlign: "center",
      color: "#102b4e",
      fontSize: "12px",
      letterSpacing: "1px",
      textTransform: "uppercase",
      verticalAlign: "bottom",
    },
  })(TableCell);
  const WarningTableCell = withStyles({
    root: {
      borderBottom: 0,
      paddingLeft: "10px",
      paddingRight: "10px",
      width: "30px",
    },
  })(TableCell);
  const CheckBoxTableCell = withStyles({
    root: {
      borderBottom: 0,
      padding: 0,
      width: "8%",
    },
  })(TableCell);
  const sort = (index) => !editEnabled && props.onSort(index);
  const showVariableMarketing =
    props.user.userType === "nsc" ||
    (props.user.userType === "dealer" && props.user.market !== "NOR");

  return (
    <div className={`${styles.rowBorder}`}>
      <Table>
        <TableHead>
          <WarningTableCell />
          <CheckBoxTableCell>
            <CheckBox
              className="toggleAllCheckbox"
              id={"toggleAllCheckbox" + index}
              checked={true}
              isDisabled={props.isDisabled}
            />
          </CheckBoxTableCell>

          <StyledSpecsTableCell
            id={"specs" + index}
            onClick={() => sort("specs")}
          >
            <div className={styles.specsHeaderWrap}>
              <Item value={props.t("PricingHeader.specification")} />
              <span className={styles.sorting}>
                <SortingIcon
                  currentKey="specs"
                  hide={editEnabled}
                  sortKey={props.sortKey}
                  sortDirection={props.sortDirection}
                  index={index}
                />
              </span>
            </div>
          </StyledSpecsTableCell>

          <NonEditTableCell
            id={"RRPItem" + index}
            item
            xs={1}
            onClick={() => sort("rrp")}
          >
            <div
              className={`${styles.vehiclesHeaderWrap} ${styles.vehiclesHeader}`}
            >
              <Item
                className={styles.headerWidth}
                value={props.t("PricingHeader.rrp")}
              />
              <span className={styles.sorting}>
                <SortingIcon
                  currentKey="rrp"
                  hide={editEnabled}
                  sortKey={props.sortKey}
                  sortDirection={props.sortDirection}
                  index={index}
                />
              </span>
            </div>
          </NonEditTableCell>

          <NonEditTableCell
            id={"VMItem" + index}
            className={styles.vehiclesShadowRow}
            onClick={() => sort("vm")}
          >
            <div className={styles.vmDiv}>
              {showVariableMarketing && (
                <div className={styles.vmTopText}>
                  <Item value={props.t("VariableMarketing.header")} />
                </div>
              )}
              <div className={styles.vmText}>
                <Item
                  className={styles.headerWidth}
                  value={props.t("PricingHeader.offers")}
                />
                <span className={styles.sorting}>
                  <SortingIcon
                    currentKey="vm"
                    hide={editEnabled}
                    sortKey={props.sortKey}
                    sortDirection={props.sortDirection}
                    index={index}
                  />
                </span>
              </div>
            </div>
          </NonEditTableCell>

          <NonEditTableCell>
            <HasPermissionTo
              permissions={props.user.permissions.rolePermissions}
              perform={["vehiclePricing:write"]}
              condition={!props.user.readOnly}
              render={() =>
                editEnabled ? (
                  <>
                    <div>
                      <div
                        data-testid="dealerAdjustmentUpdateHeader"
                        className={styles.vehiclesHeaderUpdate}
                      >
                        <Item
                          value={props.t("PricingHeader.dealerAdjustment")}
                        />
                      </div>
                    </div>
                    <div className={styles.vehiclesHeaderWrap}>
                      <Button
                        size="small"
                        name="Cancel"
                        data-testid={"cancelPricing" + index}
                        onClick={() => {
                          props.cancelChanges();
                        }}
                      >
                        <StyledClear />
                      </Button>
                      <Button
                        size="small"
                        name="Confirm"
                        type="submit"
                        data-testid={"confirmPricing" + index}
                      >
                        <span className={styles.vehiclesHeaderWrap}>
                          {props.t("PricingHeader.update")}
                        </span>
                      </Button>
                    </div>
                  </>
                ) : (
                  <div
                    className={`${styles.vehiclesHeaderWrap} ${styles.width85}`}
                  >
                    <span className={styles.width85}>
                      <Item
                        className={styles.headerWidth}
                        value={props.t("PricingHeader.dealerAdjustment")}
                      />
                    </span>
                    <div className={styles.editBtnPaddingRight}>
                      <div
                        className={styles.Edit}
                        onClick={(e) => {
                          props.editRecords(e);
                        }}
                        data-testid={"editPricing" + index}
                      >
                        <div className={styles.RectangleStart} />
                        <div className={styles.RectangleEnd} />
                      </div>
                    </div>
                  </div>
                )
              }
              noRender={() => (
                <div className={styles.vehiclesHeaderWrap}>
                  <Item
                    className={styles.headerWidth}
                    value={props.t("PricingHeader.dealerAdjustment")}
                  />
                </div>
              )}
            />
          </NonEditTableCell>

          <NonEditTableCell
            data-testid={"dealerVehiclePrice" + index}
            item
            xs={2}
            onClick={() => sort("dealerVehiclePrice")}
          >
            <div className={styles.vehiclesHeaderWrap}>
              <Item
                className={styles.headerWidth}
                value={props.t("PricingHeader.dealerVehiclePrice")}
              />
              <span className={styles.sorting}>
                <SortingIcon
                  currentKey="dealerVehiclePrice"
                  hide={editEnabled}
                  sortKey={props.sortKey}
                  sortDirection={props.sortDirection}
                  index={index}
                />
              </span>
            </div>
          </NonEditTableCell>
          <NonEditTableCell
            data-testid={"transPriceItem" + index}
            item
            xs={2}
            onClick={() => sort("transactionPrice")}
          >
            <div className={styles.vehiclesHeaderWrap}>
              <Item
                className={styles.headerWidth}
                value={props.t("PricingHeader.dealerTransactionPrice")}
              />
              <span className={styles.sorting}>
                <SortingIcon
                  currentKey="transactionPrice"
                  hide={editEnabled}
                  sortKey={props.sortKey}
                  sortDirection={props.sortDirection}
                  index={index}
                />
              </span>
            </div>
          </NonEditTableCell>
        </TableHead>
      </Table>
    </div>
  );
};

export default withTranslation("emp")(PricingHeader);
