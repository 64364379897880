import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { BASE_PRICING_URL } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

export default class PricingClient {
  static pricingUrl = BASE_PRICING_URL;

  static getNscPricing(user, model, year) {
    const url = `${
      this.pricingUrl
    }/${user.generateResourcePath()}/pricing?model=${model}&year=${year}`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static getDealerPricing(user, lang, model, year) {
    let url = `${this.pricingUrl}/dealer/${user.market}/pricing?dealerId=${user.dealerId}&model=${model}&year=${year}`;
    if (lang) {
      url = url + `&lang=${lang}`;
    }
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static saveDealerPricingCall(user, data, dealerIds) {
    const url = `${this.pricingUrl}/dealer/${user.market}/pricing?dealerIds=${dealerIds}`;
    return axios
      .post(url, data, getHeaders(user.token))
      .then((response) => response.status)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static saveNscPricingCall(user, data) {
    const url = `${
      this.pricingUrl
    }/${user.generateResourcePath()}/pricing?userId=${user.userId}`;
    return axios
      .post(url, data, getHeaders(user.token))
      .then((response) => response.status)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}
