import React, { Component } from "react";
import OptionsHeader from "../optionsHeader/OptionsHeader";
import OptionsList from "../optionsList/OptionsList";
import styles from "../PricingWrapper.module.scss";
import { Button } from "@material-ui/core";
import SortData from "../../../../utils/SortUtil";
import HasPermissionTo from "../../../../common/HasPermissionTo";
import { ValidatorForm } from "react-material-ui-form-validator";
import { withTranslation } from "react-i18next";
import ValidationRules from "../../../../common/ValidationRules";
import AppliedPricing from "../../../../shared/appliedPricing/AppliedPricing";

class OptionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsData: this.props.optionsData,
      sortDirection: null,
      sortKey: "optionSpec",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.calculatePrice(this.state.optionsData);
    ValidationRules();
    this.sortOptionsData("optionSpec");
    this.makeCopyOfOptionsData();
  }

  handleSubmit() {
    this.optionsDataCopy = this.state.optionsData;
    this.calculatePrice(this.state.optionsData);
    this.props.updateEditFlag(false);
    this.props.updateOptionsData(this.state.optionsData);
    if (this.state.sortKey === "recommendedPrice") {
      this.sortOptionsData(this.state.sortKey, true);
    }
  }

  sortOptionsData(sortByCol, fromSubmit = false) {
    // If dealer has just edited prices, re-sort the way things were previously sorted
    let sortDir;
    if (fromSubmit) {
      sortDir = this.state.sortDirection;
    } else {
      // Default to descending if first click of this arrow
      sortDir = "DSC";
      if (sortByCol === this.state.sortKey) {
        sortDir = this.state.sortDirection === "ASC" ? "DSC" : "ASC";
      }
    }

    const that = this;
    SortData(
      sortByCol,
      sortDir,
      "alphanumeric",
      this.state.optionsData,
      function (sortedData) {
        that.setState({
          optionsData: sortedData,
          sortDirection: sortDir,
          sortKey: sortByCol,
        });
      }
    );
  }

  makeCopyOfOptionsData = () => {
    this.optionsDataCopy = JSON.parse(JSON.stringify(this.state.optionsData));
  };

  editRecords() {
    this.props.toggleEditMode();
    this.props.updateEditFlag(true);
  }

  cancelOptionChanges() {
    this.props.toggleEditMode();
    this.props.updateEditFlag(false);
    this.setState({ optionsData: this.optionsDataCopy });
  }

  updateAdjustmentPrice(event, index) {
    const copy = JSON.parse(JSON.stringify(this.state.optionsData));
    copy[index].adj = event.target.value;
    this.setState({ optionsData: copy });
  }

  calculatePrice(data) {
    //will need to change variable names (?) when real data is available
    data.forEach((row) => {
      const rrp = row.rrp === null ? 0 : row.rrp;
      const vm = row.vm === null ? 0 : row.vm;
      const adj = row.adj === null ? 0 : row.adj;
      row.recommendedPrice = parseFloat(rrp) - parseFloat(vm) + parseFloat(adj);
    });
    this.options = data;
  }

  render() {
    return this.state.optionsData && this.state.optionsData.length > 0 ? (
      <div className={`${styles.pageMarginTop} `}>
        <ValidatorForm onSubmit={this.handleSubmit}>
          <>
            <OptionsHeader
              {...this.props}
              sortKey={this.state.sortKey}
              sortDirection={this.state.sortDirection}
              onClick={(key) => this.sortOptionsData(key)}
              editRecords={(e) => this.editRecords(e)}
              cancelOptionChanges={() => this.cancelOptionChanges()}
            />

            <OptionsList
              {...this.props}
              optionsData={this.state.optionsData}
              updateAdjustmentPrice={(e, index) =>
                this.updateAdjustmentPrice(e, index)
              }
            />

            <div className={styles.actionSection}>
              {this.props.released &&
              !this.props.priceUpdatesAvailable &&
              !this.props.buttonEnabled ? (
                <div className={styles.appliedPricing}>
                  <AppliedPricing />
                </div>
              ) : (
                <HasPermissionTo
                  permissions={this.props.user.permissions.rolePermissions}
                  perform={["vehiclePricing:view"]}
                  render={() => (
                    <Button
                      data-testid="applyOptionsBtn"
                      variant="contained"
                      onClick={(event) => {
                        this.props.savePricing(event);
                      }}
                      className={styles.applyBtn}
                      component="button"
                      disabled={this.props.user.readOnly}
                    >
                      <span>{this.props.t("Options.apply")}</span>
                    </Button>
                  )}
                />
              )}
            </div>
          </>
        </ValidatorForm>
      </div>
    ) : (
      <div className={styles.notFound}>
        {this.props.t("Options.dataNotFound")}
      </div>
    );
  }
}

export default withTranslation("emp")(OptionDetails);
