import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Grid, withStyles } from "@material-ui/core";
import styles from "../AccessoriesPricingWrapper.module.scss";
import HasPermissionTo from "../../../../../common/HasPermissionTo";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { GetFloatToFixedValue } from "../../../../../utils/GetFloatValue";
import Item from "../../../../../shared/item/Item";
import CheckCurrencyValue from "../../../../../utils/CheckCurrencyValue";
import EditIcon from "../../../../../shared/Edit/EditIcon";

const StyledTextValidator = withStyles({
  root: {
    display: "flex",
    justifyContent: "flex-end",
  },
})(TextValidator);

class BrandSettingsList extends Component {
  constructor(props) {
    super(props);

    ValidatorForm.addValidationRule("changeInPrice", (value, model) => {
      const changeInPrice = !(
        this.props.originalData.model === model &&
        this.props.originalData.amount === GetFloatToFixedValue(value, 2)
      );
      this.props.applyBtn(changeInPrice);
      return true;
    });
    this.currencySymbol =
      (this.props.localizedInfo &&
        this.props.localizedInfo.defaultCurrency &&
        this.props.localizedInfo.defaultCurrency.symbol) ||
      this.props.t("CurrentPricing.na");
  }

  canEdit = () => {
    if (this.props.isNsc) {
      return !this.props.editMode && this.props.originalData.amount === "";
    } else {
      return (
        !this.props.editMode && !this.props.isMainViewingSDLR(this.props.user)
      );
    }
  };

  render() {
    return (
      <div>
        <Grid container spacing={0} className={styles.tableRowHeader}>
          <Grid item xs={5}>
            <span className={styles.brandHeader}>
              {this.props.t("AccessoriesPricing.brand")}
            </span>
          </Grid>
          <Grid item xs={2}>
            <div className={styles.laborRate}>
              <span>{this.props.t("AccessoriesPricing.laborRate")}</span>
              <HasPermissionTo
                permissions={this.props.user.permissions.rolePermissions}
                perform={["brandSettings:write"]}
                condition={this.canEdit()}
                render={() => {
                  return (
                    <div>
                      <EditIcon onClick={this.props.handleEdit} />
                    </div>
                  );
                }}
              />
            </div>
          </Grid>
          <Grid item xs={5} />
        </Grid>
        <Grid
          container
          key={this.props.brandSettingsData.model}
          className={styles.tableRowContent}
        >
          <Grid item xs={5} className={styles.rowCellLeft}>
            {this.props.brandSettingsData.model}
          </Grid>
          <HasPermissionTo
            permissions={this.props.user.permissions.rolePermissions}
            perform={["brandSettings:write"]}
            condition={this.props.editMode}
            render={() => (
              <>
                <Grid
                  item
                  xs={2}
                  className={`${styles.brandSettingsInputBox} ${styles.validatorCell}`}
                >
                  <span className={styles.currency}>
                    &nbsp;&nbsp;
                    {this.currencySymbol || ""}
                  </span>
                  <StyledTextValidator
                    autoComplete="off"
                    name={this.props.brandSettingsData.model}
                    variant="outlined"
                    className={styles.brandSettingsInputText}
                    data-testid="brandSettingsInput"
                    value={this.props.brandSettingsData.amount}
                    onChange={this.props.updateBrandSettings}
                    placeholder="-"
                    validators={[
                      "required",
                      "minNumber:0",
                      "maxNumber:2000000000",
                      "isNumber",
                      "isNumericWithTwoDecimalPlaces",
                      "changeInPrice:" + this.props.brandSettingsData.model,
                    ]}
                    errorMessages={[
                      this.props.t("Error.requiredField"),
                      this.props.t("Error.invalidInput"),
                      this.props.t("Error.invalidInput"),
                      this.props.t("Error.invalidInput"),
                      this.props.t("Error.invalidInput"),
                    ]}
                  />
                </Grid>
                <Grid item xs={5} />
              </>
            )}
            noRender={() => (
              <>
                <Grid item xs={2} className={styles.itemAmount}>
                  <Item
                    value={CheckCurrencyValue(
                      this.currencySymbol,
                      this.props.brandSettingsData.amount,
                      true
                    )}
                  />
                </Grid>
                <Grid item xs={5} />
              </>
            )}
          />
        </Grid>
      </div>
    );
  }
}

export default withTranslation("emp")(BrandSettingsList);
