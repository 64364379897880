import React, { Component } from "react";
import Item from "../../../../shared/item/Item";
import CheckBox from "../../../../shared/checkBox/CheckBox";
import styles from "../pricingList/PricingList.module.scss";
import { TextValidator } from "react-material-ui-form-validator";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import HasPermissionTo from "../../../../common/HasPermissionTo";
import { Table, TableCell, TableHead, Tooltip } from "@material-ui/core";
import CheckCurrencyValue from "../../../../utils/CheckCurrencyValue";
import OfferDetails from "./offerDetails/OfferDetails";
import Info from "@material-ui/icons/Info";

const StyledSpecsTableCell = withStyles({
  root: {
    borderBottom: 0,
    fontWeight: 500,
    padding: "15px 0",
    width: "15%",
    textAlign: "left",
    color: "#102b4e",
    fontSize: "12px",
    fontFamily: "FordAntennaRegular, sans-serif",
    letterSpacing: "1px",
    lineHeight: "1.17",
  },
})(TableCell);
const NonEditTableCell = withStyles({
  root: {
    borderBottom: 0,
    fontWeight: 500,
    padding: "5px 0",
    width: "15%",
    textAlign: "center",
    color: "#4a4a4a",
    fontSize: "12px",
    fontFamily: "FordAntennaSemiBold, sans-serif",
    letterSpacing: "1px",
    lineHeight: "1.17",
  },
})(TableCell);
const WarningTableCell = withStyles({
  root: {
    borderBottom: 0,
    paddingLeft: "10px",
    paddingRight: "10px",
    minWidth: "30px",
  },
})(TableCell);
const CheckBoxTableCell = withStyles({
  root: {
    borderBottom: 0,
    padding: "5px 0",
    width: "8%",
  },
})(TableCell);

const StyledInfo = withStyles({
  root: {
    fill: "#4a90e2",
    width: 13,
    marginLeft: 3,
    position: "relative",
    bottom: 7,
  },
})(Info);

const StyledTooltip = withStyles({
  popper: {
    opacity: 1,
    pointerEvents: "auto",
  },
  tooltip: {
    background: "white",
    boxShadow: "1px 1px 9px 0 rgba(0, 0, 0, 0.27)",
    paddingTop: 28,
    paddingLeft: 28,
    paddingRight: 28,
    paddingBottom: 18,
    maxWidth: 375,
  },
})(Tooltip);

class PricingRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false,
    };
  }

  openTooltip = () => this.setState({ tooltipOpen: true });

  closeTooltip = () => this.setState({ tooltipOpen: false });

  render() {
    const { dataRow } = this.props;
    // eslint-disable-next-line
    const isPricesSame = dataRow.recommendedAdj == dataRow.adj;
    const rrp = dataRow.rrp;
    const customerSaving = (dataRow.vm && dataRow.vm.customerSaving) || 0;
    const governmentGrant = (dataRow.vm && dataRow.vm.governmentGrant) || 0;
    const incentives = dataRow.vm && dataRow.vm.incentives;
    const dealerDiscount = dataRow.adj;
    const dealerDiscountDisplay =
      dealerDiscount.charAt(0) === "+" || dealerDiscount.charAt(0) === "-"
        ? dealerDiscount
            .charAt(0)
            .concat(
              CheckCurrencyValue(
                this.props.currencySymbol,
                dealerDiscount.substr(1)
              )
            )
        : CheckCurrencyValue(this.props.currencySymbol, dealerDiscount);
    const dealerVehiclePrice = dataRow.dealerVehiclePrice;
    const transactionPrice = dataRow.transactionPrice;
    return (
      <div
        className={`${
          dataRow.priceChanged || !dataRow.released
            ? styles.rowBorderHighlightPriceChange
            : styles.rowBorderHighlight
        } pagePaddingRight`}
      >
        <Table>
          <TableHead>
            <WarningTableCell>
              {dataRow.priceChanged ||
                (!dataRow.released && (
                  <div
                    className={styles.warning}
                    data-testid={"warning" + this.props.index}
                  />
                ))}
            </WarningTableCell>
            <CheckBoxTableCell>
              <CheckBox checked={true} isDisabled={this.props.isDisabled} />
            </CheckBoxTableCell>
            <StyledSpecsTableCell>
              <Item value={dataRow.specs} />
            </StyledSpecsTableCell>

            <NonEditTableCell
              className={`${styles.rowItemBold} ${styles.center}`}
            >
              <Item
                data-testid={"rrp" + this.props.index}
                className={styles.itemWidth}
                value={CheckCurrencyValue(this.props.currencySymbol, rrp)}
              />
            </NonEditTableCell>

            <NonEditTableCell className={styles.shadedRowItem}>
              <Item
                value={CheckCurrencyValue(
                  this.props.currencySymbol,
                  customerSaving
                )}
              />
              {customerSaving !== 0 && (
                <StyledTooltip
                  open={this.state.tooltipOpen}
                  placement="bottom-start"
                  title={
                    <OfferDetails
                      currencySymbol={this.props.currencySymbol}
                      governmentGrant={governmentGrant}
                      incentives={incentives}
                      closeTooltip={this.closeTooltip}
                      index={this.props.index}
                    />
                  }
                >
                  <StyledInfo
                    onClick={this.openTooltip}
                    data-testid={`tooltipIcon_${this.props.index}`}
                    fontSize="small"
                  />
                </StyledTooltip>
              )}
            </NonEditTableCell>

            <NonEditTableCell>
              {this.props.isEditEnabled ? (
                <span className={styles.textBox}>
                  <TextValidator
                    value={dealerDiscount}
                    data-testid={
                      "editDiscount" + this.props.mainIndex + this.props.index
                    }
                    validators={[
                      "required",
                      "isSignedFloatWithTwoDecimalPlaces",
                      "SignRequiredForFloatWithTwoDecimalPlaces",
                      "maxNumber:" + `${rrp - customerSaving}`,
                      "minNumber:" + `${-(rrp - customerSaving)}`,
                    ]}
                    errorMessages={[
                      this.props.t("PricingRow.required"),
                      this.props.t("PricingRow.invalidFormat"),
                      this.props.t("PricingRow.signRequired"),
                      this.props.t("PricingRow.errorMaximum"),
                      this.props.t("PricingRow.errorMinimum"),
                    ]}
                    onChange={(e) => this.props.updateDiscountPrice(e)}
                  />
                </span>
              ) : (
                <>
                  <Item
                    className={styles.itemWidth}
                    datatestid={
                      "itemDiscount" + this.props.mainIndex + this.props.index
                    }
                    value={dealerDiscountDisplay}
                  />
                  <HasPermissionTo
                    permissions={this.props.user.permissions.rolePermissions}
                    perform={["vehiclePricing:write"]}
                    render={() =>
                      !this.props.user.readOnly && !isPricesSame ? (
                        <span
                          className={styles.reset}
                          data-testid={
                            "reset" + this.props.mainIndex + this.props.index
                          }
                          onClick={(e) =>
                            this.props.resetVehiclesRow(e, dataRow)
                          }
                        >
                          {this.props.t("PricingRow.reset")}
                        </span>
                      ) : (
                        <span className={styles.reset} />
                      )
                    }
                  />
                </>
              )}
            </NonEditTableCell>

            <NonEditTableCell
              className={!this.props.isEditEnabled && styles.paddingRight}
            >
              <Item
                datatestid={
                  "itemDealerVehiclePrice" +
                  this.props.mainIndex +
                  this.props.index
                }
                value={CheckCurrencyValue(
                  this.props.currencySymbol,
                  dealerVehiclePrice
                )}
              />
            </NonEditTableCell>

            <NonEditTableCell>
              <Item
                className={styles.itemWidth}
                datatestid={
                  "itemDealerTransactionPrice" +
                  this.props.mainIndex +
                  this.props.index
                }
                value={CheckCurrencyValue(
                  this.props.currencySymbol,
                  transactionPrice
                )}
              />
            </NonEditTableCell>
          </TableHead>
        </Table>
      </div>
    );
  }
}

export default withTranslation("emp")(PricingRow);
