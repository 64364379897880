import React from "react";
import styles from "../../pricingWrapper/optionsList/OptionsList.module.scss";
import Grid from "@material-ui/core/Grid";
import CheckBox from "../../../../shared/checkBox/CheckBox";
import Item from "../../../../shared/item/Item";
import { TextValidator } from "react-material-ui-form-validator";
import CheckCurrencyValue from "../../../../utils/CheckCurrencyValue";

const OptionsRow = (props) => {
  function formatNegativeValue(value) {
    let result = "";
    if (value < 0) {
      const signRemovedString = value.toString().replace("-", "");
      result =
        "-" + CheckCurrencyValue(props.currencySymbol, signRemovedString);
    } else {
      const signRemovedString = value.toString().replace("+", "");
      result = CheckCurrencyValue(props.currencySymbol, signRemovedString);
    }
    return result;
  }

  function moveSignToTheLeft(value) {
    let result = "";
    if (value < 0) {
      const signRemovedString = value.toString().replace("-", "");
      result =
        "-" + CheckCurrencyValue(props.currencySymbol, signRemovedString);
    } else {
      result = CheckCurrencyValue(props.currencySymbol, value);
    }
    return result;
  }

  return (
    <div
      data-testid={`optionsRow${props.index}`}
      className={`${
        props.optionsData.priceChanged || !props.released
          ? styles.rowBorderHighlightPriceChange
          : styles.rowBorderHighlight
      } pagePaddingLeft pagePaddingRight`}
    >
      <Grid container spacing={0}>
        <Grid item xs={1} className={styles.rowItem}>
          <CheckBox
            id={"toggleCheckbox" + props.index}
            checked={true}
            isDisabled={props.isDisabled}
          />
        </Grid>
        <Grid item xs={4} className={`${styles.rowItem}`}>
          <Item value={props.optionsData.featureDesc} />
        </Grid>
        <Grid item xs={1} className={`${styles.price} ${styles.center}`}>
          <Item
            datatestid={"rrp" + props.index}
            value={CheckCurrencyValue(
              props.currencySymbol,
              props.optionsData.rrp
            )}
          />
        </Grid>
        <Grid
          item
          xs={2}
          className={`${styles.shadedRowItem} ${styles.centerTogether}`}
        >
          <Item
            datatestid={"dealerDiscount" + props.index}
            value={CheckCurrencyValue(
              props.currencySymbol,
              props.optionsData.vm
            )}
          />
        </Grid>
        <Grid item xs={2} className={`${styles.priceRowItem} ${styles.right}`}>
          {props.isEditEnabled ? (
            <span className={styles.textBox}>
              <TextValidator
                name="adjustmentInput"
                value={props.optionsData.adj}
                data-testid={"adjustmentInput" + props.index}
                validators={["required", "isPosNegNumber"]}
                errorMessages={[
                  props.t("Error.requiredField"),
                  props.t("Error.invalidInput"),
                ]}
                onChange={(e) => props.updateAdjustmentPrice(e, props.index)}
              />
            </span>
          ) : (
            <>
              <div className={`${styles.width85} ${styles.alignRight}`}>
                <Item
                  datatestid={"adjustmentAmount" + props.index}
                  value={formatNegativeValue(props.optionsData.adj)}
                />
              </div>
              <div className={`${styles.width15}`} />
            </>
          )}
        </Grid>
        <Grid
          item
          xs={2}
          className={`${styles.price} ${styles.right} ${
            !props.isEditEnabled && styles.paddingRight
          }`}
        >
          <Item
            datatestid={"recommendedPrice" + props.index}
            value={moveSignToTheLeft(props.optionsData.recommendedPrice)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default OptionsRow;
