import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styles from "../PricingWrapper.module.scss";
import subNavStyles from "../../../../shared/subnav/subNavStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";

class ModelDropDown extends Component {
  render() {
    const seen = {};
    const modelValues =
      this.props.modelData &&
      this.props.modelData.filter((vehicleLine) => {
        if (seen.hasOwnProperty(vehicleLine.model)) {
          return false;
        } else {
          seen[vehicleLine.model] = vehicleLine.model;
          return true;
        }
      });
    return (
      <div id="modelDropdown" className={styles.modelDropdownDiv}>
        <FormControl id="menu" className={styles.modelFormControl}>
          <Select
            name="modelDropdown"
            id="selectModelDropdown"
            style={subNavStyles.disabledDropDown}
            className={styles.dropdown}
            IconComponent={() => (
              <KeyboardArrowDown style={subNavStyles.icon} />
            )}
            value={this.props.selectedModel}
            onChange={(event) => this.props.updateModel(event)}
          >
            {modelValues &&
              modelValues.map((dealer) => {
                return (
                  <MenuItem
                    style={subNavStyles.item}
                    id={dealer.model}
                    data-testid={dealer.model}
                    key={dealer.model}
                    value={dealer.model}
                  >
                    <p className={styles.unonboardedDealer} id={dealer.model}>
                      {dealer.model}
                    </p>
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default withTranslation("emp")(ModelDropDown);
