import React from "react";
import OptionsRow from "../optionsRow/OptionsRow";

const OptionsList = (props) => {
  return (
    <div>
      {props.optionsData.map((item, index) => {
        return (
          <OptionsRow
            {...props}
            key={index}
            index={index}
            optionsData={item}
            released={props.released}
          />
        );
      })}
    </div>
  );
};

export default OptionsList;
