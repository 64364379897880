import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import {
  RESERVATION_BASE_URL,
  RESERVATION_ACTION_URL,
} from "../../utils/EmpUtil";
import { is401, is400 } from "../../errors/ErrorConstants";

export default class CustomerReservationsClient {
  static baseUrl = RESERVATION_BASE_URL;
  static actionUrl = RESERVATION_ACTION_URL;

  static getCustomerReservations(
    token,
    dealerCommonId,
    currentPage,
    lang,
    sortBy
  ) {
    const url = `${this.baseUrl}/dealer/customer-reservations`;
    dealerCommonId = dealerCommonId.split("%7C").join("|");
    return axios
      .post(
        url,
        {
          commonIds: dealerCommonId,
          currentPage: currentPage,
          sort: sortBy,
          lang: lang,
        },
        getHeaders(token)
      )
      .then((response) => {
        // looking for an order with certain attributes? uncomment and modify this code
        // for (let i = 0; i < response.data.pagination.numberOfPages; i++) {
        //   axios
        //     .post(
        //       url,
        //       {
        //         commonIds: dealerCommonId,
        //         currentPage: i,
        //         sort: sortBy,
        //         lang,
        //       },
        //       getHeaders(token)
        //     )
        //     .then((page) => {
        //       for (let j = 0; j < page.data.reservations.length; j++) {
        //         this.getCustomerOrderDetails(
        //           { token },
        //           page.data.reservations[j].id,
        //           dealerCommonId,
        //           false,
        //           lang
        //         ).then((orderDetail) => {
        //           const reservation = orderDetail.customerReservation;
        //
        //           // conditions go here
        //           if (reservation.paymentType === "Lease") {
        //             console.log(`${reservation.dealerId}\n${reservation.id}`);
        //           }
        //         });
        //       }
        //     });
        // }

        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static getCustomerOrders(token, dealerCommonId, tab, lang) {
    let statusList = [];
    let orderType = null;
    let sort = null;
    let purchaseRequestOrders = false;

    if (tab === "orders_requiring_attention") {
      statusList = ["SUBMITTED"];
      orderType = "Order";
      sort = "byDealerActionReqDateAsc";
    } else if (tab === "reservations_submitted") {
      orderType = "Reservation";
      statusList = ["CREATED", "CANCELLED", "AMENDED"];
    } else if (tab === "orders_submitted") {
      orderType = "Order";
      statusList = [
        "REJECTED",
        "CANCELLED",
        "CONFIRMED",
        "CONTRACTED",
        "CONVERTED",
        "ORDERED",
        "SOLD",
      ];
      sort = "byOrderConfirmedDateDesc";
    } else if (tab === "purchase_request") {
      orderType = "Order";
      purchaseRequestOrders = true;
    }
    dealerCommonId = dealerCommonId.split("%7C").join("|");
    statusList = statusList.join(",");
    const url = `${this.baseUrl}/dealer/customer-reservations`;
    return axios
      .post(
        url,
        {
          commonIds: dealerCommonId,
          status: statusList,
          currentPage: 0,
          orderType: orderType,
          sort: sort,
          lang: lang,
          purchaseRequestOrders: purchaseRequestOrders,
        },
        getHeaders(token)
      )
      .then((response) => {
        // looking for an order with certain attributes? uncomment and modify this code
        // for (let i = 0; i < response.data.pagination.numberOfPages; i++) {
        //   axios
        //     .post(
        //       url,
        //       {
        //         commonIds: dealerCommonId,
        //         currentPage: i,
        //         sort,
        //         lang,
        //       },
        //       getHeaders(token)
        //     )
        //     .then((page) => {
        //       for (let j = 0; j < page.data.reservations.length; j++) {
        //         this.getCustomerOrderDetails(
        //           { token },
        //           page.data.reservations[j].id,
        //           dealerCommonId,
        //           false,
        //           lang
        //         ).then((orderDetail) => {
        //           const reservation = orderDetail.customerReservation;
        //
        //           // conditions go here
        //           if (reservation.purchaseDeposit) {
        //             console.log(`${reservation.dealerId}\n${reservation.id}`, reservation.purchaseDeposit.acceptRejectStatus);
        //           }
        //         });
        //       }
        //     });
        // }
        return response.data.reservations;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }

  static updateOrder(
    token,
    dealerCommonId,
    dealerActionInfo,
    dealerUserId,
    returnError
  ) {
    dealerCommonId = dealerCommonId.split("|").join("%7C");
    const url = `${this.actionUrl}/dealer/${dealerCommonId}/customer-orders?dealerUserId=${dealerUserId}`;

    return axios
      .post(url, dealerActionInfo, getHeaders(token))
      .then(() => {
        return Promise.resolve();
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        if (returnError && is400(error)) {
          // will be handling this 400 error in the calling method
          return "400";
        }
        return Promise.reject(error);
      });
  }

  static updatePaymentMethod(token, paymentUpdate) {
    const url = `${this.baseUrl}/dealer/customer-reservations/payment-update`;

    return axios
      .post(url, paymentUpdate, getHeaders(token))
      .then(() => {
        return Promise.resolve();
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static updateHandOver(token, handOverData, orderId) {
    const url = `${this.baseUrl}/dealer/customer-reservations/handover-update/${orderId}`;

    return axios
      .post(url, handOverData, getHeaders(token))
      .then(() => {
        return Promise.resolve();
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static getCancelOrderReasons(
    token,
    lang,
    purchaseRequest = false,
    consentReasons = false,
    reservationReasons = false,
    vehicleHoldReasons = false
  ) {
    let url = `${this.baseUrl}/dealer/cancelOrderReasons?lang=${lang}`;

    if (purchaseRequest) {
      url = url + `&purchaseRequest=${purchaseRequest}`;
    }

    if (consentReasons) {
      url = url + `&consentReasons=true`;
    }

    if (reservationReasons) {
      url = url + `&reservationReasons=true`;
    }
    if (vehicleHoldReasons) {
      url = url + `&vehicleHoldReasons=true`;
    }

    return axios
      .get(url, getHeaders(token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static getInventoryWindowSticker(token, dealerPACode, vin) {
    if (dealerPACode && vin) {
      const url = `${this.baseUrl}/dealer/window-sticker?dealerPACode=${dealerPACode}&vin=${vin}`;
      return axios
        .get(url, getHeaders(token))
        .then((response) => response.data)
        .catch((error) => {
          if (is401(error)) {
            AuthenticationFailureHandler.execute();
          }
          return Promise.reject(error);
        });
    }
  }

  static async getCustomerOrderDetails(
    user,
    orderId,
    commonId,
    isCrc,
    lang,
    market
  ) {
    let url = `${this.baseUrl}/dealer/customer-reservations/${orderId}?lang=${lang}`;
    url = url + (isCrc ? "&market=" + market : "&dealerId=" + commonId);
    return await axios
      .get(encodeURI(url), getHeaders(user.token))
      .then((response) => response.data)
      .catch(async (error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static async getCustomerFinanceDetails(user, proposalNum) {
    const url = `${this.baseUrl}/financialDetails/${proposalNum}`;
    return await axios
      .get(encodeURI(url), getHeaders(user.token))
      .then((response) => response.data)
      .catch(async (error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static async searchCustomerReservations(
    searchQuery,
    user,
    lang,
    commonIds,
    market
  ) {
    let url = `${this.baseUrl}/dealer/customer-reservations/search?query=${searchQuery}&lang=${lang}`;
    if (commonIds) {
      url = url + `&commonIds=${commonIds.split("|").join("%7C")}`;
    }

    if (market) {
      url = url + `&market=${market}`;
    }

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static async saveValuation(valuationRequest) {
    const url = `${this.baseUrl}/dealer/customer-reservations/trade-in`;

    return axios
      .put(
        url,
        {
          expiryDate: valuationRequest.expiryDate,
          valuationAmount: valuationRequest.valuationAmount,
          appraisalId: valuationRequest.appraisalId,
          orderId: valuationRequest.orderId,
        },
        getHeaders(valuationRequest.user.token)
      )
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static async sendToUVIS(uvisRequest) {
    const url = `${this.baseUrl}/dealer/customer-reservations/uvis`;

    return axios
      .post(url, uvisRequest, getHeaders(uvisRequest.token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static async uploadFinancialDocument(proposalNumber, financeDocumentRequest) {
    const url = `${this.baseUrl}/dealer/customer-reservations/registration-update?proposalNumber=${proposalNumber}`;
    return axios
      .post(
        url,
        financeDocumentRequest,
        getHeaders(financeDocumentRequest.token)
      )
      .then(() => Promise.resolve())
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static async uploadLeaseDocument(documentType, leaseDocumentRequest) {
    const url = `${this.baseUrl}/dealer/customer-reservations/upload-document?documentType=${documentType}`;
    return axios
      .post(url, leaseDocumentRequest, getHeaders(leaseDocumentRequest.token))
      .then(() => Promise.resolve())
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static async updateRegistrationNumber(
    user,
    proposalNumber,
    acceptedDateSap,
    registrationDataRequest,
    orderId,
    paymentType,
    dealerId
  ) {
    const url = `${this.baseUrl}/dealer/customer-reservations/registration-update?proposalNumber=${proposalNumber}`;
    return axios
      .put(
        url,
        {
          commonIds: user.dealerId,
          dealerId: dealerId,
          userId: user.userId,
          acceptedDate: acceptedDateSap,
          registrationNumberDTO: registrationDataRequest,
          orderId: orderId,
          dealerAction: "registrationdetailssubmit",
          paymentType: paymentType,
        },
        getHeaders(user.token)
      )
      .then(() => Promise.resolve())
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
  static fetchHandOverDeliveryNotes(token, uid, vin) {
    const url = `${this.baseUrl}/dealer/customer-reservations/deliveryNotes?vin=${vin}&uid=${uid}`;

    return axios
      .get(url, getHeaders(token))
      .then((result) => {
        return result;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}
