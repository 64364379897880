import React from "react";
import styles from "./AppliedPricing.module.scss";
import { withTranslation } from "react-i18next";

const ApplyPricing = (props) => {
  return (
    <div className={styles.appliedPricing} data-testid="pricesApplied">
      <span>
        {props.t("PricingWrapper.pricesApplied")}
        <span className={styles.tick}>✓</span>{" "}
      </span>
    </div>
  );
};

export default withTranslation("emp")(ApplyPricing);
