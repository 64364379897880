import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders, getHeadersForCsv } from "../../utils/AuthorizationUtil";
import {
  BASE_PRICING_URL,
  BASE_REPORTING_URL,
  BASE_URL,
  INVENTORY_SERVICE_URL,
} from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

export default class NscReportingClient {
  static baseUrl = BASE_URL;
  static reportingUrl = BASE_REPORTING_URL;
  static pricingUrl = BASE_PRICING_URL;
  static inventoryUrl = INVENTORY_SERVICE_URL;

  static downloadDealerActivationReportInCsv(user) {
    const url = `${
      this.baseUrl
    }/${user.generateResourcePath()}/activation-report`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static downloadDealerReservationReport(user, selectedMarket) {
    const requestBody = {
      userType: user.userType,
      marketId: selectedMarket,
      filterData: false,
    };

    const url = `${this.reportingUrl}/reporting/${selectedMarket}/img-reservations-report`;

    return axios
      .post(url, requestBody, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }

  static downloadAppActionsLogReport(user) {
    const url = `${
      this.baseUrl
    }/${user.generateResourcePath()}/app-actions-log-report`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static downloadPricingReport(user) {
    const url = `${
      this.pricingUrl
    }/${user.generateResourcePath()}/pricing-report`;
    const universalBOM = "\uFEFF";
    return axios
      .get(url, getHeadersForCsv(user.token))
      .then((response) => encodeURIComponent(`${universalBOM}${response.data}`))
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static downloadInventoryPricingReport(user, data) {
    const url = `${this.inventoryUrl}/nsc/all-dealer-inventory-pricing-report`;

    return axios
      .post(url, data, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}
