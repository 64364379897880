import React, { Component } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import "./App.scss";
import "../styles/sharedStyles.scss";
import Nsc from "./pure/Nsc";
import GlobalNavBar from "../shared/globalNavBar/GlobalNavBar";
import Footer from "../shared/Footer/Footer";
import QuickStart from "../components/quickStart/QuickStart";
import ProgressBarTabs from "../components/progressBar/ProgressBarTabs";
import InvalidRoute from "../components/accessDenied/InvalidRoute";
import Reporting from "../components/reporting/Reporting";
import BusinessSettings from "../components/businessSetting/BusinessSettings";
import { logMsg } from "../common/Logger";
import withIdleTimer from "../common/WithIdleTimer";
import PricingWrapper from "../components/pricing/pricingWrapper/PricingWrapper";
import AccessDeniedNotOnboardedNsc from "../components/accessDenied/AccessDeniedNotOnboardedNsc";
import HasPermissionTo from "../common/HasPermissionTo";
import NscDashboard from "../components/dashboard/nscDashBoard/NscDashboard";
import DepositPricing from "../components/pricing/pricingWrapper/depositPricing/DepositPricing";
import NscCurrentPricing from "../components/pricing/pricingWrapper/currentPricing/NscCurrentPricing";
import NscOnboardingClient from "../shared/clients/NscOnboardingClient";
import LocalizedInfoClient from "../shared/clients/LocalizedInfoClient";
import ErrorScreen from "../errors/ErrorScreen";
import AccessoriesPricingWrapper from "../components/pricing/pricingWrapper/accessoriesPricing/AccessoriesPricingWrapper";
import { IS_SA_MARKET } from "../utils/EmpUtil";
import OrdersSearch from "../components/search/searchWrapper/orders/OrdersSearch";
import SearchWrapper from "../components/search/searchWrapper/SearchWrapper";

class NSCApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renderApp: false,
      shouldNavigationBeRestricted: false,
      padding: "70px",
    };
    this.user = new Nsc(
      this.props.token,
      this.props.userDetails,
      this.props.history
    );
    this.localizedInfo = {};

    // Listening for route change
    this.props.history.listen(() => this.setNavigationRestriction());
    this.navigateToRoute = this.navigateToRoute.bind(this);
    this.onReservationSave = this.onReservationSave.bind(this);
  }

  componentDidMount() {
    this.getLocalizedInfo();
  }

  onReservationSave() {
    this.onBoardingStatus = "ACCEPTED";
    this.navigateToRoute("dashboard");
  }

  navigateToRoute(link) {
    this.props.history.push(`/${link}`);
  }

  setNavigationRestriction() {
    if (this.onBoardingStatus !== "ACCEPTED") {
      this.setState({ shouldNavigationBeRestricted: true });
    } else {
      const isAllowedPath = this.isCurrentPathAllowedToShowHeaderAndFooter(
        this.props.history.location.pathname
      );
      this.setState({ shouldNavigationBeRestricted: !isAllowedPath });
    }
  }

  setPadding = (padding) => {
    if (padding > 70) {
      this.setState({ padding: padding + "px" });
    }
  };

  isCurrentPathAllowedToShowHeaderAndFooter(currentPathName) {
    const staticAllowedPaths = [
      "/dashboard",
      "/pricing",
      "/pricing/vehicles",
      "/pricing/accessories",
      "/pricing/deposit",
      "/business-settings",
      "/search/orders",
      "/search",
      "/reporting",
      "/",
    ];

    return staticAllowedPaths.includes(currentPathName);
  }

  checkIsUserOnboarded() {
    this.isOnboarded()
      .then((val) => {
        this.onBoardingStatus = val.onboarded ? "ACCEPTED" : "PENDING";
        this.setNavigationRestriction();
      })
      .finally(() => {
        this.user.hasNscCompletedBasicPAs = true;
        this.setState({ renderApp: true });
      });
  }

  async isOnboarded() {
    return await NscOnboardingClient.isOnboarded(this.user);
  }

  getLocalizedInfo() {
    this.props.hideOrShow(true);
    LocalizedInfoClient.getLocalizedInfo(this.user)
      .then((response) => {
        this.localizedInfo = Object.assign(
          {},
          { ...this.localizedInfo },
          { ...response }
        );
      })
      .catch((error) => logMsg("the error is: ", error))
      .finally(() => {
        //*** NSC screens are currently only in English
        this.props.i18n.changeLanguage("en" + "_" + "AUS");
        this.checkIsUserOnboarded();
        this.props.hideOrShow(false);
      });
  }

  getProgressBarTabs() {
    return IS_SA_MARKET(this.user.market)
      ? ProgressBarTabs().onboardingSANsc
      : ProgressBarTabs().onboardingNsc;
  }

  render() {
    const ProtectedRoute = ({
      component: Component,
      perform: perform,
      ...rest
    }) => (
      <HasPermissionTo
        perform={perform}
        permissions={this.user.permissions.rolePermissions}
        render={() => (
          <Route
            {...rest}
            render={() =>
              this.onBoardingStatus === "ACCEPTED" ? (
                <Component {...rest.customprops} {...this.props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
        )}
      />
    );
    return (
      this.state.renderApp && (
        <Router history={this.props.history}>
          <div className="appWrapper" id="appWrapper">
            {!this.state.shouldNavigationBeRestricted && (
              <div style={{ paddingTop: this.state.padding }}>
                <GlobalNavBar
                  {...this.props}
                  user={this.user}
                  urlPath={window.location.pathname}
                  navigateToRoute={this.navigateToRoute}
                  setPadding={this.setPadding}
                />
              </div>
            )}

            <Switch>
              <ProtectedRoute
                exact
                path="/pricing"
                component={withIdleTimer(PricingWrapper)}
                perform={["pricingPage"]}
                customprops={{
                  ...this.props,
                  user: this.user,
                  localizedInfo: this.localizedInfo,
                }}
              />
              <ProtectedRoute
                exact
                path="/dashboard"
                component={withIdleTimer(NscDashboard)}
                perform={["dashBoardPage"]}
                customprops={{
                  user: this.user,
                }}
              />

              <ProtectedRoute
                exact
                path="/reporting"
                component={withIdleTimer(Reporting)}
                perform={["reportingPage"]}
                customprops={{
                  user: this.user,
                }}
              />
              <ProtectedRoute
                exact
                path="/business-settings"
                component={withIdleTimer(BusinessSettings)}
                perform={["businessSettingsPage"]}
                customprops={{
                  user: this.user,
                  localizedInfo: this.localizedInfo,
                }}
              />
              <ProtectedRoute
                exact
                path="/search"
                component={withIdleTimer(SearchWrapper)}
                perform={["searchLandingPage"]}
                customprops={{
                  user: this.user,
                }}
              />
              <Route
                exact
                path="/search/orders"
                component={withIdleTimer(() => (
                  <OrdersSearch
                    {...this.props}
                    localizedInfo={this.localizedInfo}
                    user={this.user}
                  />
                ))}
              />
              <ProtectedRoute
                exact
                path="/pricing/vehicles"
                component={withIdleTimer(() => (
                  <NscCurrentPricing
                    {...this.props}
                    localizedInfo={this.localizedInfo}
                    user={this.user}
                  />
                ))}
                perform={["vehiclePricingTab"]}
              />
              <ProtectedRoute
                exact
                path="/pricing/accessories"
                perform={["accessoriesPricingTab"]}
                component={withIdleTimer(() => (
                  <AccessoriesPricingWrapper
                    {...this.props}
                    localizedInfo={this.localizedInfo}
                    user={this.user}
                  />
                ))}
              />
              <ProtectedRoute
                exact
                path="/pricing/deposit"
                component={withIdleTimer(() => (
                  <DepositPricing
                    {...this.props}
                    localizedInfo={this.localizedInfo}
                    user={this.user}
                  />
                ))}
                perform={["depositPricingTab"]}
              />

              <Route
                exact
                path="/denied-nsc"
                component={AccessDeniedNotOnboardedNsc}
              />
              <Route
                exact
                path="/"
                render={() =>
                  this.onBoardingStatus === "ACCEPTED" ? (
                    <Redirect
                      to={{
                        pathname: "/dashboard",
                      }}
                    />
                  ) : (
                    <HasPermissionTo
                      perform={["quickStartPage"]}
                      permissions={this.user.permissions.rolePermissions}
                      render={() => (
                        <QuickStart
                          user={this.user}
                          {...this.props}
                          onboardingClient={NscOnboardingClient}
                          localizedInfo={this.localizedInfo}
                          onSave={this.onReservationSave}
                          progressBarTabs={this.getProgressBarTabs()}
                          fromQuickstart={true}
                        />
                      )}
                      noRender={() => (
                        <Redirect
                          to={{
                            pathname: "/denied-nsc",
                          }}
                        />
                      )}
                    />
                  )
                }
              />
              <Route
                exact
                path="/error"
                render={(props) => <ErrorScreen {...props} />}
              />
              <Route component={withIdleTimer(InvalidRoute)} />
            </Switch>
            {!this.state.shouldNavigationBeRestricted && (
              <Footer permissions={this.props.userDetails.permissions} />
            )}
          </div>
        </Router>
      )
    );
  }
}

export default NSCApp;
