import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import BackToButton from "../../../../shared/backToButton/BackToButton";
import styles from "./AccessoriesPricingWrapper.module.scss";
import SubNav from "../../../../shared/subnav/SubNav";
import BrandSettingsEU from "./brandSettings/BrandSettingsEU";
import AccessoriesSettingsEU from "./accessoriesSettings/AccessoriesSettingsEU";
import UpperCaseText from "../../../../utils/UpperCaseText";

class AccessoriesPricingWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      enableTabs: false,
      showWarning: false,
    };
  }

  handleTabChange = (event, currentTab) => {
    if (this.state.enableTabs) {
      this.setState({ currentTab });
    } else {
      this.setState({ showWarning: currentTab === 1 });
    }
  };

  constructTabs() {
    return [
      {
        menuName: this.props.t("AccessoriesPricing.brandSettingsTab"),
        enabled: true,
        id: "brandSettings",
      },
      {
        menuName: this.props.t("AccessoriesPricing.accessoriesTab"),
        enabled: true,
        disabledStyle: !this.state.enableTabs,
        id: "accessories",
      },
    ];
  }

  enableOtherTabs = (value) => {
    this.setState({ enableTabs: value, showWarning: false });
  };

  closeWarning = () => {
    this.setState({ showWarning: false });
  };

  getSubNav = () => {
    return (
      <div className={styles.left}>
        <SubNav
          {...this.props}
          title={this.props.t("AccessoriesPricing.header")}
          selectedTab={this.state.currentTab}
          action={this.handleTabChange}
          tabs={this.constructTabs()}
        />
      </div>
    );
  };

  render() {
    return (
      <div className="pageWrapper">
        <BackToButton
          backToUrl="/pricing"
          display={this.props.t("PricingWrapper.backToPricing")}
        />
        {this.getSubNav()}
        {this.state.showWarning && (
          <div className={styles.dialogTitle}>
            <span className={styles.warning} />
            <div>
              {UpperCaseText(this.props.user.userType) === "NSC"
                ? this.props.t("AccessoriesPricing.warningMessageNsc")
                : this.props.t("AccessoriesPricing.warningMessage")}
            </div>
            <div
              className={styles.delete}
              onClick={() => {
                this.closeWarning();
              }}
            />
          </div>
        )}
        {this.state.currentTab === 0 ? (
          <BrandSettingsEU
            {...this.props}
            handleTabChange={this.handleTabChange}
            enableOtherTabs={this.enableOtherTabs}
          />
        ) : (
          this.state.currentTab === 1 && (
            <AccessoriesSettingsEU
              {...this.props}
              handleTabChange={this.handleTabChange}
              enableOtherTabs={this.enableOtherTabs}
            />
          )
        )}
      </div>
    );
  }
}

export default withTranslation("emp")(AccessoriesPricingWrapper);
