import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styles from "../PricingWrapper.module.scss";
import subNavStyles from "../../../../shared/subnav/subNavStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";

class YearDropDown extends Component {
  render() {
    const yearValues = this.props.yearValues;
    return (
      <div id="yearDropdown" className={styles.modelDropdownDiv}>
        <FormControl id="menu" className={styles.modelFormControl}>
          <Select
            name="yearDropdown"
            id="yearSelect"
            data-testid="yearSelect"
            style={subNavStyles.disabledDropDown}
            className={styles.dropdown}
            IconComponent={() => (
              <KeyboardArrowDown style={subNavStyles.icon} />
            )}
            value={this.props.selectedYear}
            onChange={(event) => this.props.updateYear(event)}
          >
            {this.props.showAll && (
              <MenuItem
                style={subNavStyles.item}
                id="All"
                value="All"
                key="All"
              >
                <p className={styles.dealerName}>
                  {this.props.t("SubNav.all")}
                </p>
              </MenuItem>
            )}

            {yearValues &&
              yearValues.map((year) => {
                return (
                  <MenuItem
                    style={subNavStyles.item}
                    id={year}
                    value={year}
                    key={year}
                    data-testid={year}
                  >
                    <p className={styles.dropDownText}>{year}</p>
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default withTranslation("emp")(YearDropDown);
