import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { BASE_URL, FORMAT_COMMON_ID } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

export default class VehicleCatalogClient {
  static baseUrl = BASE_URL;

  static getDealerVehicleLines(user, accessories, pricing = false) {
    const url = `${
      this.baseUrl
    }/${user.generateResourcePath()}/vehicleLines?accessories=${accessories}&pricing=${pricing}`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static getNscVehicleLines(user, accessories) {
    const url = `${this.baseUrl}/nsc/vehicleLines?accessories=${accessories}`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static getStatusOverviewTable(user) {
    const url = `${
      this.baseUrl
    }/dealer/pricing/status-overview?dealerCommonId=${FORMAT_COMMON_ID(
      user.commonId
    )}`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}
