import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { ACCESSORIES_PRICING_URL } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

class BrandSettingsClient {
  static baseUrl = ACCESSORIES_PRICING_URL;

  static getDealerBrandAdjustments(user) {
    const url = `${this.baseUrl}/${user.generateResourcePath()}/adjustments`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data.result.dealerBrandAdjustments;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
  static getNscBrandAdjustments(user) {
    const url = `${this.baseUrl}/nsc/adjustments?brandCode=${user.brand}`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static saveDealerBrandAdjustments(user, data, dealerIds) {
    const url = `${this.baseUrl}/dealer/adjustments/save?dealerIds=${dealerIds}`;
    return this.saveBrandAdjustments(user, data, url);
  }

  static saveNscBrandAdjustments(user, data) {
    const url = `${this.baseUrl}/nsc/adjustments/save?brandCode=${user.brand}`;
    return this.saveBrandAdjustments(user, data, url);
  }

  static saveBrandAdjustments(user, data, url) {
    return axios
      .post(url, data, getHeaders(user.token))
      .then(() => {
        return Promise.resolve(true);
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}

export default BrandSettingsClient;
