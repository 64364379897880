/**
 * @return {string}
 */
import UpperCaseText from "./UpperCaseText";
import LowerCaseText from "./LowerCaseText";

export default function FormattedLang(text) {
  if (text && text.length === 2) {
    return LowerCaseText(text).concat("_").concat(UpperCaseText(text));
  } else return text;
}
