import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { ACCESSORIES_PRICING_URL } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

export default class AccessoriesEUClient {
  static accessoriesUrl = ACCESSORIES_PRICING_URL;

  static getDealerAccessoriesData(user, lang, catalogIds) {
    const commonId = user.commonId.split("|").join("%7C");
    let url = `${this.accessoriesUrl}/dealer/accessories?dealerCommonId=${commonId}&catalogId=${catalogIds}`;
    if (lang) {
      url = url + `&lang=${lang}`;
    }
    return this.getAccessoriesData(user, url);
  }

  static getNscAccessoriesData(user, catalogIds) {
    const url = `${this.accessoriesUrl}/nsc/accessories?brandCode=${user.brand}&catalogId=${catalogIds}`;
    return this.getAccessoriesData(user, url);
  }

  static getAccessoriesData(user, url) {
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data && response.data.result;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static saveNSCAccessories(user, data) {
    const url = `${this.accessoriesUrl}/nsc/accessories/save?brandCode=${user.brand}`;
    return this.saveAccessories(user, data, url);
  }

  static saveDealerAccessories(user, commonIds, data) {
    const url = `${this.accessoriesUrl}/dealer/accessories/save?dealerCommonIds=${commonIds}`;
    return this.saveAccessories(user, data, url);
  }

  static saveAccessories(user, data, url) {
    return axios
      .post(url, data, getHeaders(user.token))
      .then((response) => response.status)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}
