import React from "react";
import Grid from "@material-ui/core/Grid";
import styles from "./OfferDetails.module.scss";
import Clear from "@material-ui/icons/Clear";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

const StyledClear = withStyles({
  root: {
    color: "#102b4e",
  },
})(Clear);

const OfferDetails = (props) => {
  return (
    <div data-testid={`vmTooltip_${props.index}`}>
      <button onClick={props.closeTooltip} className={styles.exitButton}>
        <StyledClear />
      </button>
      <div className={styles.vmTitle}>{props.t("PricingRow.vmOffer")}</div>
      {props.governmentGrant && (
        <Grid container key={2} spacing={4} className={styles.toolTipTable}>
          <Grid item xs={6} className={styles.rowItem}>
            {props.t("PricingRow.governmentGrant")}
          </Grid>
          <Grid item xs={2} className={styles.rowItem}>
            {props.currencySymbol.concat(props.governmentGrant)}
          </Grid>
          <Grid item xs={4} className={styles.rowItem}>
            {props.t("PricingRow.refNo")} {" -- "}
          </Grid>
          <Grid item xs={12} className={styles.rowItem}>
            {props.t("PricingRow.effectiveFrom")} {" -- "}
            {props.t("PricingRow.to")} {" -- "}
          </Grid>
        </Grid>
      )}
      {props.incentives &&
        props.incentives.map((item, index) => {
          return (
            <Grid
              container
              key={index}
              spacing={4}
              className={styles.toolTipTable}
            >
              <Grid item xs={6} className={styles.rowItem}>
                {props.t("PricingRow.incentives")}
              </Grid>
              <Grid item xs={2} className={styles.rowItem}>
                {" -- "}
              </Grid>
              <Grid item xs={4} className={styles.rowItem}>
                {props.t("PricingRow.refNo")} {item.id}
              </Grid>
              <Grid item xs={12} className={styles.rowItem}>
                {props.t("PricingRow.effectiveFrom")} {item.startDate}{" "}
                {props.t("PricingRow.to")} {item.endDate}
              </Grid>
            </Grid>
          );
        })}
    </div>
  );
};

export default withTranslation("emp")(OfferDetails);
