import React, { Component } from "react";
import styles from "../PricingWrapper.module.scss";
import AppliedPricing from "../../../../shared/appliedPricing/AppliedPricing";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import vehiclesStyles from "../nscVehicles/NscVehicles.module.scss";
import PricingDetails from "../pricingDetails/PricingDetails";
import HasPermissionTo from "../../../../common/HasPermissionTo";

class DealerVehicles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditEnabled: new Map(),
      inEditMode: false,
    };
    this.checkedCheckBoxCount = 0;

    this.updateEditFlag = this.updateEditFlag.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.handlePricingData = this.handlePricingData.bind(true);
  }

  handlePricingData(data) {
    this.props.updatePricingData(data);
  }

  toggleEditMode() {
    this.setState({ inEditMode: !this.state.inEditMode });
  }

  updateEditFlag(index, value) {
    const isEditEnabled = new Map(this.state.isEditEnabled);
    if (value) {
      isEditEnabled.set(index, value);
    } else {
      isEditEnabled.delete(index);
    }
    this.setState({ isEditEnabled: isEditEnabled });
  }

  navigateToOptions(e) {
    this.props.handleTabChange(e, 2);
  }

  render() {
    return this.props.pricingData && this.props.pricingData.length > 0 ? (
      <>
        {this.props.pricingData.map((item, index) => {
          return (
            <PricingDetails
              {...this.props}
              index={index}
              key={index}
              isDealer={true}
              item={item}
              handlePricingData={this.handlePricingData}
              updateEditFlag={this.updateEditFlag}
              isEditEnabled={this.state.isEditEnabled.get(index)}
              toggleEditMode={this.toggleEditMode}
              released={this.props.released}
            />
          );
        })}
        <div className={styles.actionSection}>
          {this.props.released &&
          !this.props.priceUpdatesAvailable &&
          !this.props.buttonEnabled ? (
            <div className={styles.appliedPricing}>
              <AppliedPricing />
            </div>
          ) : (
            <HasPermissionTo
              permissions={this.props.user.permissions.rolePermissions}
              perform={["vehiclePricing:view"]}
              render={() => (
                <Button
                  data-testid="applyPricesBtn"
                  variant="contained"
                  disabled={
                    this.state.isEditEnabled.size > 0 ||
                    this.props.user.readOnly
                  }
                  onClick={(event) => {
                    this.navigateToOptions(event, 1);
                  }}
                  className={vehiclesStyles.applyBtn}
                  component="button"
                >
                  <span>{this.props.t("DealerVehicles.next")}</span>
                </Button>
              )}
            />
          )}
        </div>
      </>
    ) : (
      <div className={styles.notFound}>
        {this.props.t("DealerVehicles.dataNotFound")}
      </div>
    );
  }
}

export default withTranslation("emp")(DealerVehicles);
