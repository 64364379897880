import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { displayError } from "../../../../errors/ErrorConstants";
import UploadDealerPricing from "./UploadDealerPricing";
import UploadDealerPriceClient from "../../../../shared/clients/UploadDealerPriceClient";
import ConfirmDialog from "../../../../shared/confirmDialog/ConfirmDialog";

class DealerPricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: [],
      dealerInventoryPrices: [],
      hideUsdPrice: false,
      openDeleteDialog: false,
      delete_vin: "",
    };
    //
    this.dialogProps = {
      confirm: props.t("UploadDealerPrice.confirm"),
      cancel: props.t("UploadDealerPrice.cancel"),
      bodyText: props.t("UploadDealerPrice.bodyTextConfirmDelete"),
    };
    this.pricingRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.user && this.props.user.market !== "ARG") {
      this.setState({ hideUsdPrice: true });
    }
    this.loadDealerInventoryPricingHistory();
  }

  handleSubmit = () => {
    this.pricingRef.current.submit();
  };
  handleClose = () => {
    //Step-3 // Close the confirmation dialog
    this.setState({ openDeleteDialog: false, delete_vin: "" });
  };
  handleDeleteConfirmation = () => {
    //Step-3 // delete the record from DB
    const vin = this.state.delete_vin;
    //show loading icon
    this.props.hideOrShow(true);
    UploadDealerPriceClient.deleteInventoryPrices(vin, this.props.user)
      .then((data) => {
        //hide loading icon
        this.props.hideOrShow(false);
        if (data.message.code === "200") {
          //Step-4 // hide the confirmation dialog
          this.setState({ openDeleteDialog: false });
          //Step-5 // reload the data
          this.loadDealerInventoryPricingHistory();
        }
      })
      .catch(() => {
        //hide loading icon
        this.props.hideOrShow(false);
      });
  };
  uncheckEverything = () => {
    const prices = this.state.dealerInventoryPrices;
    prices.forEach((price) => {
      price.checked = false;
    });
    this.setState({ dealerInventoryPrices: prices });
  };
  showInventoryConfirmation = (v) => {
    //Step-2 // Open the confirmation dialog
    this.setState({ openDeleteDialog: true, delete_vin: v });
  };
  loadDealerInventoryPricingHistory() {
    this.props.hideOrShow(true);
    UploadDealerPriceClient.retrieveDealerInventoryPrices(this.props.user)
      .then((data) => {
        this.setState({
          dealerInventoryPrices:
            data &&
            data.dealerPricesDataList &&
            data.dealerPricesDataList[0].dealerInvPriceData,
        });
      })
      .catch(() => {})
      .finally(() => {
        this.props.hideOrShow(false);
      });
  }
  render() {
    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);

    return (
      <div>
        <UploadDealerPricing
          {...this.props}
          enableUpload={true}
          loadDealerInventoryPricingHistory={() =>
            this.loadDealerInventoryPricingHistory()
          }
          dealerInventoryPrices={this.state.dealerInventoryPrices}
          hideUsdPrice={this.state.hideUsdPrice}
          uncheckEverything={this.uncheckEverything}
          showInventoryConfirmation={this.showInventoryConfirmation}
        />
        <ConfirmDialog
          open={this.state.openDeleteDialog}
          dialogProps={this.dialogProps}
          onConfirm={this.handleDeleteConfirmation}
          onCancel={this.handleClose}
          onClose={this.handleClose}
          boldTitle={false}
          {...this.props}
        />
      </div>
    );
  }
}

export default withTranslation("emp")(DealerPricing);
