import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { BASE_URL, INVENTORY_SERVICE_URL } from "../../utils/EmpUtil";
import { is401, is404 } from "../../errors/ErrorConstants";

class UploadDealerPriceClient {
  static baseUrl = BASE_URL;
  static invetoryPricingUrl = INVENTORY_SERVICE_URL;

  static getInventoryPricingData(user, path) {
    let url;
    if (user.userType === "dealer") {
      url = `${this.invetoryPricingUrl}/dealer/${user.dealerId}/${path}`;
    }
    return axios.get(url, getHeaders(user.token));
  }

  static downloadInvPriceTemplate(user, colunmHeaders) {
    const url = `${this.baseUrl}/dealer/get-inventory-prices-tempalte?columnHeaders=${colunmHeaders}`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static retrieveDealerInventoryPrices(user) {
    return this.getInventoryPricingData(user, "inventory-prices-data")
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error) || is404(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static uploadDealerPrice(
    user,
    file,
    effectiveDate,
    processtype,
    conversionRate
  ) {
    const url = `${this.invetoryPricingUrl}/dealer/save-inventory-prices?processtype=${processtype}&dealerId=${user.dealerId}&conversionRate=${conversionRate}`;
    const formData = new FormData();
    formData.append("pricingdocument", file);
    formData.append("dealerCode", user.commonId);
    return axios
      .post(url, formData, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static updateDealerInventoryPrices(user, modifiedPrices, conversionRate) {
    const url = `${this.invetoryPricingUrl}/dealer/update-inventory-prices?dealerId=${user.dealerId}&conversionRate=${conversionRate}`;
    const requestbody = {
      dealerCode: user.commonId,
      dealerId: user.dealerId,
      prices: modifiedPrices,
    };
    return axios
      .put(url, requestbody, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static deleteInventoryPrices(vin, user) {
    const url = `${this.invetoryPricingUrl}/dealer/delete-inventory-price?vin=${vin}&dealerId=${user.dealerId}`;
    return axios
      .delete(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}

export default UploadDealerPriceClient;
