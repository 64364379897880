import Dialog from "@material-ui/core/Dialog";
import Clear from "@material-ui/icons/Clear";
import { withTranslation } from "react-i18next";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./ShowInventoryData.module.scss";
import LoadDealerPricing from "./LoadDealerPricing";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import commonFordStyles from "../../../../styles/common/CommonStyles.module.scss";
import commonLincolnStyles from "../../../../styles/common/CommonStyles-Lincoln.module.scss";
import { IS_FORD_BRAND } from "../../../../utils/EmpUtil";

const StyledDialog = withStyles({
  // paper: {
  //   width: 900,
  //   maxWidth: 900,
  // },
  root: {
    flexGrow: 1,
    marginTop: 20,
  },
})(Dialog);

class ShowInventoryData extends Component {
  render() {
    const { showSettings } = this.props;
    const isFordBrand = IS_FORD_BRAND(this.props.user && this.props.user.brand);
    const commonStyles = isFordBrand ? commonFordStyles : commonLincolnStyles;
    return (
      <StyledDialog
        open={this.props.open}
        className={styles.dialog}
        onClose={this.props.onClose}
        fullWidth={true}
        maxWidth="lg"
      >
        <Grid container id="deleteDialog" data-testid="confirmSaveSettings">
          <div className={styles.wrapper}>
            <button className={styles.close} onClick={this.props.closeModal}>
              <Clear />
            </button>
            <LoadDealerPricing {...this.props} showValidatdColumn={false} />
            <div className={styles.root}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <div className={styles.buttonContainer}>
                    <Button
                      className={commonStyles.cancelledDialogButton}
                      id="cancelDialog"
                      data-testid="closeDialog"
                      onClick={this.props.closeModal}
                    >
                      Cancel
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <div className={styles.buttonContainer}>
                    <Button
                      className={commonStyles.confirmDialogButton}
                      id="confirmDialog"
                      data-testid="confirmDialog"
                      disabled={showSettings && !this.state.checked}
                      onClick={this.props.uploadDealerPriceToSave}
                    >
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </StyledDialog>
    );
  }
}

export default withTranslation("emp")(ShowInventoryData);
