import React, { Component } from "react";
import styles from "../PricingWrapper.module.scss";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import { ValidatorForm } from "react-material-ui-form-validator";
import AppliedPricing from "../../../../shared/appliedPricing/AppliedPricing";
import vehiclesStyles from "../nscVehicles/NscVehicles.module.scss";
import ValidationRules from "../../../../common/ValidationRules";
import DealerFeesHeader from "./dealerFeesHeader/DealerFeesHeader";
import DealerFeesRow from "./dealerFeesRow/DealerFeesRow";
import SortData from "../../../../utils/SortUtil";
import HasPermissionTo from "../../../../common/HasPermissionTo";
import {
  ADDITIONAL_FEES,
  CITY_FEE,
  DEALER_HANDLING_FEE,
  DELIVERY_FEE,
  FEES_MAP,
  FIRST_REG_FEE,
  NUMBER_PLATE,
} from "../../../../common/Constants";
import UpperCaseText from "../../../../utils/UpperCaseText";

class DealerFees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feesData: this.props.feesData,
      editEnabled: {
        numberPlates: false,
        additionalFees: false,
        deliveryFee: false,
        firstRegFee: false,
        cityFee: false,
        handlingFee: false,
      },
      editFlags: {
        numberPlates: true,
        additionalFees: true,
        deliveryFee: true,
        firstRegFee: true,
        cityFee: true,
        handlingFee: true,
      },
      sortDirection: null,
      sortKey: "dealerFeesInfo",
    };

    this.updateDealerFees = this.updateDealerFees.bind(this);
  }

  componentDidMount() {
    ValidationRules();
    this.makeCopyOfFeesData();
  }

  handleEdit = (e, index, key) => {
    const feesData = JSON.parse(JSON.stringify(this.state.feesData));
    feesData[index][key] = e.target.value;
    this.setState({ feesData });
  };

  makeCopyOfFeesData() {
    this.feesDataCopy = JSON.parse(JSON.stringify(this.state.feesData));
  }

  navigateToOptions = (e) => {
    this.props.handleTabChange(e, 1);
  };

  updateEditFlags = (column) => {
    const editFlags = this.state.editFlags;
    const editEnabled = this.state.editEnabled;
    if (column === NUMBER_PLATE) {
      editFlags.deliveryFee = !this.state.editFlags.deliveryFee;
      editFlags.additionalFees = !this.state.editFlags.additionalFees;
      editFlags.firstRegFee = !this.state.editFlags.firstRegFee;
      editFlags.cityFee = !this.state.editFlags.cityFee;
      editFlags.handlingFee = !this.state.editFlags.handlingFee;
      editEnabled.numberPlates = !this.state.editEnabled.numberPlates;
    } else if (column === ADDITIONAL_FEES) {
      editFlags.numberPlates = !this.state.editFlags.numberPlates;
      editFlags.deliveryFee = !this.state.editFlags.deliveryFee;
      editFlags.firstRegFee = !this.state.editFlags.firstRegFee;
      editFlags.cityFee = !this.state.editFlags.cityFee;
      editFlags.handlingFee = !this.state.editFlags.handlingFee;
      editEnabled.additionalFees = !this.state.editEnabled.additionalFees;
    } else if (column === DELIVERY_FEE) {
      editFlags.additionalFees = !this.state.editFlags.additionalFees;
      editFlags.numberPlates = !this.state.editFlags.numberPlates;
      editFlags.firstRegFee = !this.state.editFlags.firstRegFee;
      editFlags.cityFee = !this.state.editFlags.cityFee;
      editFlags.handlingFee = !this.state.editFlags.handlingFee;
      editEnabled.deliveryFee = !this.state.editEnabled.deliveryFee;
    } else if (column === FIRST_REG_FEE) {
      editFlags.additionalFees = !this.state.editFlags.additionalFees;
      editFlags.numberPlates = !this.state.editFlags.numberPlates;
      editFlags.deliveryFee = !this.state.editFlags.deliveryFee;
      editFlags.cityFee = !this.state.editFlags.cityFee;
      editFlags.handlingFee = !this.state.editFlags.handlingFee;
      editEnabled.firstRegFee = !this.state.editEnabled.firstRegFee;
    } else if (column === CITY_FEE) {
      editFlags.additionalFees = !this.state.editFlags.additionalFees;
      editFlags.numberPlates = !this.state.editFlags.numberPlates;
      editFlags.deliveryFee = !this.state.editFlags.deliveryFee;
      editFlags.handlingFee = !this.state.editFlags.handlingFee;
      editEnabled.cityFee = !this.state.editEnabled.cityFee;
    } else if (column === DEALER_HANDLING_FEE) {
      editFlags.additionalFees = !this.state.editFlags.additionalFees;
      editFlags.numberPlates = !this.state.editFlags.numberPlates;
      editFlags.deliveryFee = !this.state.editFlags.deliveryFee;
      editFlags.cityFee = !this.state.editFlags.cityFee;
      editEnabled.handlingFee = !this.state.editEnabled.handlingFee;
    }
    this.setState({ editEnabled, editFlags });
  };

  cancelUpdate = (column) => {
    this.setState({ feesData: this.feesDataCopy });
    this.updateEditFlags(column);
  };

  updateDealerFees() {
    const editFlags = this.state.editFlags;
    const editEnabled = this.state.editEnabled;
    editFlags.additionalFees = true;
    editFlags.deliveryFee = true;
    editFlags.numberPlates = true;
    editFlags.firstRegFee = true;
    editFlags.cityFee = true;
    editFlags.handlingFee = true;
    editEnabled.numberPlates = false;
    editEnabled.additionalFees = false;
    editEnabled.deliveryFee = false;
    editEnabled.firstRegFee = false;
    editEnabled.cityFee = false;
    editEnabled.handlingFee = false;
    this.feesDataCopy = this.state.feesData;
    this.props.updateFeesData(this.state.feesData);
    this.setState({
      editEnabled,
      editFlags,
    });
  }

  sortDealerFeesData(sortByCol) {
    let sortDir = "DSC";
    if (sortByCol === this.state.sortKey) {
      sortDir = this.state.sortDirection === "ASC" ? "DSC" : "ASC";
    }

    const that = this;
    SortData(
      sortByCol,
      sortDir,
      "alphanumeric",
      this.state.feesData,
      function (sortedData) {
        that.setState({
          feesData: sortedData,
          sortDirection: sortDir,
          sortKey: sortByCol,
        });
      }
    );
  }

  render() {
    return this.props.feesData && this.props.feesData.length > 0 ? (
      <div className={`${styles.pageMarginTop} `}>
        <ValidatorForm onSubmit={this.updateDealerFees}>
          <DealerFeesHeader
            {...this.props}
            fees={FEES_MAP[UpperCaseText(this.props.user.market)]}
            updateEditFlags={this.updateEditFlags}
            cancelUpdate={this.cancelUpdate}
            editFlags={this.state.editFlags}
            editEnabled={this.state.editEnabled}
            isDisabled={true}
            sortKey={this.state.sortKey}
            sortDirection={this.state.sortDirection}
            onClick={(key) => this.sortDealerFeesData(key)}
          />
          {this.state.feesData.map((item, index) => (
            <DealerFeesRow
              {...this.props}
              fees={FEES_MAP[UpperCaseText(this.props.user.market)]}
              item={item}
              index={index}
              handleEdit={this.handleEdit}
              editEnabled={this.state.editEnabled}
              isDisabled={true}
              key={index}
              released={this.props.released}
            />
          ))}
          {this.props.released &&
          !this.props.priceUpdatesAvailable &&
          !this.props.buttonEnabled ? (
            <div className={styles.appliedPricing}>
              <AppliedPricing />
            </div>
          ) : (
            <div className={vehiclesStyles.actionSection}>
              <HasPermissionTo
                permissions={this.props.user.permissions.rolePermissions}
                perform={["vehiclePricing:view"]}
                render={() => (
                  <Button
                    data-testid="applyFeesBtn"
                    disabled={
                      this.state.editEnabled.numberPlates ||
                      this.state.editEnabled.additionalFees ||
                      this.state.editEnabled.deliveryFee ||
                      this.state.editEnabled.firstRegFee ||
                      this.state.editEnabled.cityFee ||
                      this.state.editEnabled.handlingFee ||
                      this.props.user.readOnly
                    }
                    variant="contained"
                    onClick={(event) => {
                      this.navigateToOptions(event);
                    }}
                    className={vehiclesStyles.applyBtn}
                    component="button"
                  >
                    <span>{this.props.t("DealerVehicles.next")}</span>
                  </Button>
                )}
              />
            </div>
          )}
        </ValidatorForm>
      </div>
    ) : (
      <div className={styles.notFound}>
        {this.props.t("DealerVehicles.dataNotFound")}
      </div>
    );
  }
}

export default withTranslation("emp")(DealerFees);
