import React from "react";
import styles from "./AccessoriesSettingsEU.module.scss";
import { Table, TableBody, TableCell, withStyles } from "@material-ui/core";
import Item from "../../../../../shared/item/Item";
import CheckCurrencyValue from "../../../../../utils/CheckCurrencyValue";
import { TextValidator } from "react-material-ui-form-validator";
import GetFloatValue from "../../../../../utils/GetFloatValue";
import TableRow from "@material-ui/core/TableRow";

const commonStyles = {
  borderBottom: 0,
  fontWeight: 500,
  padding: "5px 0",
  fontFamily: "FordAntennaRegular, sans-serif",
  letterSpacing: "1px",
  lineHeight: 2.67,
};
const PartNameTableCell = withStyles({
  root: {
    ...commonStyles,
    fontFamily: "FordAntennaRegular, sans-serif",
    fontSize: "12px",
    width: "23%",
    textAlign: "left",
    color: "#102b4e",
    overflowWrap: "break-word",
    wordWrap: "break-word",
  },
})(TableCell);
const ItemTableCell = withStyles({
  root: {
    ...commonStyles,
    width: "11%",
    fontSize: "10px",
    fontFamily: "FordAntennaSemiBold, sans-serif",
    textAlign: "right",
    color: "#4a4a4a ",
    overflowWrap: "break-word",
    wordWrap: "break-word",
  },
})(TableCell);
const AccessoriesTable = (props) => {
  const handleAdjustmentEdit = (event) => {
    props.handleAdjustmentEdit(
      props.accessoriesData.groupName,
      event.target.name,
      event.target.value
    );
  };
  const ItemCell = ({ item: item, testId: testId }) => {
    return (
      <ItemTableCell className={`${styles.rowItemBold} ${styles.right}`}>
        <Item datatestid={testId} value={item} />
      </ItemTableCell>
    );
  };
  const { accessoriesData } = props;
  return (
    <>
      <div className={styles.subCategoryHeader}>
        {accessoriesData.groupName}
      </div>
      <div className={styles.tableBody}>
        {accessoriesData.accessories.map((accessory, index) => {
          const partNumber =
            (accessory.partNumber && "#" + accessory.partNumber) || "--";
          const grossRetail =
            (accessory.partNumber && accessory.grossRetail) || 0;
          const vm = 0;
          const adjustment = accessory.adjustment.toString();
          const dealerPrice = grossRetail + vm + GetFloatValue(adjustment);
          const installTime =
            (accessory.installTime && accessory.installTime) || 0;
          const transactionPrice =
            installTime * GetFloatValue(accessory.laborRate) + dealerPrice;
          return (
            <div
              key={index}
              className={`${styles.rowBorderHighlight} ${styles.dealerFeesRow} pagePaddingLeft pagePaddingRight`}
            >
              <Table>
                <TableBody>
                  <TableRow key={index}>
                    <PartNameTableCell className={styles.rowItem}>
                      <Item
                        value={accessory.accessoriesDesc}
                        datatestid={"specs" + props.index}
                      />
                    </PartNameTableCell>
                    <ItemCell item={partNumber} />
                    <ItemCell
                      item={CheckCurrencyValue(
                        props.currencySymbol,
                        grossRetail,
                        true
                      )}
                    />
                    <ItemCell
                      item={CheckCurrencyValue(props.currencySymbol, vm)}
                    />
                    {props.editEnabled ? (
                      <ItemTableCell>
                        <span className={styles.textBox}>
                          <TextValidator
                            name={accessory.id}
                            data-testid={"input" + accessory.id}
                            value={adjustment}
                            onChange={(e) => handleAdjustmentEdit(e)}
                            validators={[
                              "required",
                              "matchRegexp:^^[+-d\\b.]+$",
                              "isSignedFloatWithTwoDecimalPlaces",
                            ]}
                            errorMessages={[
                              props.t("Error.requiredField"),
                              props.t("Error.invalidInput"),
                              props.t("Error.invalidInput"),
                            ]}
                          />
                        </span>
                      </ItemTableCell>
                    ) : (
                      <ItemTableCell
                        className={`${styles.rowItemBold} ${styles.right}`}
                      >
                        <Item
                          className={styles.spacingLeft}
                          value={CheckCurrencyValue(
                            props.currencySymbol,
                            adjustment,
                            true
                          )}
                        />
                      </ItemTableCell>
                    )}
                    <ItemCell
                      item={CheckCurrencyValue(
                        props.currencySymbol,
                        dealerPrice,
                        true
                      )}
                    />
                    <ItemCell item={installTime} />
                    <ItemCell
                      item={CheckCurrencyValue(
                        props.currencySymbol,
                        transactionPrice,
                        true
                      )}
                      testId={"tp" + accessory.id}
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AccessoriesTable;
