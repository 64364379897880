import React from "react";
import PropTypes from "prop-types";
import styles from "./VehicleInfo.module.scss";

const VehicleInfo = (props) => {
  return (
    <div>
      <h1 id="vehInfoT1" className={styles.vehicleName}>
        {props.vehicleTitle} {props.year}
      </h1>
    </div>
  );
};

VehicleInfo.propTypes = {
  vehicleTitle: PropTypes.string.isRequired,
};

export default VehicleInfo;
