import React, { Component } from "react";
import styles from "../PricingWrapper.module.scss";
import SubNav from "../../../../shared/subnav/SubNav";
import Grid from "@material-ui/core/Grid";
import VehicleInfo from "../../../../shared/vehicleInfo/VehicleInfo";
import { vehicleInfo } from "./VehicleInfoMockData";
import BackToButton from "../../../../shared/backToButton/BackToButton";
import { withTranslation } from "react-i18next";
import NscVehicles from "../nscVehicles/NscVehicles";
import NscOptions from "../nscOptions/NscOptions";
import { logMsg } from "../../../../common/Logger";
import { FEES_MAP, PRICING } from "../../../../common/Constants";
import DealerFees from "../dealerFees/DealerFees";
import PendingActionClient from "../../../../shared/clients/PendingActionClient";
import PricingClient from "../../../../shared/clients/PricingClient";
import UpperCaseText from "../../../../utils/UpperCaseText";
import DealerVehicleCatalogClient from "../../../../shared/clients/DealerVehicleCatalogClient";
import {
  displayError,
  isUnknownError,
  PAGE_ERROR,
} from "../../../../errors/ErrorConstants";
import ModelDropDown from "./ModelDropDown";

class NscCurrentPricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      renderApp: false,
      modelData: null,
      hasError: [],
      buttonEnabled: false,
      model: "All",
      year: "All",
      optionsData: [],
      pricingData: [],
      feesData: [],
      released: [],
      priceUpdatesAvailable: [],
    };
    this.currencySymbol =
      this.props.localizedInfo && this.props.localizedInfo.defaultCurrency
        ? this.props.localizedInfo.defaultCurrency.symbol
        : "NA";
    this.newVehicleLine = false;

    this.vehicleTitle = this.vehicleTitle.bind(this);
  }

  componentDidMount() {
    this.fetchNscPricing();
    this.fetchVehicleLines();
  }

  onModelChange = (event) => {
    this.setState(
      {
        model: event.target.value,
        renderApp: false,
        optionsData: [],
        feesData: [],
        pricingData: [],
        released: [],
      },
      () => {
        this.fetchNscPricing();
      }
    );
  };

  onYearChange = (event) => {
    this.setState(
      {
        year: event.target.value,
        renderApp: false,
        optionsData: [],
        feesData: [],
        pricingData: [],
        released: [],
      },
      () => {
        this.fetchNscPricing();
      }
    );
  };

  handleTabChange = (event, selectedTab) => {
    this.setState({ selectedTab });
  };

  fetchNscPricing() {
    this.props.hideOrShow(true);
    const pricingData = [];
    const optionsData = [];
    const feesData = [];
    let released;
    let priceUpdatesAvailable;
    PricingClient.getNscPricing(
      this.props.user,
      this.state.model,
      this.state.year
    )
      .then((res) => {
        if (res && res.length > 0) {
          res.forEach((data) => {
            pricingData.push(...data.result.products);
            optionsData.push(...data.result.options);
            feesData.push(...data.result.fees);
            released = data.result.released;
            priceUpdatesAvailable = data.result.priceUpdatesAvailable;
          });
        }
        this.setState({
          pricingData,
          optionsData,
          feesData,
          released,
          priceUpdatesAvailable,
        });
        this.checkReleased();
      })
      .catch((reason) => {
        logMsg("reason", reason);
      })
      .finally(() => {
        this.setState({ renderApp: true });
        this.props.hideOrShow(false);
      });
  }

  fetchVehicleLines() {
    this.props.hideOrShow(true);
    DealerVehicleCatalogClient.getVehicleLines(this.props.user)
      .then((res) => {
        if (res && res.length) {
          this.setState({ modelData: res });
        }
      })
      .catch((error) => {
        logMsg("reason", error);
        if (isUnknownError(error)) {
          this.setState({ hasError: [PAGE_ERROR] });
        }
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  }

  checkReleased = () => {
    let buttonEnabled = false;
    if (!this.state.released) {
      this.setState({ buttonEnabled: true });
    } else {
      this.state.feesData &&
        this.state.feesData.forEach((fees) => {
          if (!fees.released) {
            buttonEnabled = true;
          }
        });
      this.state.pricingData &&
        this.state.pricingData.forEach((entity) => {
          entity.vehiclePricingDetails &&
            entity.vehiclePricingDetails.forEach((pricingRow) => {
              if (!pricingRow.released) {
                this.newVehicleLine = true;
                buttonEnabled = true;
              }
            });
        });
      this.setState({ buttonEnabled });
    }
  };

  constructTabs() {
    return [
      {
        menuName: this.props.t("CurrentPricing.dealerFees"),
        enabled: true,
        id: "dealerFees",
      },
      {
        menuName: this.props.t("CurrentPricing.vehicles"),
        enabled: true,
        id: "vehicles",
      },
      {
        menuName: this.props.t("CurrentPricing.options"),
        enabled: true,
        id: "options",
      },
    ];
  }

  saveResponseBody = () => {
    const pricingData = [];
    const feesData = [];
    if (!this.state.released) {
      this.state.pricingData.forEach((entity) => {
        entity.vehiclePricingDetails.forEach((item) => {
          item.catalogId = entity.catalogId;
          pricingData.push(item);
        });
      });
      return {
        pricing: pricingData,
        options: this.state.optionsData,
        fees: this.state.feesData,
      };
    } else {
      this.state.pricingData.forEach((entity) => {
        entity.vehiclePricingDetails.forEach((item) => {
          if (!item.released) {
            item.catalogId = entity.catalogId;
            pricingData.push(item);
          }
        });
      });
      this.state.feesData.forEach((fees) => {
        if (!fees.released) {
          feesData.push(fees);
        }
      });
      return {
        pricing: pricingData,
        fees: feesData,
      };
    }
  };

  saveNscPricing = () => {
    this.props.hideOrShow(true);
    this.setState({ renderApp: false });
    PricingClient.saveNscPricingCall(this.props.user, this.saveResponseBody())
      .then(() => {
        PendingActionClient.removeNscPendingAction(this.props.user, PRICING, 1);
        this.newVehicleLine &&
          PendingActionClient.addPA(this.props.user, PRICING);
        this.fetchNscPricing();
      })
      .catch((err) => {
        logMsg("error", err);
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  getSubHeader = () => {
    return (
      <div className={`${styles.left} pagePaddingLeft pagePaddingBottom`}>
        {this.state.selectedTab === 0 ? (
          <div>
            <div className={styles.dealerTrans}>
              <p>{this.props.t("DealerFees.subHeader")} </p>
            </div>
            <div className={styles.modelDiv}>
              <div className={styles.dropdownContainer}>
                <Grid item md={3} xs={4}>
                  <div className={styles.subTitle}>
                    {this.props.t("NamePlate.model")}
                  </div>
                  <ModelDropDown
                    modelData={this.state.modelData}
                    year={false}
                    onModelChange={this.onModelChange}
                    onYearChange={this.onYearChange}
                    model={this.state.model}
                    yearSelection={this.state.year}
                  />
                </Grid>

                <Grid item md={3} xs={4}>
                  <div className={styles.subTitle}>
                    {this.props.t("NamePlate.modelYear")}
                  </div>
                  <ModelDropDown
                    modelData={this.state.modelData}
                    year={true}
                    onModelChange={this.onModelChange}
                    onYearChange={this.onYearChange}
                    model={this.state.model}
                    yearSelection={this.state.year}
                  />
                </Grid>
              </div>
            </div>
          </div>
        ) : this.state.selectedTab === 1 ? (
          <>
            <div className={styles.dealerTrans}>
              {this.props.t("DealerVehicles.subheader1")}
            </div>
            <div className={styles.equation}>
              {this.props.t("DealerVehicles.rrp")}{" "}
              <span className={styles.minus}> &#8722; </span>{" "}
              {this.props.t("DealerVehicles.variableMarketingOffer")}
              <span className={styles.plus}> &#43;/&#8722; </span>{" "}
              {this.props.t("DealerVehicles.dealerAdjustment")}
              <span className={styles.equal}> &#61; </span>{" "}
              {this.props.t("DealerVehicles.vehicle1")}
            </div>
            <div className={styles.equation}>
              {this.props.t("DealerVehicles.vehicle2")}
              <span className={styles.plus}> &#43; </span>
              {this.props.t("DealerVehicles.dealerFees")}{" "}
              <span className={styles.equal}> &#61; </span>{" "}
              {this.props.t("DealerVehicles.transactionPrice")}
            </div>
            <div className={styles.modelDiv}>
              <div className={styles.dropdownContainer}>
                <Grid item md={3} xs={4}>
                  <div className={styles.subTitle}>
                    {this.props.t("NamePlate.model")}
                  </div>
                  <ModelDropDown
                    modelData={this.state.modelData}
                    year={false}
                    onModelChange={this.onModelChange}
                    onYearChange={this.onYearChange}
                    model={this.state.model}
                    yearSelection={this.state.year}
                  />
                </Grid>

                <Grid item md={3} xs={4}>
                  <div className={styles.subTitle}>
                    {this.props.t("NamePlate.modelYear")}
                  </div>
                  <ModelDropDown
                    modelData={this.state.modelData}
                    year={true}
                    onModelChange={this.onModelChange}
                    onYearChange={this.onYearChange}
                    model={this.state.model}
                    yearSelection={this.state.year}
                  />
                </Grid>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.dealerTrans}>
            <p>{this.props.t("DealerVehicles.setAccessoriesPricing")}</p>
            <div className={styles.modelDiv}>
              <div className={styles.dropdownContainer}>
                <Grid item md={3} xs={4}>
                  <div className={styles.subTitle}>
                    {this.props.t("NamePlate.model")}
                  </div>
                  <ModelDropDown
                    modelData={this.state.modelData}
                    year={false}
                    onModelChange={this.onModelChange}
                    onYearChange={this.onYearChange}
                    model={this.state.model}
                    yearSelection={this.state.year}
                  />
                </Grid>

                <Grid item md={3} xs={4}>
                  <div className={styles.subTitle}>
                    {this.props.t("NamePlate.modelYear")}
                  </div>
                  <ModelDropDown
                    modelData={this.state.modelData}
                    year={true}
                    onModelChange={this.onModelChange}
                    onYearChange={this.onYearChange}
                    model={this.state.model}
                    yearSelection={this.state.year}
                  />
                </Grid>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  vehicleTitle() {
    return (
      <Grid className={styles.infoBar} container>
        <Grid item xs={6}>
          <VehicleInfo
            vehicleTitle={vehicleInfo.vehicleTitle}
            year={vehicleInfo.year}
          />
        </Grid>
      </Grid>
    );
  }

  getSubNav = () => {
    return (
      <div className={styles.left}>
        <SubNav
          {...this.props}
          title={this.props.t("DealerVehicles.currentPricing")}
          subTitle={this.vehicleTitle}
          renderSubHeader={true}
          subHeader={this.getSubHeader}
          selectedTab={this.state.selectedTab}
          action={this.handleTabChange}
          tabs={this.constructTabs()}
        />
      </div>
    );
  };

  render() {
    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);
    return (
      this.state.renderApp && (
        <div className="pageWrapper">
          <BackToButton
            backToUrl="/pricing"
            display={this.props.t("PricingWrapper.backToPricing")}
          />
          {this.getSubNav()}
          {this.state.selectedTab === 0 ? (
            <DealerFees
              {...this.props}
              handleTabChange={this.handleTabChange}
              released={!this.state.buttonEnabled}
              priceUpdatesAvailable={this.state.priceUpdatesAvailable}
              currencySymbol={this.currencySymbol}
              feesData={this.state.feesData}
            />
          ) : this.state.selectedTab === 1 ? (
            <NscVehicles
              {...this.props}
              handleTabChange={this.handleTabChange}
              released={!this.state.buttonEnabled}
              feesData={this.state.feesData}
              pricingData={this.state.pricingData}
              priceUpdatesAvailable={this.state.priceUpdatesAvailable}
              currencySymbol={this.currencySymbol}
              fees={FEES_MAP[UpperCaseText(this.props.user.market)]}
            />
          ) : (
            <NscOptions
              {...this.props}
              isDisabled={true}
              savePricing={this.saveNscPricing}
              released={!this.state.buttonEnabled}
              options={this.state.optionsData}
              priceUpdatesAvailable={this.state.priceUpdatesAvailable}
              currencySymbol={this.currencySymbol}
            />
          )}
        </div>
      )
    );
  }
}

export default withTranslation("emp")(NscCurrentPricing);
