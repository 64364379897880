import ModifyReservationPrice from "../../components/pricing/pricingWrapper/depositPricing/modifyReservationPrice/ModifyReservationPrice";
import UploadTermsConditions from "../../components/uploadTermsConditions/UploadTermsConditions";
import AcceptTermsConditions from "../../components/acceptterms/AcceptTermsConditions";
import MerchantId from "../../components/businessSetting/merchantId/MerchantId";
import EmpOnBoarding from "../../components/quicksetup/emponboarding/OnboardingWelcome";
import PaymentThreshold from "../../components/businessSetting/paymentThreshold/PaymentThreshold";
import withIdleTimer from "../../common/WithIdleTimer";

const ProgressBarTabs = () => {
  return {
    onboardingNsc: [
      {
        key: 1,
        name: "ProgressBar.termsConditions",
        status: "active",
        stepComponent: withIdleTimer(UploadTermsConditions),
        saveBtn: "uploadFile",
        actionText: "ActionFooter.confirm",
      },
      {
        key: 2,
        name: "ProgressBar.preOrderDepositPricing",
        status: "",
        stepComponent: withIdleTimer(ModifyReservationPrice),
        saveBtn: "saveForm",
        actionText: "ActionFooter.apply",
      },
      {
        key: 3,
        name: "PaymentThreshold.tabCaption",
        status: "",
        stepComponent: withIdleTimer(PaymentThreshold),
        saveBtn: "savePaymentThreshold",
        actionText: "ActionFooter.applyAndFinish",
      },
    ],
    onboardingSANsc: [
      {
        key: 1,
        name: "ProgressBar.termsConditions",
        status: "active",
        stepComponent: withIdleTimer(UploadTermsConditions),
        saveBtn: "uploadFile",
        actionText: "ActionFooter.confirm",
      },
    ],
    onboardingDealer: [
      {
        key: 0,
        name: "ProgressBar.welcome",
        status: "active",
        stepComponent: withIdleTimer(EmpOnBoarding),
        saveBtn: "welcome",
        actionText: "ActionFooter.confirm",
      },
      {
        key: 1,
        name: "ProgressBar.termsConditions",
        status: "active",
        stepComponent: withIdleTimer(AcceptTermsConditions),
        saveBtn: "acceptTermsAndConditions",
        actionText: "ActionFooter.confirm",
      },
      {
        key: 2,
        name: "ProgressBar.storeId",
        status: "",
        stepComponent: withIdleTimer(MerchantId),
        saveBtn: "saveForm",
        actionText: "ActionFooter.confirm",
      },
      {
        key: 3,
        name: "ProgressBar.preOrderDepositPricing",
        status: "",
        stepComponent: withIdleTimer(ModifyReservationPrice),
        saveBtn: "saveForm",
        actionText: "ActionFooter.apply",
      },
    ],
    onboardingSADealer: [
      {
        key: 1,
        name: "ProgressBar.termsConditions",
        status: "active",
        stepComponent: withIdleTimer(AcceptTermsConditions),
        saveBtn: "acceptTermsAndConditions",
        actionText: "ActionFooter.confirm",
      },
    ],
  };
};

export default ProgressBarTabs;
