import React, { Component } from "react";
import styles from "../AccessoriesPricingWrapper.module.scss";
import HasPermissionTo from "../../../../../common/HasPermissionTo";
import Button from "@material-ui/core/Button";
import { ValidatorForm } from "react-material-ui-form-validator";
import BrandSettingsList from "./BrandSettingsList";
import ValidationRules from "../../../../../common/ValidationRules";
import { withTranslation } from "react-i18next";
import ConfirmDialog from "../../../../../shared/confirmDialog/ConfirmDialog";
import BrandSettingsClient from "../../../../../shared/clients/BrandSettingsClient";
import { SRP_PRICE_TYPE } from "../../../../../common/Constants";
import { GetFloatToFixedValue } from "../../../../../utils/GetFloatValue";
import UpperCaseText from "../../../../../utils/UpperCaseText";

class BrandSettingsEU extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brandSettingsData: {},
      isDataLoaded: false,
      buttonEnabled: false,
      originalData: {},
      dialogPrompt: false,
      editMode: false,
    };
  }

  componentDidMount() {
    ValidationRules();
    this.fetchBrandAdjustments();
  }

  handleDialogClose = () => {
    this.toggleDialogPrompt(false);
  };

  handleSubmit = (event) => {
    this.props.hideOrShow(true);
    if (event) {
      event.preventDefault();
    }
    const updatedBrandSettings = JSON.parse(
      JSON.stringify(this.state.brandSettingsData)
    );
    updatedBrandSettings.amount = GetFloatToFixedValue(
      updatedBrandSettings.amount,
      2
    );

    const payLoadData = {
      laborRate: updatedBrandSettings.amount,
      priceType: updatedBrandSettings.priceType || SRP_PRICE_TYPE,
      markup: 1,
    };

    this.saveBrandAdjustments(
      payLoadData,
      !this.props.isNsc && this.props.user.getDealerIdsWithoutSDLR()
    )
      .then(() => {
        this.setState({
          buttonEnabled: false,
          brandSettingsData: payLoadData,
          editMode: false,
          originalData: payLoadData,
        });
        this.toggleDialogPrompt(true);
        this.props.enableOtherTabs(true);
      })
      .catch()
      .finally(() => {
        this.fetchBrandAdjustments();
        this.props.hideOrShow(false);
      });
  };

  handleDialogConfirmation = () => {
    this.toggleDialogPrompt(false);
    this.props.handleTabChange(null, 1);
  };

  handleEdit = () => {
    this.setState({ editMode: true });
  };

  fetchBrandAdjustments = () => {
    this.getBrandAdjustments()
      .then((data) => {
        const brandSettingsObject = {
          model: this.props.t(`BrandSettings.model.${this.props.user.brand}`),
          amount: data.laborRate,
          priceType: data.priceType,
        };

        if (Number.isFinite(brandSettingsObject.amount)) {
          brandSettingsObject.amount = GetFloatToFixedValue(
            brandSettingsObject.amount,
            2
          );
          (this.props.isNsc || data.released) &&
            this.props.enableOtherTabs(true);
        } else {
          brandSettingsObject.amount = "";
        }

        this.setState({
          buttonEnabled: !this.props.isNsc && !data.released,
          editMode: this.props.isNsc
            ? brandSettingsObject.amount === ""
            : !data.released,
          brandSettingsData: brandSettingsObject,
          isDataLoaded: true,
          originalData: brandSettingsObject,
        });
      })
      .catch(() => {})
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };
  getBrandAdjustments = () => {
    this.props.hideOrShow(true);
    if (this.props.isNsc) {
      return BrandSettingsClient.getNscBrandAdjustments(this.props.user);
    } else {
      return BrandSettingsClient.getDealerBrandAdjustments(this.props.user);
    }
  };
  saveBrandAdjustments = (data, dealerIds) => {
    if (this.props.isNsc) {
      return BrandSettingsClient.saveNscBrandAdjustments(this.props.user, data);
    } else {
      return BrandSettingsClient.saveDealerBrandAdjustments(
        this.props.user,
        data,
        dealerIds
      );
    }
  };

  toggleDialogPrompt(open) {
    this.setState({ dialogPrompt: open });
  }

  updateBrandSettings = (event) => {
    const updatedBrandSettings = JSON.parse(
      JSON.stringify(this.state.brandSettingsData)
    );
    if (event.target.name === updatedBrandSettings.model) {
      updatedBrandSettings.amount = event.target.value;
    }
    this.setState({
      buttonEnabled: true,
      brandSettingsData: updatedBrandSettings,
    });
  };

  applyBtnHandler = (enable) => this.setState({ buttonEnabled: enable });

  render() {
    return (
      this.state.isDataLoaded && (
        <div className="pageWrapper">
          <div className="pagePadding">
            <div className={styles.header}>
              {this.props.t("AccessoriesPricing.subHeader")}
            </div>
            <div className={styles.description}>
              {UpperCaseText(this.props.user.userType) === "NSC"
                ? this.props.t("AccessoriesPricing.titleNsc")
                : this.props.t("AccessoriesPricing.title")}
            </div>
            <ValidatorForm
              id="modifyBrandSettingsForm"
              onSubmit={this.handleSubmit}
            >
              <BrandSettingsList
                {...this.props}
                buttonEnabled={this.state.buttonEnabled}
                updateBrandSettings={this.updateBrandSettings}
                brandSettingsData={this.state.brandSettingsData}
                originalData={this.state.originalData}
                applyBtn={this.applyBtnHandler}
                editMode={this.state.editMode}
                handleEdit={this.handleEdit}
              />
              <HasPermissionTo
                perform={["brandSettings:write"]}
                permissions={this.props.user.permissions.rolePermissions}
                condition={this.state.editMode}
                render={() => (
                  <div
                    className={
                      !this.state.buttonEnabled
                        ? styles.disabledButtonDiv
                        : styles.buttonDiv
                    }
                  >
                    <Button
                      data-testid="saveBrandSettings"
                      disabled={!this.state.buttonEnabled}
                      type="submit"
                    >
                      {this.state.buttonEnabled
                        ? this.props.t("AccessoriesPricing.apply")
                        : this.props.t("AccessoriesPricing.disableApply")}
                    </Button>
                  </div>
                )}
              />
            </ValidatorForm>
          </div>
          <ConfirmDialog
            open={this.state.dialogPrompt}
            dialogProps={{
              title: this.props.t("AccessoriesPricing.dialogTitle"),
              bodyText: this.props.t("AccessoriesPricing.dialogDescription"),
              confirm: this.props.t("AccessoriesPricing.dialogButton"),
            }}
            greenTitle={true}
            showOneButton={true}
            onConfirm={this.handleDialogConfirmation}
            onClose={this.handleDialogClose}
            boldTitle={false}
            {...this.props}
          />
        </div>
      )
    );
  }
}

export default withTranslation("emp")(BrandSettingsEU);
