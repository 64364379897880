const DealerFeesStyles = {
  tableRow: {
    height: 40,
  },

  headerCommon: {
    borderBottom: 0,
  },
  checkBox: {
    borderBottom: 0,
    padding: 0,
    width: "8%",
  },

  specs: {
    borderBottom: 0,
    fontWeight: 500,
    padding: "5px 0",
    width: "26%",
    textAlign: "left",
    color: "#102b4e",
    fontSize: "12px",
    fontFamily: "FordAntennaCondMed, sans-serif",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  nonEditHeader: {
    borderBottom: 0,
    fontWeight: 500,
    padding: "5px 0",
    width: "10%",
    textAlign: "right",
    align: "right",
    color: "#102b4e",
    fontSize: "12px",
    fontFamily: "FordAntennaCondMed, sans-serif",
    letterSpacing: "1px",
  },
  editHeader: {
    borderBottom: 0,
    fontWeight: 500,
    padding: "5px 0",
    width: "12%",
    textAlign: "right",
    align: "right",
    color: "#102b4e",
    fontSize: "12px",
    fontFamily: "FordAntennaCondMed, sans-serif",
    letterSpacing: "1px",
    verticalAlign: "bottom",
  },
  editHeaderNoBtn: {
    borderBottom: 0,
    fontWeight: 500,
    padding: "5px 11px 5px 0",
    width: "12%",
    textAlign: "right",
    align: "right",
    color: "#102b4e",
    fontSize: "12px",
    fontFamily: "FordAntennaCondMed, sans-serif",
    letterSpacing: "1px",
    verticalAlign: "bottom",
  },

  specsItem: {
    borderBottom: 0,
    fontWeight: 500,
    padding: "5px 0",
    width: "26%",
    textAlign: "left",
    color: "#102b4e",
    fontSize: "12px",
    fontFamily: "FordAntennaRegular, sans-serif",
    letterSpacing: "1px",
    lineHeight: 2.67,
  },
  feesNonEditValue: {
    borderBottom: 0,
    fontWeight: 500,
    padding: "5px 0",
    width: "10%",
    textAlign: "right",
    align: "right",
    color: "#4a4a4a ",
    fontSize: "12px",
    fontFamily: "FordAntennaSemiBold, sans-serif",
    letterSpacing: "1px",
  },
  feesEditValue: {
    borderBottom: 0,
    fontWeight: 500,
    padding: "5px 0",
    width: "12%",
    textAlign: "right",
    align: "right",
    color: "#4a4a4a ",
    fontSize: "12px",
    fontFamily: "FordAntennaSemiBold, sans-serif",
    letterSpacing: "1px",
  },
};

export default DealerFeesStyles;
