import React from "react";
import styles from "../../pricingHeader/PricingHeader.module.scss";
import { Table, TableCell, TableHead, withStyles } from "@material-ui/core";
import HasPermissionTo from "../../../../../common/HasPermissionTo";
import Item from "../../../../../shared/item/Item";
import Clear from "@material-ui/icons/Clear";
import CheckBox from "../../../../../shared/checkBox/CheckBox";
import SortingIcon from "../../../../../shared/sortingIcon/SortingIcon";
import Button from "@material-ui/core/Button";
import feesStyles from "../DealerFeesStyles";

const DealerFeesHeader = (props) => {
  const StyledClear = withStyles({
    root: {
      width: 20,
      height: 20,
      fill: "#e81607",
    },
  })(Clear);
  const StyledSpecsTableCell = withStyles({
    root: {
      borderBottom: 0,
      fontWeight: 500,
      padding: "5px 0",
      width: "26%",
      textAlign: "left",
      color: "#102b4e",
      fontSize: "12px",
      fontFamily: "FordAntennaCondMed, sans-serif",
      letterSpacing: "1px",
      textTransform: "uppercase",
      verticalAlign: "bottom",
    },
  })(TableCell);
  const NonEditTableCell = withStyles({
    root: {
      borderBottom: 0,
      fontWeight: 500,
      padding: "5px 0",
      width: "10%",
      textAlign: "right",
      color: "#102b4e",
      fontSize: "12px",
      fontFamily: "FordAntennaCondMed, sans-serif",
      letterSpacing: "1px",
      textTransform: "uppercase",
      verticalAlign: "bottom",
    },
  })(TableCell);
  const WarningTableCell = withStyles({
    root: {
      borderBottom: 0,
      padding: 0,
      width: "5%",
    },
  })(TableCell);
  const CheckBoxTableCell = withStyles({
    root: {
      borderBottom: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 5,
      width: "8%",
      verticalAlign: "bottom",
    },
  })(TableCell);

  const sortHidden =
    props.editEnabled.deliveryFee ||
    props.editEnabled.numberPlates ||
    props.editEnabled.firstRegFee ||
    props.editEnabled.additionalFees;
  return (
    <div className={styles.rowBorder}>
      <Table>
        <TableHead>
          <WarningTableCell />
          <CheckBoxTableCell className={styles.rowItem}>
            <CheckBox
              className="toggleAllCheckbox"
              id="toggleAllCheckbox"
              checked={true}
              isDisabled={props.isDisabled}
            />
          </CheckBoxTableCell>
          <StyledSpecsTableCell
            onClick={() => !sortHidden && props.onClick("specs")}
          >
            <div className={styles.specsHeaderWrap}>
              <Item value={props.t("DealerFees.specs")} />
              <span className={styles.sorting}>
                <SortingIcon
                  currentKey="specs"
                  hide={sortHidden}
                  sortKey={props.sortKey}
                  sortDirection={props.sortDirection}
                />
              </span>
            </div>
          </StyledSpecsTableCell>
          {props.fees &&
            props.fees.map((fee) => {
              return fee.isEditable ? (
                <HasPermissionTo
                  permissions={props.user.permissions.rolePermissions}
                  perform={["vehiclePricing:write"]}
                  key={fee.key}
                  condition={!props.user.readOnly && props.editFlags[fee.key]}
                  render={() => (
                    <TableCell
                      style={feesStyles.editHeader}
                      className={`${styles.rowItem} ${styles.right}`}
                    >
                      {props.editEnabled[fee.key] ? (
                        <>
                          <div
                            data-testid="feesUpdateHeader"
                            className={`${styles.headerUpdateTitle} ${styles.right}`}
                          >
                            <span>
                              <Item value={props.t(fee.name)} />
                            </span>
                          </div>
                          <div className={styles.cancelConfirmHeaderWrap}>
                            <Button
                              size="small"
                              name="Cancel"
                              data-testid={"cancel" + fee.key}
                              onClick={() => {
                                props.cancelUpdate(fee.key);
                              }}
                            >
                              <StyledClear />
                            </Button>
                            <Button
                              size="small"
                              name="Confirm"
                              type="submit"
                              data-testid={"confirm" + fee.key}
                            >
                              <span className={styles.headerWrap}>
                                {props.t("Options.update")}
                              </span>
                            </Button>
                          </div>
                        </>
                      ) : (
                        <div className={styles.editableHeader}>
                          <div
                            className={`${styles.headerWrap} ${styles.width85} ${styles.right}`}
                          >
                            <span>
                              <Item value={props.t(fee.name)} />
                            </span>
                          </div>
                          <div
                            className={`${styles.headerWrap} ${styles.width15} ${styles.left} ${styles.noPadding}`}
                          >
                            <div
                              className={styles.Edit}
                              onClick={() => {
                                props.updateEditFlags(fee.key);
                              }}
                              data-testid={"edit" + fee.key}
                            >
                              <div className={styles.RectangleStart} />
                              <div className={styles.RectangleEnd} />
                            </div>
                          </div>
                        </div>
                      )}
                    </TableCell>
                  )}
                  noRender={() => (
                    <TableCell
                      style={feesStyles.editHeaderNoBtn}
                      className={`${styles.rowItem} ${styles.right}`}
                    >
                      <div
                        className={`${styles.headerWrap}  ${styles.width85} ${styles.right}`}
                      >
                        <span>
                          <Item value={props.t(fee.name)} />
                        </span>
                      </div>
                      <div className={styles.width15} />
                    </TableCell>
                  )}
                />
              ) : (
                <NonEditTableCell>
                  <div className={styles.headerWrap}>
                    <Item
                      className={styles.maxWidth}
                      value={props.t(fee.name)}
                    />
                  </div>
                </NonEditTableCell>
              );
            })}
        </TableHead>
      </Table>
    </div>
  );
};

export default DealerFeesHeader;
