import React from "react";
import styles from "../PricingWrapper.module.scss";
import AppliedPricing from "../../../../shared/appliedPricing/AppliedPricing";
import vehiclesStyles from "./NscVehicles.module.scss";
import Button from "@material-ui/core/Button";
import HasPermissionTo from "../../../../common/HasPermissionTo";
import PricingDetails from "../pricingDetails/PricingDetails";

const NscVehicles = (props) => {
  const navigateToOptions = (e) => {
    props.handleTabChange(e, 2);
  };

  return props.pricingData && props.pricingData.length > 0 ? (
    <div id="nscVehicleDetails">
      {props.pricingData.map((item, index) => {
        return (
          <PricingDetails
            {...props}
            index={index}
            key={index}
            item={item}
            isDealer={false}
          />
        );
      })}

      {props.released && !props.priceUpdatesAvailable ? (
        <div className={styles.appliedPricing}>
          <AppliedPricing />
        </div>
      ) : (
        <div className={vehiclesStyles.actionSection}>
          <HasPermissionTo
            permissions={props.user.permissions.rolePermissions}
            perform={["vehiclePricing:view"]}
            render={() => (
              <Button
                data-testid="applyPricesBtn"
                variant="contained"
                onClick={(event) => {
                  navigateToOptions(event);
                }}
                className={vehiclesStyles.applyBtn}
                component="button"
              >
                <span>Next</span>
              </Button>
            )}
          />
        </div>
      )}
    </div>
  ) : (
    <div className={styles.notFound}> Data Not Found</div>
  );
};

export default NscVehicles;
