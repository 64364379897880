import React from "react";
import styles from "../../pricingWrapper/optionsHeader/OptionsHeader.module.scss";
import Grid from "@material-ui/core/Grid";
import CheckBox from "../../../../shared/checkBox/CheckBox";
import Item from "../../../../shared/item/Item";
import SortingIcon from "../../../../shared/sortingIcon/SortingIcon";
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Clear from "@material-ui/icons/Clear";
import HasPermissionTo from "../../../../common/HasPermissionTo";

const OptionsHeader = (props) => {
  const StyledClear = withStyles({
    root: {
      width: 20,
      height: 20,
      fill: "#e81607",
    },
  })(Clear);

  return (
    <div className={`${styles.rowBorder} pagePaddingLeft pagePaddingRight`}>
      <Grid container spacing={0}>
        <Grid item xs={1} className={styles.rowItem}>
          <CheckBox
            className="toggleAllCheckbox"
            id="toggleAllCheckbox"
            checked={true}
            isDisabled={props.isDisabled}
          />
        </Grid>
        <Grid
          item
          xs={4}
          className={styles.rowItem}
          id="optionInfo"
          onClick={() => !props.isEditEnabled && props.onClick("featureDesc")}
        >
          <div className={styles.header}>
            <Item value={props.t("Options.specification")} />
            <span className={styles.sorting}>
              <SortingIcon
                hide={props.isEditEnabled}
                currentKey="featureDesc"
                sortKey={props.sortKey}
                sortDirection={props.sortDirection}
              />
            </span>
          </div>
        </Grid>
        <Grid
          id="RRPItem"
          item
          xs={1}
          className={`${styles.rowItem} ${styles.center}`}
          onClick={() => !props.isEditEnabled && props.onClick("rrp")}
        >
          <div className={styles.header}>
            <Item value={props.t("Options.rrp")} />
            <span className={styles.sorting}>
              <SortingIcon
                currentKey="rrp"
                hide={props.isEditEnabled}
                sortKey={props.sortKey}
                sortDirection={props.sortDirection}
              />
            </span>
          </div>
        </Grid>
        <Grid
          item
          xs={2}
          className={`${styles.highlightedColumn} ${styles.center}`}
          onClick={() => !props.isEditEnabled && props.onClick("vm")}
        >
          <div className={styles.header}>
            <Item value={props.t("Options.discount")} />
            <span className={styles.sorting}>
              <SortingIcon
                currentKey="vm"
                hide={props.isEditEnabled}
                sortKey={props.sortKey}
                sortDirection={props.sortDirection}
              />
            </span>
          </div>
        </Grid>
        <Grid
          item
          xs={2}
          className={`${styles.rowItem} ${styles.right} ${styles.leftPadding}`}
        >
          <HasPermissionTo
            permissions={props.user.permissions.rolePermissions}
            perform={["vehiclePricing:write"]}
            condition={!props.readOnly}
            render={() =>
              props.isEditEnabled ? (
                <div className={styles.headerUpdateTitle}>
                  <div
                    className={styles.bottomPadding}
                    data-testid="recommendedAdjustmentUpdateHeader"
                  >
                    <span>
                      <Item value={props.t("Options.recommendedAdjustment")} />
                    </span>
                  </div>
                  <div>
                    <Button
                      size="small"
                      name="Cancel"
                      data-testid="cancelOptions"
                      onClick={() => {
                        props.cancelOptionChanges();
                      }}
                    >
                      <StyledClear />
                    </Button>
                    <Button
                      size="small"
                      name="Confirm"
                      type="submit"
                      data-testid="confirmOptions"
                    >
                      <span className={styles.header}>
                        {props.t("Options.update")}
                      </span>
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className={`${styles.header} ${styles.width85} ${styles.right}`}
                  >
                    <span className={`${styles.specificWidth} `}>
                      <Item value={props.t("Options.recommendedAdjustment")} />
                    </span>
                  </div>
                  <div
                    className={`${styles.header} ${styles.width15} ${styles.left} ${styles.spacePadding}`}
                  >
                    <div
                      className={styles.Edit}
                      onClick={(e) => {
                        props.editRecords(e);
                      }}
                      data-testid="editOptions"
                    >
                      <div className={styles.RectangleStart}></div>
                      <div className={styles.RectangleEnd}></div>
                    </div>
                  </div>
                </>
              )
            }
            noRender={() => (
              <>
                <div
                  className={`${styles.header} ${styles.width85} ${styles.right}`}
                >
                  <span className={`${styles.specificWidth} `}>
                    <Item value={props.t("Options.recommendedAdjustment")} />
                  </span>
                </div>
                <div className={` ${styles.width15}`} />
              </>
            )}
          />
        </Grid>
        <Grid
          item
          xs={2}
          className={`${styles.rowItem} ${styles.right} ${styles.leftPadding}`}
          onClick={() =>
            !props.isEditEnabled && props.onClick("recommendedPrice")
          }
        >
          <div className={`${styles.header} ${styles.specificWidth}`}>
            <Item value={props.t("Options.recommendedPrice")} />
            <span className={styles.sorting}>
              <SortingIcon
                currentKey="recommendedPrice"
                hide={props.isEditEnabled}
                sortKey={props.sortKey}
                sortDirection={props.sortDirection}
              />
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default OptionsHeader;
