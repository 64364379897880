import React, { Component } from "react";
import PricingHeader from "../pricingHeader/PricingHeader";
import { withTranslation } from "react-i18next";
import vehiclesStyles from "../nscVehicles/NscVehicles.module.scss";
import { ValidatorForm } from "react-material-ui-form-validator";
import ValidationRules from "../../../../common/ValidationRules";
import SortData from "../../../../utils/SortUtil";
import PricingRow from "../pricingRow/PricingRow";

class PricingDetails extends Component {
  constructor(props) {
    super(props);
    const that = this;

    this.props.item.vehiclePricingDetails.forEach(function (item) {
      item["dealerVehiclePrice"] = that.calculateDealerVehiclePrice(
        item.adj,
        item
      );
      item["transactionPrice"] = that.calculateTransactionPrice(item);
    });

    const pricingData = this.concatSigns();
    this.state = {
      pricingData: pricingData,
      sortDirection: null,
      sortKey: "vehicleInfo",
    };
    this.modifiedPricingData = [];

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.makeCopyOfPricingData();
    this.performOnLoadTasks();
    ValidationRules();
  }

  handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    this.updateVehiclePrice();
  }

  calculateTransactionPrice(pricing) {
    let fees = null;
    const feesMap = new Map();
    if (this.props.feesData) {
      this.props.feesData.forEach(function (val) {
        feesMap.set(val.entity, val);
      });
      const feeSet = feesMap.get(pricing.entityId);
      if (feeSet) {
        this.props.fees.forEach((fee) => {
          fees = fees + feeSet[fee.key];
        });
      }

      return pricing.dealerVehiclePrice + fees;
    }
  }

  makeCopyOfPricingData = () => {
    //In Discount mode if changes are cancelled we need this previous copy to replace the values
    const pricingData = JSON.parse(JSON.stringify(this.state.pricingData));
    this.pricingDataCopy = pricingData;
  };

  performOnLoadTasks() {
    // Creating pricing Map key as vehicleInfo, Value is pricing record with current index
    // This is required to keep original values
    this.vehicleInfoWithIndex = new Map();
    const that = this;
    const pricingDataCopy = JSON.parse(JSON.stringify(this.state.pricingData)); //Required to store in VehicleInfoWithIndexMap to store original values
    pricingDataCopy.forEach(function (pricing, index) {
      pricing.index = index;
      that.vehicleInfoWithIndex.set(pricing.entityId, pricing);
    });
  }

  updateVehicleInfoWithIndex(fromSort) {
    if (fromSort) {
      const that = this;
      this.state.pricingData.forEach(function (pricing, index) {
        that.vehicleInfoWithIndex.get(pricing.entityId).index = index;
      });
    }
  }

  sortPricingData(sortByCol, fromSubmit = false) {
    // If dealer has just edited prices, re-sort the way things were previously sorted
    let sortDir;
    if (fromSubmit) {
      sortDir = this.state.sortDirection;
    } else {
      // Default to descending if first click of this arrow
      sortDir = "DSC";
      if (sortByCol === this.state.sortKey) {
        sortDir = this.state.sortDirection === "ASC" ? "DSC" : "ASC";
      }
    }

    const that = this;
    SortData(
      sortByCol,
      sortDir,
      "alphanumeric",
      this.state.pricingData,
      function (sortedData) {
        that.setState(
          {
            pricingData: sortedData,
            sortDirection: sortDir,
            sortKey: sortByCol,
          },
          () => {
            that.makeCopyOfPricingData();
            that.updateVehicleInfoWithIndex(true);
          }
        );
      }
    );
  }

  editRecords() {
    this.props.toggleEditMode();
    this.updateEditFlag(true);
  }

  concatSigns() {
    const pricingDataCopy = JSON.parse(
      JSON.stringify(this.props.item.vehiclePricingDetails)
    );
    pricingDataCopy.forEach((dataRow) => {
      dataRow.adj =
        dataRow.adj > 0 && dataRow.adj.toString().charAt(0) !== "+"
          ? "+".concat(dataRow.adj)
          : dataRow.adj.toString();
    });
    return pricingDataCopy;
  }

  cancelChanges() {
    this.props.toggleEditMode();
    this.updateEditFlag(false);
    this.setState({ pricingData: this.pricingDataCopy });
  }

  updateEditFlag(value) {
    this.props.updateEditFlag(this.props.index, value);
  }

  updateDiscountPrice(event, item) {
    const copy = JSON.parse(JSON.stringify(this.state.pricingData));
    const index = this.vehicleInfoWithIndex.get(item.entityId).index;
    const currentData = copy[index];
    currentData.adj = event.target.value;
    this.setState({ pricingData: copy });
  }

  resetVehiclesRow = (event, item) => {
    const copy = JSON.parse(JSON.stringify(this.state.pricingData));
    const index = this.vehicleInfoWithIndex.get(item.entityId).index;
    const currentData = copy[index];
    currentData.adj = item.recommendedAdj + "";
    this.setState({ pricingData: copy }, () => {
      this.updateVehiclePrice();
    });
  };

  updateVehiclePrice() {
    this.updateEditFlag(false);
    const that = this;
    this.state.pricingData.forEach(function (pricing) {
      const initialRecord = that.vehicleInfoWithIndex.get(pricing.entityId);
      const adjFloat = parseFloat(pricing.adj);
      if (initialRecord.adj !== adjFloat) {
        const modifiedPricingDataIndex = that.findObjectIndexFromArray(
          that.modifiedPricingData,
          pricing
        );
        if (modifiedPricingDataIndex === -1) {
          pricing["dealerVehiclePrice"] = that.calculateDealerVehiclePrice(
            adjFloat,
            initialRecord
          );
          pricing["transactionPrice"] = that.calculateTransactionPrice(pricing);
          that.modifiedPricingData.push(pricing);
        } else if (
          modifiedPricingDataIndex !== -1 &&
          that.modifiedPricingData[modifiedPricingDataIndex].adj !== pricing.adj
        ) {
          pricing["dealerVehiclePrice"] = that.calculateDealerVehiclePrice(
            adjFloat,
            initialRecord
          );
          pricing["transactionPrice"] = that.calculateTransactionPrice(pricing);

          that.modifiedPricingData[
            modifiedPricingDataIndex
          ].dealerVehiclePrice = pricing.dealerVehiclePrice;
          //that.modifiedPricingData[modifiedPricingDataIndex].applyStatus = pricing.applyStatus;
          that.modifiedPricingData[modifiedPricingDataIndex].adj = pricing.adj;
          that.modifiedPricingData[modifiedPricingDataIndex].transactionPrice =
            pricing.transactionPrice;
        }
      }
    });

    this.setState({ pricingData: this.state.pricingData }, () => {
      this.makeCopyOfPricingData();
    });
    this.props.updatePricingData(this.state.pricingData, this.props.index);
    if (
      this.state.sortKey === "dealerVehiclePrice" ||
      this.state.sortKey === "transactionPrice"
    ) {
      this.sortPricingData(this.state.sortKey, true);
    }
  }

  // function to find index of object from array of objects (only for wersInfo)
  findObjectIndexFromArray = (pricingData, item) => {
    if (pricingData) {
      return pricingData.findIndex(
        (pricing) => pricing["entityId"] === item.entityId
      );
    } else {
      return -1;
    }
  };

  calculateDealerVehiclePrice(dealerDiscount, pricing) {
    const rrp = pricing.rrp;
    const customerSaving = pricing.vm.customerSaving;
    const dealerVehiclePrice = rrp - customerSaving + dealerDiscount;
    return dealerVehiclePrice;
  }

  render() {
    return (
      this.props.item.vehiclePricingDetails.length > 0 && (
        <ValidatorForm onSubmit={this.handleSubmit} key={this.props.index}>
          <>
            <div
              className={`${vehiclesStyles.vehicleName} 
                                                pagePaddingLeft pagePaddingRight`}
            >
              {this.props.item.marketedSeries}
            </div>
            <PricingHeader
              onSort={(key) => this.sortPricingData(key)}
              editRecords={(e) => this.editRecords(e)}
              cancelChanges={() => this.cancelChanges()}
              isDealer={this.props.isDealer}
              sortDirection={this.state.sortDirection}
              sortKey={this.state.sortKey}
              index={this.props.index}
              isEditEnabled={this.props.isEditEnabled}
              isDisabled={true}
              user={this.props.user}
            />
            {this.state.pricingData.map((item, index) => {
              return (
                <PricingRow
                  {...this.props}
                  isEditEnabled={this.props.isEditEnabled}
                  mainIndex={this.props.index}
                  index={index}
                  key={index}
                  dataRow={item}
                  resetVehiclesRow={this.resetVehiclesRow}
                  currencySymbol={this.props.currencySymbol}
                  onChange={(e) => this.props.onChange(e, item)}
                  isDealer={this.props.isDealer}
                  isDisabled={true}
                  updateDiscountPrice={(e) => this.updateDiscountPrice(e, item)}
                />
              );
            })}
          </>
        </ValidatorForm>
      )
    );
  }
}

export default withTranslation("emp")(PricingDetails);
